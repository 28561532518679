<template>
  <button class="delivery__existing" v-bind:class="{ active: isActive }" @click.prevent="handleAddressPick">
    {{ address.street }} {{ address.number }}{{ address.number_addon }} {{ address.busnumber }} <br>
    {{ address.postalcode }} {{ address.commune }}, <br>
    {{ address.country }}
  </button>
</template>

<script>
  export default {
    name: "AddressCard",
    mounted(){

    },
    data(){
      return {
        address: this.initAddress
      }
    },
    computed: {
      isActive(){
        if(this.address.id == this.activeAddress)
        {
          return true;
        }

        return false;
      }
    },
    methods: {
      handleAddressPick(){
        this.$emit("addressPick", this.type, this.address.id);
      }
    },
    props: {
      initAddress: {
        type: Object|Array,
        required: true
      },
      activeAddress: {
        type: Number|String,
        required: true
      },
      type: {
        required: true
      }
    }
  }
</script>

<style scoped>

</style>