<template>
    <li v-bind:class="{'uk-open': ukOpen}">
        <a class="uk-accordion-title" href="#">
            <span class="d-flex">
              <info-circle
                  v-if="filter.description !== null && filter.description !== ''"
                  :headline="filter.name"
                  :body="filter.description"
              ></info-circle>
              <span>{{ filter.name }}</span>
            </span>
            <i class="fa-solid fa-chevron-down"></i>
        </a>

        <div class="uk-accordion-content">
            <ul class="sidebar__nav">
                <filter-sidebar-dynamic-filters-checks
                    v-if="filter.type=='check'"
                    v-model="localActiveFilters"
                    :filter="filter"
                    :active-filters="localActiveFilters"
                    @input="handleChange"
                >
                </filter-sidebar-dynamic-filters-checks>
                <filter-sidebar-dynamic-filters-radios
                    v-if="filter.type == 'radio'"
                    v-model="localActiveFilters"
                    :filter="filter"
                    :active-filters="localActiveFilters"
                    @input="handleChange"
                >
                </filter-sidebar-dynamic-filters-radios>
            </ul>
        </div>
    </li>
</template>

<script>
    import InfoCircle from "./InfoCircle";
    export default {
        name: "FilterSidebarDynamicFilters",
      components: {InfoCircle},
      mounted() {
            
        },
        data() {
            return {
                localActiveFilters: this.activeFilters
            }
        },
        methods: {
            handleChange(val){
                this.localActiveFilters = Object.assign({}, val);
                this.$emit('input', this.localActiveFilters);
            }
        },
        props: {
            filter: {
                type: Object|Array,
                required: true
            },
            activeFilters: {
                type: Object|Array,
                required: true
            },
            ukOpen: {
              type: Boolean,
              required: false,
              default: true
            }
        }
    }
</script>

<style scoped>
</style>