<template>
    
</template>

<script>
export default {
    name: "FilterSidebarPrice",
    mounted() {

    },
    data() {
        return {

        }
    },
    methods: {

    },
    props: {
        
    }

}
</script>

<style scoped>
    
</style>