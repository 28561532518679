import { render, staticRenderFns } from "./MobileCategoryNav.vue?vue&type=template&id=6323721d&scoped=true&"
import script from "./MobileCategoryNav.vue?vue&type=script&lang=js&"
export * from "./MobileCategoryNav.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6323721d",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6323721d')) {
      api.createRecord('6323721d', component.options)
    } else {
      api.reload('6323721d', component.options)
    }
    module.hot.accept("./MobileCategoryNav.vue?vue&type=template&id=6323721d&scoped=true&", function () {
      api.rerender('6323721d', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/assets/js/Components/MobileCategoryNav.vue"
export default component.exports