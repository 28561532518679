var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "delivery__existing",
      class: { active: _vm.isActive },
      on: {
        click: function ($event) {
          $event.preventDefault()
          return _vm.handleAddressPick.apply(null, arguments)
        },
      },
    },
    [
      _vm._v(
        "\n  " +
          _vm._s(_vm.address.street) +
          " " +
          _vm._s(_vm.address.number) +
          _vm._s(_vm.address.number_addon) +
          " " +
          _vm._s(_vm.address.busnumber) +
          " "
      ),
      _c("br"),
      _vm._v(
        "\n  " +
          _vm._s(_vm.address.postalcode) +
          " " +
          _vm._s(_vm.address.commune) +
          ", "
      ),
      _c("br"),
      _vm._v("\n  " + _vm._s(_vm.address.country) + "\n"),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }