<template>
    <div>
        <button v-if="checked" class="c-product__like" @click.prevent="toggleWishlist">
            <img src="/dist/assets/images/icons/heart-filled.svg" class="normal" uk-svg />
            <img src="/dist/assets/images/icons/heart-outline.svg" class="active" uk-svg />
        </button>
        <button v-else class="c-product__like" @click.prevent="toggleWishlist">
            <img src="/dist/assets/images/icons/heart-outline.svg" class="normal" uk-svg />
            <img src="/dist/assets/images/icons/heart-filled.svg" class="active" uk-svg />
        </button>
    </div>
</template>

<script>
    import { EventBus } from "../EventBus";

    export default {
        name: "WishlistButton",
        data() {
            return {
                checked: this.inWishlist
            }
        },
        methods: {
            toggleWishlist()
            {
                window.axios.post(this.routes.wishlist.toggleWishlist, {product_id: this.productId})
                    .then(res => {
                        this.checked = !this.checked;

                        if (res?.data?.message) {
                            EventBus.$emit('NOTIFY', res.data.message);
                        }

                        if (res?.data?.products) {
                            EventBus.$emit('WISHLIST_UPDATE', res.data.products);
                        }
                    })
                    .catch(error => {
                        if (error?.response?.message) {
                            EventBus.$emit('NOTIFY', error.response.data.message);
                        }
                    });
            },
        },
        watch: {
            inWishlist(newVal, oldVal) {
                this.checked = newVal;
            }
        },
        props: {
            inWishlist: {
                type: Boolean,
                required: true,
            },
            productId: {
                type: Number,
                required: true,
            },
            routes: {
                type: Object|Array,
                required: true
            },
        }
    }
</script>

<style scoped>

</style>