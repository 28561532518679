var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("form", { staticClass: "form-custom" }, [
    _vm.userLoggedIn
      ? _c("div", { staticClass: "uk-width-1-2@s mb-4" }, [
          _c(
            "label",
            {
              staticClass: "uk-form-label",
              attrs: { for: "form-stacked-text" },
            },
            [_vm._v(_vm._s(_vm.translations.user_reference))]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "uk-form-controls" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.userReference,
                  expression: "userReference",
                },
              ],
              staticClass: "uk-input",
              attrs: {
                id: "form-stacked-text",
                type: "text",
                placeholder: _vm.translations.user_reference,
              },
              domProps: { value: _vm.userReference },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.userReference = $event.target.value
                },
              },
            }),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "uk-width-1-1" }, [
      _c(
        "label",
        { staticClass: "uk-form-label", attrs: { for: "form-stacked-text" } },
        [_vm._v(_vm._s(_vm.translations.regards))]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "uk-form-controls" }, [
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.additionalRemarks,
              expression: "additionalRemarks",
            },
          ],
          staticClass: "uk-textarea mb-4",
          attrs: { rows: "5", placeholder: _vm.translations.your_message },
          domProps: { value: _vm.additionalRemarks },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.additionalRemarks = $event.target.value
            },
          },
        }),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "d-flex justify-content-end" }, [
      _c("p", [
        _vm._v("\n      " + _vm._s(_vm.translations.by_continuing) + " "),
        _c(
          "a",
          {
            attrs: {
              href: _vm.routes.general_terms_conditions,
              title: _vm.translations.general_terms_conditions,
            },
          },
          [_vm._v(_vm._s(_vm.translations.our_conditions))]
        ),
        _vm._v(".\n    "),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "d-flex justify-content-between align-items-center mt-4" },
      [
        _c(
          "a",
          {
            staticClass: "uk-button-back",
            attrs: {
              href: _vm.routes.delivery_billing,
              title: _vm.translations.delivery_billing,
            },
          },
          [
            _c("i", { staticClass: "fa-solid fa-arrow-left me-2" }),
            _vm._v(
              "\n      " + _vm._s(_vm.translations.delivery_billing) + "\n    "
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "uk-button uk-button-primary",
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.handleCheckout.apply(null, arguments)
              },
            },
          },
          [
            _vm.isLoading
              ? _c("div", { staticClass: "loader" }, [
                  _c("i", {
                    staticClass: "fa-solid fa-circle-notch fa-spin me-2",
                  }),
                ])
              : _vm._e(),
            _vm._v("\n      " + _vm._s(_vm.translations.make_order) + "\n    "),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }