var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    { staticClass: "form-custom uk-grid-small", attrs: { "uk-grid": "" } },
    [
      _c("h3", { staticClass: "uk-text-primary" }, [
        _vm._v(_vm._s(_vm.translations.personal_data)),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "uk-width-1-1 mb-3" }, [
        _c(
          "div",
          {
            staticClass: "uk-margin uk-grid-small uk-child-width-auto uk-grid",
          },
          [
            _c("p", [
              _c("span", { staticClass: "uk-text-bold" }, [
                _vm._v(_vm._s(_vm.translations.account_type.self)),
              ]),
            ]),
            _vm._v(" "),
            _c("label", { staticClass: "custom-radio" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.accountType,
                    expression: "accountType",
                  },
                ],
                staticClass: "uk-radio me-2",
                attrs: { type: "radio", name: "accountType", value: "private" },
                domProps: { checked: _vm._q(_vm.accountType, "private") },
                on: {
                  change: function ($event) {
                    _vm.accountType = "private"
                  },
                },
              }),
              _vm._v(" "),
              _c("p", { staticClass: "my-0" }, [
                _vm._v(_vm._s(_vm.translations.account_type.private)),
              ]),
            ]),
            _vm._v(" "),
            _c("label", { staticClass: "custom-radio" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.accountType,
                    expression: "accountType",
                  },
                ],
                staticClass: "uk-radio me-2",
                attrs: {
                  type: "radio",
                  name: "accountType",
                  value: "business",
                },
                domProps: { checked: _vm._q(_vm.accountType, "business") },
                on: {
                  change: function ($event) {
                    _vm.accountType = "business"
                  },
                },
              }),
              _vm._v(" "),
              _c("p", { staticClass: "my-0" }, [
                _vm._v(_vm._s(_vm.translations.account_type.company)),
              ]),
            ]),
            _vm._v(" "),
            _vm.accountTypeErrors.length > 0
              ? _c(
                  "div",
                  { staticClass: "input-errors" },
                  _vm._l(_vm.accountTypeErrors, function (error) {
                    return _c("p", [_vm._v(_vm._s(error))])
                  }),
                  0
                )
              : _vm._e(),
          ]
        ),
      ]),
      _vm._v(" "),
      _vm.accountType == "business"
        ? _c("div", { staticClass: "uk-width-1-2@s" }, [
            _c(
              "label",
              { staticClass: "uk-form-label", attrs: { for: "companyname" } },
              [_vm._v(_vm._s(_vm.translations.company.companyname) + " *")]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "uk-form-controls" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.companyname,
                    expression: "companyname",
                  },
                ],
                staticClass: "uk-input",
                attrs: {
                  id: "companyname",
                  type: "text",
                  placeholder: _vm.translations.companyname,
                },
                domProps: { value: _vm.companyname },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.companyname = $event.target.value
                  },
                },
              }),
              _vm._v(" "),
              _vm.companynameErrors.length > 0
                ? _c(
                    "div",
                    { staticClass: "input-errors" },
                    _vm._l(_vm.companynameErrors, function (error) {
                      return _c("p", [_vm._v(_vm._s(error))])
                    }),
                    0
                  )
                : _vm._e(),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.accountType == "business"
        ? _c("div", { staticClass: "uk-width-1-4@s" }, [
            _c(
              "label",
              { staticClass: "uk-form-label", attrs: { for: "companytype" } },
              [_vm._v(_vm._s(_vm.translations.company.type) + " *")]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "uk-form-controls" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.companyType,
                      expression: "companyType",
                    },
                  ],
                  staticClass: "custom-select uk-width-1-1",
                  attrs: { name: "company-type", id: "companytype" },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.companyType = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                  },
                },
                _vm._l(_vm.legalForms, function (legalForm) {
                  return _c("option", { domProps: { value: legalForm } }, [
                    _vm._v(_vm._s(legalForm)),
                  ])
                }),
                0
              ),
              _vm._v(" "),
              _vm.companyTypeErrors.length > 0
                ? _c(
                    "div",
                    { staticClass: "input-errors" },
                    _vm._l(_vm.companyTypeErrors, function (error) {
                      return _c("p", [_vm._v(_vm._s(error))])
                    }),
                    0
                  )
                : _vm._e(),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.accountType == "business"
        ? _c("div", { staticClass: "uk-width-1-4@s" }, [
            _c(
              "label",
              { staticClass: "uk-form-label", attrs: { for: "companyvat" } },
              [_vm._v(_vm._s(_vm.translations.company.companynumber) + " *")]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "uk-form-controls" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.companyVat,
                    expression: "companyVat",
                  },
                ],
                staticClass: "uk-input",
                attrs: {
                  id: "companyvat",
                  type: "text",
                  placeholder: _vm.translations.company.companynumber,
                },
                domProps: { value: _vm.companyVat },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.companyVat = $event.target.value
                  },
                },
              }),
              _vm._v(" "),
              _vm.companyVatErrors.length > 0
                ? _c(
                    "div",
                    { staticClass: "input-errors" },
                    _vm._l(_vm.companyVatErrors, function (error) {
                      return _c("p", [_vm._v(_vm._s(error))])
                    }),
                    0
                  )
                : _vm._e(),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "uk-width-1-1 mb-3 mt-4" }, [
        _c(
          "div",
          {
            staticClass: "uk-margin uk-grid-small uk-child-width-auto uk-grid",
          },
          [
            _c("p", [
              _c("span", { staticClass: "uk-text-bold" }, [
                _vm._v(_vm._s(_vm.translations.salutation.self)),
              ]),
            ]),
            _vm._v(" "),
            _c("label", { staticClass: "custom-radio" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.salutation,
                    expression: "salutation",
                  },
                ],
                staticClass: "uk-radio me-2",
                attrs: { type: "radio", name: "salutation", value: "male" },
                domProps: { checked: _vm._q(_vm.salutation, "male") },
                on: {
                  change: function ($event) {
                    _vm.salutation = "male"
                  },
                },
              }),
              _vm._v(" "),
              _c("p", { staticClass: "my-0" }, [
                _vm._v(_vm._s(_vm.translations.salutation.male)),
              ]),
            ]),
            _vm._v(" "),
            _c("label", { staticClass: "custom-radio" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.salutation,
                    expression: "salutation",
                  },
                ],
                staticClass: "uk-radio me-2",
                attrs: { type: "radio", name: "salutation", value: "female" },
                domProps: { checked: _vm._q(_vm.salutation, "female") },
                on: {
                  change: function ($event) {
                    _vm.salutation = "female"
                  },
                },
              }),
              _vm._v(" "),
              _c("p", { staticClass: "my-0" }, [
                _vm._v(_vm._s(_vm.translations.salutation.female)),
              ]),
            ]),
            _vm._v(" "),
            _vm.salutationErrors.length > 0
              ? _c(
                  "div",
                  { staticClass: "input-errors" },
                  _vm._l(_vm.salutationErrors, function (error) {
                    return _c("p", [_vm._v(_vm._s(error))])
                  }),
                  0
                )
              : _vm._e(),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "uk-width-1-2@s" }, [
        _c(
          "label",
          { staticClass: "uk-form-label", attrs: { for: "firstname" } },
          [_vm._v(_vm._s(_vm.translations.firstname) + " *")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "uk-form-controls" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.firstname,
                expression: "firstname",
              },
            ],
            staticClass: "uk-input",
            attrs: {
              id: "firstname",
              type: "text",
              placeholder: _vm.translations.firstname,
            },
            domProps: { value: _vm.firstname },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.firstname = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _vm.firstnameErrors.length > 0
            ? _c(
                "div",
                { staticClass: "input-errors" },
                _vm._l(_vm.firstnameErrors, function (error) {
                  return _c("p", [_vm._v(_vm._s(error))])
                }),
                0
              )
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "uk-width-1-2@s" }, [
        _c(
          "label",
          { staticClass: "uk-form-label", attrs: { for: "lastname" } },
          [_vm._v(_vm._s(_vm.translations.lastname) + " *")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "uk-form-controls" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.lastname,
                expression: "lastname",
              },
            ],
            staticClass: "uk-input",
            attrs: {
              id: "lastname",
              type: "text",
              placeholder: _vm.translations.lastname,
            },
            domProps: { value: _vm.lastname },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.lastname = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _vm.lastnameErrors.length > 0
            ? _c(
                "div",
                { staticClass: "input-errors" },
                _vm._l(_vm.lastnameErrors, function (error) {
                  return _c("p", [_vm._v(_vm._s(error))])
                }),
                0
              )
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "uk-width-1-1" }, [
        _vm.userPhoneRequired
          ? _c(
              "label",
              { staticClass: "uk-form-label", attrs: { for: "phone" } },
              [_vm._v(_vm._s(_vm.translations.tel_or_cell) + " *")]
            )
          : _c(
              "label",
              { staticClass: "uk-form-label", attrs: { for: "phone" } },
              [_vm._v(_vm._s(_vm.translations.tel_or_cell))]
            ),
        _vm._v(" "),
        _c("div", { staticClass: "uk-form-controls" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.phone,
                expression: "phone",
              },
            ],
            staticClass: "uk-input",
            attrs: {
              id: "phone",
              type: "text",
              placeholder: _vm.translations.tel_or_cell,
            },
            domProps: { value: _vm.phone },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.phone = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _vm.phoneErrors.length > 0
            ? _c(
                "div",
                { staticClass: "input-errors" },
                _vm._l(_vm.phoneErrors, function (error) {
                  return _c("p", [_vm._v(_vm._s(error))])
                }),
                0
              )
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "uk-width-1-2@s" }, [
        _c(
          "label",
          { staticClass: "uk-form-label", attrs: { for: "street" } },
          [_vm._v(_vm._s(_vm.translations.address.streetname) + " *")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "uk-form-controls" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.street,
                expression: "street",
              },
            ],
            staticClass: "uk-input",
            attrs: {
              id: "street",
              type: "text",
              placeholder: _vm.translations.address.streetname,
            },
            domProps: { value: _vm.street },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.street = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _vm.streetErrors.length > 0
            ? _c(
                "div",
                { staticClass: "input-errors" },
                _vm._l(_vm.streetErrors, function (error) {
                  return _c("p", [_vm._v(_vm._s(error))])
                }),
                0
              )
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "uk-width-1-6@s" }, [
        _c(
          "label",
          { staticClass: "uk-form-label", attrs: { for: "number" } },
          [_vm._v(_vm._s(_vm.translations.address.housenr) + " *")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "uk-form-controls" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.number,
                expression: "number",
              },
            ],
            staticClass: "uk-input",
            attrs: {
              id: "number",
              type: "text",
              placeholder: _vm.translations.address.housenr,
            },
            domProps: { value: _vm.number },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.number = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _vm.numberErrors.length > 0
            ? _c(
                "div",
                { staticClass: "input-errors" },
                _vm._l(_vm.numberErrors, function (error) {
                  return _c("p", [_vm._v(_vm._s(error))])
                }),
                0
              )
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "uk-width-1-6@s" }, [
        _c(
          "label",
          { staticClass: "uk-form-label", attrs: { for: "number_addon" } },
          [_vm._v(_vm._s(_vm.translations.address.number_addon_short))]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "uk-form-controls" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.number_addon,
                expression: "number_addon",
              },
            ],
            staticClass: "uk-input",
            attrs: {
              id: "number_addon",
              type: "text",
              placeholder: _vm.translations.address.number_addon_short,
            },
            domProps: { value: _vm.number_addon },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.number_addon = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _vm.numberAddonErrors.length > 0
            ? _c(
                "div",
                { staticClass: "input-errors" },
                _vm._l(_vm.numberAddonErrors, function (error) {
                  return _c("p", [_vm._v(_vm._s(error))])
                }),
                0
              )
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "uk-width-1-6@s" }, [
        _c("label", { staticClass: "uk-form-label", attrs: { for: "busnr" } }, [
          _vm._v(_vm._s(_vm.translations.address.busnr)),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "uk-form-controls" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.busnr,
                expression: "busnr",
              },
            ],
            staticClass: "uk-input",
            attrs: {
              id: "busnr",
              type: "text",
              placeholder: _vm.translations.address.busnr,
            },
            domProps: { value: _vm.busnr },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.busnr = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _vm.busnrErrors.length > 0
            ? _c(
                "div",
                { staticClass: "input-errors" },
                _vm._l(_vm.busnrErrors, function (error) {
                  return _c("p", [_vm._v(_vm._s(error))])
                }),
                0
              )
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "uk-width-1-4@s" }, [
        _c(
          "label",
          { staticClass: "uk-form-label", attrs: { for: "postalcode" } },
          [_vm._v(_vm._s(_vm.translations.address.postalcode) + " *")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "uk-form-controls" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.postalcode,
                expression: "postalcode",
              },
            ],
            staticClass: "uk-input",
            attrs: {
              id: "postalcode",
              type: "text",
              placeholder: _vm.translations.address.postalcode,
            },
            domProps: { value: _vm.postalcode },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.postalcode = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _vm.postalcodeErrors.length > 0
            ? _c(
                "div",
                { staticClass: "input-errors" },
                _vm._l(_vm.postalcodeErrors, function (error) {
                  return _c("p", [_vm._v(_vm._s(error))])
                }),
                0
              )
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "uk-width-1-2@s" }, [
        _c(
          "label",
          { staticClass: "uk-form-label", attrs: { for: "commune" } },
          [_vm._v(_vm._s(_vm.translations.address.commune) + " *")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "uk-form-controls" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.commune,
                expression: "commune",
              },
            ],
            staticClass: "uk-input",
            attrs: {
              id: "commune",
              type: "text",
              placeholder: _vm.translations.address.commune,
            },
            domProps: { value: _vm.commune },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.commune = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _vm.communeErrors.length > 0
            ? _c(
                "div",
                { staticClass: "input-errors" },
                _vm._l(_vm.communeErrors, function (error) {
                  return _c("p", [_vm._v(_vm._s(error))])
                }),
                0
              )
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "uk-width-1-4@s" }, [
        _c(
          "label",
          { staticClass: "uk-form-label", attrs: { for: "country" } },
          [_vm._v(_vm._s(_vm.translations.address.country) + " *")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "uk-form-controls" }, [
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.country,
                  expression: "country",
                },
              ],
              staticClass: "custom-select uk-width-1-1",
              attrs: { name: "company-type", id: "country" },
              on: {
                change: function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.country = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                },
              },
            },
            _vm._l(_vm.countries, function (country) {
              return _c("option", { domProps: { value: country.id } }, [
                _vm._v(_vm._s(country.name)),
              ])
            }),
            0
          ),
          _vm._v(" "),
          _vm.countryErrors.length > 0
            ? _c(
                "div",
                { staticClass: "input-errors" },
                _vm._l(_vm.countryErrors, function (error) {
                  return _c("p", [_vm._v(_vm._s(error))])
                }),
                0
              )
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "uk-width-1-1 mt-4" }, [
        _c("h3", { staticClass: "uk-text-primary" }, [
          _vm._v(_vm._s(_vm.translations.login_data)),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "uk-text-small" }, [
          _vm._v(
            "\n      " + _vm._s(_vm.translations.login_data_expl) + "\n    "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "uk-width-1-3@s" }, [
        _c("label", { staticClass: "uk-form-label", attrs: { for: "email" } }, [
          _vm._v(_vm._s(_vm.translations.email) + " *"),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "uk-form-controls" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.email,
                expression: "email",
              },
            ],
            staticClass: "uk-input",
            attrs: {
              id: "email",
              type: "email",
              placeholder: _vm.translations.email,
            },
            domProps: { value: _vm.email },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.email = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _vm.emailErrors.length > 0
            ? _c(
                "div",
                { staticClass: "input-errors" },
                _vm._l(_vm.emailErrors, function (error) {
                  return _c("p", [_vm._v(_vm._s(error))])
                }),
                0
              )
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "uk-width-1-3@s" }, [
        _c(
          "label",
          { staticClass: "uk-form-label", attrs: { for: "password" } },
          [_vm._v(_vm._s(_vm.translations.password) + " *")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "uk-form-controls" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.password,
                expression: "password",
              },
            ],
            staticClass: "uk-input",
            attrs: {
              id: "password",
              type: "password",
              placeholder: _vm.translations.password,
            },
            domProps: { value: _vm.password },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.password = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _vm.passwordErrors.length > 0
            ? _c(
                "div",
                { staticClass: "input-errors" },
                _vm._l(_vm.passwordErrors, function (error) {
                  return _c("p", [_vm._v(_vm._s(error))])
                }),
                0
              )
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "uk-width-1-3@s" }, [
        _c(
          "label",
          { staticClass: "uk-form-label", attrs: { for: "confirm_password" } },
          [_vm._v(_vm._s(_vm.translations.password_confirm) + " *")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "uk-form-controls" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.confirmPassword,
                expression: "confirmPassword",
              },
            ],
            staticClass: "uk-input",
            attrs: {
              id: "confirm_password",
              type: "password",
              placeholder: _vm.translations.password_confirm,
            },
            domProps: { value: _vm.confirmPassword },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.confirmPassword = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _vm.confirmPasswordErrors.length > 0
            ? _c(
                "div",
                { staticClass: "input-errors" },
                _vm._l(_vm.confirmPasswordErrors, function (error) {
                  return _c("p", [_vm._v(_vm._s(error))])
                }),
                0
              )
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "uk-width-1-1 mt-sm-5 mt-4" }, [
        _c(
          "button",
          {
            staticClass: "uk-button uk-button-primary",
            attrs: { type: "submit" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.handleCreate.apply(null, arguments)
              },
            },
          },
          [
            _vm.isLoading
              ? _c("div", { staticClass: "loader" }, [
                  _c("i", {
                    staticClass: "fa-solid fa-circle-notch fa-spin me-2",
                  }),
                ])
              : _vm._e(),
            _vm._v(
              "\n      " + _vm._s(_vm.translations.create_account) + "\n    "
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }