var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "d-flex flex-sm-row flex-column align-items-baseline" },
        [
          _c("h2", { staticClass: "my-0 me-3" }, [
            _vm._v(_vm._s(_vm.activeProduct.use_price)),
          ]),
          _vm._v(" "),
          _vm.activeProduct.use_price != _vm.activeProduct.price
            ? _c("h5", { staticClass: "my-0 me-3 old-price" }, [
                _c("s", [_vm._v(_vm._s(_vm.activeProduct.price))]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.activeProduct.use_price != _vm.product.price
            ? _c("p", { staticClass: "my-0 body--small" }, [
                _c("strong", [_vm._v("Je bespaart 20%")]),
                _vm._v(" (bij min 3 stuks)\n    "),
              ])
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _vm.activeProduct.delivery_time
        ? _c("product-delivery-time", {
            staticClass: "mt-3 mb-4",
            attrs: { "delivery-time": _vm.activeProduct.delivery_time },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.sizes && _vm.sizes.length > 1
        ? _c("section", { staticClass: "mb-4" }, [
            _c("div", { staticClass: "size-picker" }, [
              _c("div", { staticClass: "d-flex align-items-baseline mb-2" }, [
                _c("p", { staticClass: "my-0 me-4" }, [
                  _c("strong", [
                    _vm._v(
                      _vm._s(_vm.translations.chose_a) +
                        " " +
                        _vm._s(_vm.activeProduct.size_group.name)
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "sizes" },
                _vm._l(_vm.sizes, function (size) {
                  return _c("product-configurator-size", {
                    key: size.id,
                    attrs: {
                      size: size,
                      variation: _vm.getVariation(_vm.colorId, size.id),
                      "active-size": _vm.sizeId,
                      "active-color": _vm.colorId,
                    },
                    on: { change: _vm.handleChange },
                  })
                }),
                1
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.colors && _vm.colors.length > 1
        ? _c("section", { staticClass: "mb-4" }, [
            _c("div", { staticClass: "d-flex align-items-baseline mb-2" }, [
              _c("p", { staticClass: "my-0 me-4" }, [
                _c("strong", [
                  _vm._v(
                    _vm._s(_vm.translations.chose_a) +
                      " " +
                      _vm._s(_vm.translations.color)
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "color-picker color-picker--wide" },
              _vm._l(_vm.colors, function (color) {
                return _c("product-configurator-color", {
                  key: color.id,
                  attrs: {
                    color: color,
                    variation: _vm.getVariation(color.id, _vm.sizeId),
                    "active-size": _vm.sizeId,
                    "active-color": _vm.colorId,
                  },
                  on: { change: [function ($event) {}, _vm.handleChange] },
                })
              }),
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "cart-container mt-sm-5" }, [
        _c(
          "div",
          { staticClass: "uk-width-1-6@m uk-width-small@s uk-width-1-3 me-3" },
          [
            _c("form", { staticClass: "form-custom" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.quantity,
                    expression: "quantity",
                  },
                ],
                staticClass: "uk-input uk-text-center",
                attrs: { type: "number", name: "amount", min: "1" },
                domProps: { value: _vm.quantity },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.quantity = $event.target.value
                  },
                },
              }),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("div", [
          _c(
            "button",
            {
              staticClass: "uk-button uk-button-cart uk-width-1-1",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.addToCart.apply(null, arguments)
                },
              },
            },
            [
              !_vm.isLoading
                ? _c("img", {
                    staticClass: "me-2 pb-1",
                    attrs: {
                      src: "/dist/assets/images/icons/cart.svg",
                      width: "20",
                      "uk-svg": "",
                    },
                  })
                : _c("div", { staticClass: "loader" }, [
                    _c("i", {
                      staticClass: "fa-solid fa-circle-notch fa-spin me-2",
                    }),
                  ]),
              _vm._v(
                "\n        " + _vm._s(_vm.translations.in_my_cart) + "\n      "
              ),
            ]
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }