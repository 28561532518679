var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    { staticClass: "form-custom uk-grid-small", attrs: { "uk-grid": "" } },
    [
      _c("h3", { staticClass: "mt-5 uk-text-primary" }, [
        _vm._v(_vm._s(_vm.translations.delivery_information)),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "uk-width-1-1 mb-3" }, [
        _c(
          "div",
          {
            staticClass: "uk-margin uk-grid-small uk-child-width-auto uk-grid",
          },
          [
            _c("p", [
              _c("span", { staticClass: "uk-text-bold" }, [
                _vm._v(_vm._s(_vm.translations.salutation.self)),
              ]),
            ]),
            _vm._v(" "),
            _c("label", { staticClass: "custom-radio" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.salutation,
                    expression: "salutation",
                  },
                ],
                staticClass: "uk-radio me-2",
                attrs: { type: "radio", name: "gender", value: "male" },
                domProps: { checked: _vm._q(_vm.salutation, "male") },
                on: {
                  change: function ($event) {
                    _vm.salutation = "male"
                  },
                },
              }),
              _vm._v(" "),
              _c("p", { staticClass: "my-0" }, [
                _vm._v(_vm._s(_vm.translations.salutation.male)),
              ]),
            ]),
            _vm._v(" "),
            _c("label", { staticClass: "custom-radio" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.salutation,
                    expression: "salutation",
                  },
                ],
                staticClass: "uk-radio me-2",
                attrs: { type: "radio", name: "gender", value: "female" },
                domProps: { checked: _vm._q(_vm.salutation, "female") },
                on: {
                  change: function ($event) {
                    _vm.salutation = "female"
                  },
                },
              }),
              _vm._v(" "),
              _c("p", { staticClass: "my-0" }, [
                _vm._v(_vm._s(_vm.translations.salutation.female)),
              ]),
            ]),
          ]
        ),
        _vm._v(" "),
        _vm.errors.salutation
          ? _c(
              "div",
              { staticClass: "input-errors" },
              _vm._l(_vm.errors.salutation, function (error) {
                return _c("p", [_vm._v(_vm._s(error))])
              }),
              0
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "uk-width-1-2@s" }, [
        _c(
          "label",
          { staticClass: "uk-form-label", attrs: { for: "firstname" } },
          [_vm._v(_vm._s(_vm.translations.firstname) + " *")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "uk-form-controls" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.firstname,
                expression: "firstname",
              },
            ],
            staticClass: "uk-input",
            attrs: {
              id: "firstname",
              type: "text",
              placeholder: _vm.translations.firstname,
            },
            domProps: { value: _vm.firstname },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.firstname = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _vm.errors.firstname
            ? _c(
                "div",
                { staticClass: "input-errors" },
                _vm._l(_vm.errors.firstname, function (error) {
                  return _c("p", [_vm._v(_vm._s(error))])
                }),
                0
              )
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "uk-width-1-2@s" }, [
        _c(
          "label",
          { staticClass: "uk-form-label", attrs: { for: "lastname" } },
          [_vm._v(_vm._s(_vm.translations.lastname) + " *")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "uk-form-controls" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.lastname,
                expression: "lastname",
              },
            ],
            staticClass: "uk-input",
            attrs: {
              id: "lastname",
              type: "text",
              placeholder: _vm.translations.lastname,
            },
            domProps: { value: _vm.lastname },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.lastname = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _vm.errors.lastname
            ? _c(
                "div",
                { staticClass: "input-errors" },
                _vm._l(_vm.errors.lastname, function (error) {
                  return _c("p", [_vm._v(_vm._s(error))])
                }),
                0
              )
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "uk-width-1-2@s" }, [
        _c(
          "label",
          {
            staticClass: "uk-form-label",
            attrs: { for: "companyname_delivery" },
          },
          [_vm._v(_vm._s(_vm.translations.company.companyname))]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "uk-form-controls" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.companyname_delivery,
                expression: "companyname_delivery",
              },
            ],
            staticClass: "uk-input",
            attrs: {
              id: "companyname_delivery",
              type: "text",
              placeholder: _vm.translations.company.companyname,
            },
            domProps: { value: _vm.companyname_delivery },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.companyname_delivery = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _vm.errors.companyname_delivery
            ? _c(
                "div",
                { staticClass: "input-errors" },
                _vm._l(_vm.errors.companyname_delivery, function (error) {
                  return _c("p", [_vm._v(_vm._s(error))])
                }),
                0
              )
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _vm.deliveryAddressRequired
        ? _c("div", { staticClass: "uk-width-1-1" }, [
            _c("label", { staticClass: "uk-form-label" }, [
              _vm._v(_vm._s(_vm.translations.address.delivery_address) + " *"),
            ]),
            _vm._v(" "),
            _vm.errors.delivery_address
              ? _c(
                  "div",
                  { staticClass: "input-errors" },
                  _vm._l(_vm.errors.delivery_address, function (error) {
                    return _c("p", [_vm._v(_vm._s(error))])
                  }),
                  0
                )
              : _vm._e(),
            _vm._v(" "),
            Object.keys(_vm.user).length > 0 &&
            Object.keys(_vm.user.addresses).length > 0
              ? _c(
                  "div",
                  { staticClass: "delivery" },
                  [
                    _vm._l(_vm.user.addresses, function (address) {
                      return _c("address-card", {
                        key: address.id,
                        attrs: {
                          "init-address": address,
                          "active-address": _vm.active_delivery,
                          type: "delivery",
                        },
                        on: { addressPick: _vm.addressPick },
                      })
                    }),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "delivery__new",
                        class: { active: _vm.newDeliveryAddressActive },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.handleNewAddress("delivery")
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "fa-solid fa-plus me-2" }),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(_vm._s(_vm.translations.address.new)),
                        ]),
                      ]
                    ),
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "uk-grid-small uk-grid",
                class: { "d-none": !_vm.newDeliveryAddressActive },
                attrs: { id: "new-delivery", "uk-grid": "" },
              },
              [
                _c("div", { staticClass: "uk-width-1-2@s" }, [
                  _c(
                    "label",
                    {
                      staticClass: "uk-form-label",
                      attrs: { for: "new_delivery_street" },
                    },
                    [_vm._v(_vm._s(_vm.translations.address.streetname) + " *")]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "uk-form-controls" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.new_delivery_address.street,
                          expression: "new_delivery_address.street",
                        },
                      ],
                      staticClass: "uk-input",
                      attrs: {
                        id: "new_delivery_street",
                        type: "text",
                        placeholder: _vm.translations.address.streetname,
                      },
                      domProps: { value: _vm.new_delivery_address.street },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.new_delivery_address,
                            "street",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.errors["new_delivery_address.street"]
                      ? _c(
                          "div",
                          { staticClass: "input-errors" },
                          _vm._l(
                            _vm.errors["new_delivery_address.street"],
                            function (error) {
                              return _c("p", [_vm._v(_vm._s(error))])
                            }
                          ),
                          0
                        )
                      : _vm._e(),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "uk-width-1-6@s" }, [
                  _c(
                    "label",
                    {
                      staticClass: "uk-form-label",
                      attrs: { for: "new_delivery_housenr" },
                    },
                    [_vm._v(_vm._s(_vm.translations.address.housenr) + " *")]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "uk-form-controls" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.new_delivery_address.number,
                          expression: "new_delivery_address.number",
                        },
                      ],
                      staticClass: "uk-input",
                      attrs: {
                        id: "new_delivery_housenr",
                        type: "text",
                        placeholder: _vm.translations.address.housenr,
                      },
                      domProps: { value: _vm.new_delivery_address.number },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.new_delivery_address,
                            "number",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.errors["new_delivery_address.number"]
                      ? _c(
                          "div",
                          { staticClass: "input-errors" },
                          _vm._l(
                            _vm.errors["new_delivery_address.number"],
                            function (error) {
                              return _c("p", [_vm._v(_vm._s(error))])
                            }
                          ),
                          0
                        )
                      : _vm._e(),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "uk-width-1-6@s" }, [
                  _c(
                    "label",
                    {
                      staticClass: "uk-form-label",
                      attrs: { for: "new_delivery_housenr_addon" },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.translations.address.number_addon_short)
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "uk-form-controls" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.new_delivery_address.number_addon,
                          expression: "new_delivery_address.number_addon",
                        },
                      ],
                      staticClass: "uk-input",
                      attrs: {
                        id: "new_delivery_housenr_addon",
                        type: "text",
                        placeholder:
                          _vm.translations.address.number_addon_short,
                      },
                      domProps: {
                        value: _vm.new_delivery_address.number_addon,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.new_delivery_address,
                            "number_addon",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.errors["new_delivery_address.number_addon"]
                      ? _c(
                          "div",
                          { staticClass: "input-errors" },
                          _vm._l(
                            _vm.errors["new_delivery_address.number_addon"],
                            function (error) {
                              return _c("p", [_vm._v(_vm._s(error))])
                            }
                          ),
                          0
                        )
                      : _vm._e(),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "uk-width-1-6@s" }, [
                  _c(
                    "label",
                    {
                      staticClass: "uk-form-label",
                      attrs: { for: "new_delivery_busnr" },
                    },
                    [_vm._v(_vm._s(_vm.translations.address.busnr))]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "uk-form-controls" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.new_delivery_address.busnumber,
                          expression: "new_delivery_address.busnumber",
                        },
                      ],
                      staticClass: "uk-input",
                      attrs: {
                        id: "new_delivery_busnr",
                        type: "text",
                        placeholder: _vm.translations.address.busnr,
                      },
                      domProps: { value: _vm.new_delivery_address.busnumber },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.new_delivery_address,
                            "busnumber",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.errors["new_delivery_address.busnumber"]
                      ? _c(
                          "div",
                          { staticClass: "input-errors" },
                          _vm._l(
                            _vm.errors["new_delivery_address.busnumber"],
                            function (error) {
                              return _c("p", [_vm._v(_vm._s(error))])
                            }
                          ),
                          0
                        )
                      : _vm._e(),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "uk-width-1-4@s" }, [
                  _c(
                    "label",
                    {
                      staticClass: "uk-form-label",
                      attrs: { for: "new_delivery_postalcode" },
                    },
                    [_vm._v(_vm._s(_vm.translations.address.postalcode) + " *")]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "uk-form-controls" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.new_delivery_address.postalcode,
                          expression: "new_delivery_address.postalcode",
                        },
                      ],
                      staticClass: "uk-input",
                      attrs: {
                        id: "new_delivery_postalcode",
                        type: "text",
                        placeholder: _vm.translations.address.postalcode,
                      },
                      domProps: { value: _vm.new_delivery_address.postalcode },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.new_delivery_address,
                            "postalcode",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.errors["new_delivery_address.postalcode"]
                      ? _c(
                          "div",
                          { staticClass: "input-errors" },
                          _vm._l(
                            _vm.errors["new_delivery_address.postalcode"],
                            function (error) {
                              return _c("p", [_vm._v(_vm._s(error))])
                            }
                          ),
                          0
                        )
                      : _vm._e(),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "uk-width-1-2@s" }, [
                  _c(
                    "label",
                    {
                      staticClass: "uk-form-label",
                      attrs: { for: "new_delivery_commune" },
                    },
                    [_vm._v(_vm._s(_vm.translations.address.commune) + " *")]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "uk-form-controls" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.new_delivery_address.commune,
                          expression: "new_delivery_address.commune",
                        },
                      ],
                      staticClass: "uk-input",
                      attrs: {
                        id: "new_delivery_commune",
                        type: "text",
                        placeholder: _vm.translations.address.commune,
                      },
                      domProps: { value: _vm.new_delivery_address.commune },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.new_delivery_address,
                            "commune",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.errors["new_delivery_address.commune"]
                      ? _c(
                          "div",
                          { staticClass: "input-errors" },
                          _vm._l(
                            _vm.errors["new_delivery_address.commune"],
                            function (error) {
                              return _c("p", [_vm._v(_vm._s(error))])
                            }
                          ),
                          0
                        )
                      : _vm._e(),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "uk-width-1-4@s" }, [
                  _c(
                    "label",
                    {
                      staticClass: "uk-form-label",
                      attrs: { for: "new_delivery_country" },
                    },
                    [_vm._v(_vm._s(_vm.translations.address.country) + " *")]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "uk-form-controls" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.new_delivery_address.country,
                            expression: "new_delivery_address.country",
                          },
                        ],
                        staticClass: "custom-select uk-width-1-1",
                        attrs: { name: "country", id: "new_delivery_country" },
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.new_delivery_address,
                              "country",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      _vm._l(_vm.countries, function (country) {
                        return _c(
                          "option",
                          { domProps: { value: country.id } },
                          [_vm._v(_vm._s(country.name))]
                        )
                      }),
                      0
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.errors["new_delivery_address.country"]
                    ? _c(
                        "div",
                        { staticClass: "input-errors" },
                        _vm._l(
                          _vm.errors["new_delivery_address.country"],
                          function (error) {
                            return _c("p", [_vm._v(_vm._s(error))])
                          }
                        ),
                        0
                      )
                    : _vm._e(),
                ]),
              ]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "uk-width-1-1 mt-4 mb-3" }, [
        _c("h3", { staticClass: "mt-5 uk-text-primary" }, [
          _vm._v(_vm._s(_vm.translations.facturation_data)),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "uk-width-1-1 mb-3" }, [
        _c(
          "div",
          {
            staticClass: "uk-margin uk-grid-small uk-child-width-auto uk-grid",
          },
          [
            _c("p", [
              _c("span", { staticClass: "uk-text-bold" }, [
                _vm._v(_vm._s(_vm.translations.order_type)),
              ]),
            ]),
            _vm._v(" "),
            _c("label", { staticClass: "custom-radio" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.order_type,
                    expression: "order_type",
                  },
                ],
                staticClass: "uk-radio me-2",
                attrs: { type: "radio", name: "order_type", value: "private" },
                domProps: { checked: _vm._q(_vm.order_type, "private") },
                on: {
                  change: function ($event) {
                    _vm.order_type = "private"
                  },
                },
              }),
              _vm._v(" "),
              _c("p", { staticClass: "my-0" }, [
                _vm._v(_vm._s(_vm.translations.account_type.private)),
              ]),
            ]),
            _vm._v(" "),
            _c("label", { staticClass: "custom-radio" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.order_type,
                    expression: "order_type",
                  },
                ],
                staticClass: "uk-radio me-2",
                attrs: { type: "radio", name: "order_type", value: "business" },
                domProps: { checked: _vm._q(_vm.order_type, "business") },
                on: {
                  change: function ($event) {
                    _vm.order_type = "business"
                  },
                },
              }),
              _vm._v(" "),
              _c("p", { staticClass: "my-0" }, [
                _vm._v(_vm._s(_vm.translations.account_type.company)),
              ]),
            ]),
          ]
        ),
        _vm._v(" "),
        _vm.errors.order_type
          ? _c(
              "div",
              { staticClass: "input-errors" },
              _vm._l(_vm.errors.order_type, function (error) {
                return _c("p", [_vm._v(_vm._s(error))])
              }),
              0
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _vm.order_type === "business"
        ? _c("div", { staticClass: "uk-width-1-2@s" }, [
            _c(
              "label",
              { staticClass: "uk-form-label", attrs: { for: "companyname" } },
              [_vm._v(_vm._s(_vm.translations.company.companyname) + " *")]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "uk-form-controls" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.companyname,
                    expression: "companyname",
                  },
                ],
                staticClass: "uk-input",
                attrs: {
                  id: "companyname",
                  type: "text",
                  placeholder: _vm.translations.company.companyname,
                },
                domProps: { value: _vm.companyname },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.companyname = $event.target.value
                  },
                },
              }),
              _vm._v(" "),
              _vm.errors.companyname
                ? _c(
                    "div",
                    { staticClass: "input-errors" },
                    _vm._l(_vm.errors.companyname, function (error) {
                      return _c("p", [_vm._v(_vm._s(error))])
                    }),
                    0
                  )
                : _vm._e(),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.order_type === "business"
        ? _c("div", { staticClass: "uk-width-1-4@s" }, [
            _c("label", { staticClass: "uk-form-label" }, [
              _vm._v(_vm._s(_vm.translations.company.type) + " *"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "uk-form-controls" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.company_type,
                      expression: "company_type",
                    },
                  ],
                  staticClass: "custom-select uk-width-1-1",
                  attrs: { name: "company-type" },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.company_type = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                  },
                },
                _vm._l(_vm.legalForms, function (legalForm) {
                  return _c("option", { domProps: { value: legalForm } }, [
                    _vm._v(_vm._s(legalForm)),
                  ])
                }),
                0
              ),
            ]),
            _vm._v(" "),
            _vm.errors.company_type
              ? _c(
                  "div",
                  { staticClass: "input-errors" },
                  _vm._l(_vm.errors.company_type, function (error) {
                    return _c("p", [_vm._v(_vm._s(error))])
                  }),
                  0
                )
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.order_type === "business"
        ? _c("div", { staticClass: "uk-width-1-4@s" }, [
            _c(
              "label",
              { staticClass: "uk-form-label", attrs: { for: "companynumber" } },
              [_vm._v(_vm._s(_vm.translations.company.companynumber) + " *")]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "uk-form-controls" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.company_vat,
                    expression: "company_vat",
                  },
                ],
                staticClass: "uk-input",
                attrs: {
                  id: "companynumber",
                  type: "text",
                  placeholder: _vm.translations.company.companynumber,
                },
                domProps: { value: _vm.company_vat },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.company_vat = $event.target.value
                  },
                },
              }),
              _vm._v(" "),
              _vm.errors.company_vat
                ? _c(
                    "div",
                    { staticClass: "input-errors" },
                    _vm._l(_vm.errors.company_vat, function (error) {
                      return _c("p", [_vm._v(_vm._s(error))])
                    }),
                    0
                  )
                : _vm._e(),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "uk-width-1-1 mt-4" }, [
        _c(
          "div",
          {
            staticClass: "uk-margin uk-grid-small uk-child-width-auto uk-grid",
          },
          [
            _c("p", [
              _c("span", { staticClass: "uk-text-bold" }, [
                _vm._v(_vm._s(_vm.translations.address.facturation) + " *"),
              ]),
            ]),
            _vm._v(" "),
            _vm.deliveryAddressRequired
              ? _c("label", { staticClass: "custom-checkbox" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.facturation_same_delivery,
                        expression: "facturation_same_delivery",
                      },
                    ],
                    staticClass: "uk-checkbox me-2 mt-1",
                    attrs: { type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(_vm.facturation_same_delivery)
                        ? _vm._i(_vm.facturation_same_delivery, null) > -1
                        : _vm.facturation_same_delivery,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.facturation_same_delivery,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              (_vm.facturation_same_delivery = $$a.concat([
                                $$v,
                              ]))
                          } else {
                            $$i > -1 &&
                              (_vm.facturation_same_delivery = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.facturation_same_delivery = $$c
                        }
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("p", { staticClass: "my-0" }, [
                    _vm._v(_vm._s(_vm.translations.address.same_as_delivery)),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.errors.facturation_same_delivery && _vm.deliveryAddressRequired
              ? _c(
                  "div",
                  { staticClass: "input-errors" },
                  _vm._l(
                    _vm.errors.facturation_same_delivery,
                    function (error) {
                      return _c("p", [_vm._v(_vm._s(error))])
                    }
                  ),
                  0
                )
              : _vm._e(),
          ]
        ),
      ]),
      _vm._v(" "),
      !_vm.facturation_same_delivery
        ? _c("div", { staticClass: "uk-width-1-1" }, [
            _vm.errors.billing_address
              ? _c(
                  "div",
                  { staticClass: "input-errors" },
                  _vm._l(_vm.errors.billing_address, function (error) {
                    return _c("p", [_vm._v(_vm._s(error))])
                  }),
                  0
                )
              : _vm._e(),
            _vm._v(" "),
            Object.keys(_vm.user).length > 0 &&
            Object.keys(_vm.user.addresses).length > 0
              ? _c(
                  "div",
                  { staticClass: "delivery" },
                  [
                    _vm._l(_vm.user.addresses, function (address) {
                      return _c("address-card", {
                        key: "billing-" + address.id,
                        attrs: {
                          "init-address": address,
                          "active-address": _vm.active_billing,
                          type: "billing",
                        },
                        on: { addressPick: _vm.addressPick },
                      })
                    }),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "delivery__new",
                        class: { active: _vm.newBillingAddressActive },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.handleNewAddress("billing")
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "fa-solid fa-plus me-2" }),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(_vm._s(_vm.translations.address.new)),
                        ]),
                      ]
                    ),
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "uk-grid-small uk-grid",
                class: { "d-none": !_vm.newBillingAddressActive },
                attrs: { id: "new-billing", "uk-grid": "" },
              },
              [
                _c("div", { staticClass: "uk-width-1-2@s" }, [
                  _c(
                    "label",
                    {
                      staticClass: "uk-form-label",
                      attrs: { for: "new_billing_street" },
                    },
                    [_vm._v(_vm._s(_vm.translations.address.streetname) + " *")]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "uk-form-controls" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.new_billing_address.street,
                          expression: "new_billing_address.street",
                        },
                      ],
                      staticClass: "uk-input",
                      attrs: {
                        id: "new_billing_street",
                        type: "text",
                        placeholder: _vm.translations.address.streetname,
                      },
                      domProps: { value: _vm.new_billing_address.street },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.new_billing_address,
                            "street",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.errors["new_billing_address.street"]
                      ? _c(
                          "div",
                          { staticClass: "input-errors" },
                          _vm._l(
                            _vm.errors["new_billing_address.street"],
                            function (error) {
                              return _c("p", [_vm._v(_vm._s(error))])
                            }
                          ),
                          0
                        )
                      : _vm._e(),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "uk-width-1-6@s" }, [
                  _c(
                    "label",
                    {
                      staticClass: "uk-form-label",
                      attrs: { for: "new_billing_housenr" },
                    },
                    [_vm._v(_vm._s(_vm.translations.address.housenr) + " *")]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "uk-form-controls" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.new_billing_address.number,
                          expression: "new_billing_address.number",
                        },
                      ],
                      staticClass: "uk-input",
                      attrs: {
                        id: "new_billing_housenr",
                        type: "text",
                        placeholder: _vm.translations.address.housenr,
                      },
                      domProps: { value: _vm.new_billing_address.number },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.new_billing_address,
                            "number",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.errors["new_billing_address.number"]
                      ? _c(
                          "div",
                          { staticClass: "input-errors" },
                          _vm._l(
                            _vm.errors["new_billing_address.number"],
                            function (error) {
                              return _c("p", [_vm._v(_vm._s(error))])
                            }
                          ),
                          0
                        )
                      : _vm._e(),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "uk-width-1-6@s" }, [
                  _c(
                    "label",
                    {
                      staticClass: "uk-form-label",
                      attrs: { for: "new_billing_housenr_addon" },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.translations.address.number_addon_short)
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "uk-form-controls" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.new_billing_address.number_addon,
                          expression: "new_billing_address.number_addon",
                        },
                      ],
                      staticClass: "uk-input",
                      attrs: {
                        id: "new_billing_housenr_addon",
                        type: "text",
                        placeholder:
                          _vm.translations.address.number_addon_short,
                      },
                      domProps: { value: _vm.new_billing_address.number_addon },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.new_billing_address,
                            "number_addon",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.errors["new_billing_address.number_addon"]
                      ? _c(
                          "div",
                          { staticClass: "input-errors" },
                          _vm._l(
                            _vm.errors["new_billing_address.number_addon"],
                            function (error) {
                              return _c("p", [_vm._v(_vm._s(error))])
                            }
                          ),
                          0
                        )
                      : _vm._e(),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "uk-width-1-6@s" }, [
                  _c(
                    "label",
                    {
                      staticClass: "uk-form-label",
                      attrs: { for: "new_billing_busnr" },
                    },
                    [_vm._v(_vm._s(_vm.translations.address.busnr))]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "uk-form-controls" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.new_billing_address.busnumber,
                          expression: "new_billing_address.busnumber",
                        },
                      ],
                      staticClass: "uk-input",
                      attrs: {
                        id: "new_billing_busnr",
                        type: "text",
                        placeholder: _vm.translations.address.busnr,
                      },
                      domProps: { value: _vm.new_billing_address.busnumber },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.new_billing_address,
                            "busnumber",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.errors["new_billing_address.busnumber"]
                      ? _c(
                          "div",
                          { staticClass: "input-errors" },
                          _vm._l(
                            _vm.errors["new_billing_address.busnumber"],
                            function (error) {
                              return _c("p", [_vm._v(_vm._s(error))])
                            }
                          ),
                          0
                        )
                      : _vm._e(),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "uk-width-1-4@s" }, [
                  _c(
                    "label",
                    {
                      staticClass: "uk-form-label",
                      attrs: { for: "new_billing_postalcode" },
                    },
                    [_vm._v(_vm._s(_vm.translations.address.postalcode) + " *")]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "uk-form-controls" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.new_billing_address.postalcode,
                          expression: "new_billing_address.postalcode",
                        },
                      ],
                      staticClass: "uk-input",
                      attrs: {
                        id: "new_billing_postalcode",
                        type: "text",
                        placeholder: _vm.translations.address.postalcode,
                      },
                      domProps: { value: _vm.new_billing_address.postalcode },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.new_billing_address,
                            "postalcode",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.errors["new_billing_address.postalcode"]
                      ? _c(
                          "div",
                          { staticClass: "input-errors" },
                          _vm._l(
                            _vm.errors["new_billing_address.postalcode"],
                            function (error) {
                              return _c("p", [_vm._v(_vm._s(error))])
                            }
                          ),
                          0
                        )
                      : _vm._e(),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "uk-width-1-2@s" }, [
                  _c(
                    "label",
                    {
                      staticClass: "uk-form-label",
                      attrs: { for: "new_billing_commune" },
                    },
                    [_vm._v(_vm._s(_vm.translations.address.commune) + " *")]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "uk-form-controls" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.new_billing_address.commune,
                          expression: "new_billing_address.commune",
                        },
                      ],
                      staticClass: "uk-input",
                      attrs: {
                        id: "new_billing_commune",
                        type: "text",
                        placeholder: _vm.translations.address.commune,
                      },
                      domProps: { value: _vm.new_billing_address.commune },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.new_billing_address,
                            "commune",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.errors["new_billing_address.commune"]
                      ? _c(
                          "div",
                          { staticClass: "input-errors" },
                          _vm._l(
                            _vm.errors["new_billing_address.commune"],
                            function (error) {
                              return _c("p", [_vm._v(_vm._s(error))])
                            }
                          ),
                          0
                        )
                      : _vm._e(),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "uk-width-1-4@s" }, [
                  _c(
                    "label",
                    {
                      staticClass: "uk-form-label",
                      attrs: { for: "new_billing_country" },
                    },
                    [_vm._v(_vm._s(_vm.translations.address.country) + " *")]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "uk-form-controls" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.new_billing_address.country,
                            expression: "new_billing_address.country",
                          },
                        ],
                        staticClass: "custom-select uk-width-1-1",
                        attrs: { name: "country", id: "new_billing_country" },
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.new_billing_address,
                              "country",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      _vm._l(_vm.countries, function (country) {
                        return _c(
                          "option",
                          { domProps: { value: country.id } },
                          [_vm._v(_vm._s(country.name))]
                        )
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _vm.errors["new_billing_address.country"]
                      ? _c(
                          "div",
                          { staticClass: "input-errors" },
                          _vm._l(
                            _vm.errors["new_billing_address.country"],
                            function (error) {
                              return _c("p", [_vm._v(_vm._s(error))])
                            }
                          ),
                          0
                        )
                      : _vm._e(),
                  ]),
                ]),
              ]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "uk-width-1-1 mt-4 mb-3" }, [
        _c("h3", { staticClass: "mt-5 uk-text-primary" }, [
          _vm._v(_vm._s(_vm.translations.contact_info)),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "uk-width-1-2@s" }, [
        _c("label", { staticClass: "uk-form-label", attrs: { for: "email" } }, [
          _vm._v(_vm._s(_vm.translations.email) + " *"),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "uk-form-controls" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.email,
                expression: "email",
              },
            ],
            staticClass: "uk-input",
            attrs: {
              id: "email",
              type: "email",
              placeholder: _vm.translations.email,
            },
            domProps: { value: _vm.email },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.email = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _vm.errors.email
            ? _c(
                "div",
                { staticClass: "input-errors" },
                _vm._l(_vm.errors.email, function (error) {
                  return _c("p", [_vm._v(_vm._s(error))])
                }),
                0
              )
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "uk-width-1-2@s" }, [
        _vm.userPhoneRequired
          ? _c(
              "label",
              { staticClass: "uk-form-label", attrs: { for: "phone" } },
              [_vm._v(_vm._s(_vm.translations.tel_or_cell) + " *")]
            )
          : _c(
              "label",
              { staticClass: "uk-form-label", attrs: { for: "phone" } },
              [_vm._v(_vm._s(_vm.translations.tel_or_cell))]
            ),
        _vm._v(" "),
        _c("div", { staticClass: "uk-form-controls" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.phone,
                expression: "phone",
              },
            ],
            staticClass: "uk-input",
            attrs: {
              id: "phone",
              type: "text",
              placeholder: _vm.translations.tel_or_cell,
            },
            domProps: { value: _vm.phone },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.phone = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _vm.errors.phone
            ? _c(
                "div",
                { staticClass: "input-errors" },
                _vm._l(_vm.errors.phone, function (error) {
                  return _c("p", [_vm._v(_vm._s(error))])
                }),
                0
              )
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "uk-width-1-1 mt-sm-5 mt-4" }, [
        _c(
          "div",
          { staticClass: "d-flex justify-content-between align-items-center" },
          [
            _c(
              "a",
              {
                staticClass: "uk-button-back",
                attrs: {
                  href: _vm.routes.back_to_cart,
                  title: _vm.translations.cart,
                },
              },
              [
                _c("i", { staticClass: "fa-solid fa-arrow-left me-2" }),
                _vm._v(_vm._s(_vm.translations.cart)),
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "uk-button uk-button-primary",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.handleValidate.apply(null, arguments)
                  },
                },
              },
              [
                _vm.isLoading
                  ? _c("div", { staticClass: "loader" }, [
                      _c("i", {
                        staticClass: "fa-solid fa-circle-notch fa-spin me-2",
                      }),
                    ])
                  : _vm._e(),
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.translations.to_overview) +
                    "\n      "
                ),
              ]
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }