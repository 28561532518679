<template>
    <ul class="sidebar__nav">
        <filter-sidebar-dynamic-filters-radios-item
            v-for="filtervalue in filter.values"
            :filter="filtervalue"
            :labelid="filter.id"
            :active-filters="localActiveFilters"
            :filter-values="filter.values"
            :key="filtervalue.id"
            @input="handleChange"
        >
        </filter-sidebar-dynamic-filters-radios-item>
    </ul>
</template>

<script>
    export default {
        name: "FilterSidebarDynamicFiltersRadios",
        mounted() {
            
        },
        data() {
            return {
                localActiveFilters: this.activeFilters
            }
        },
        methods: {
            handleChange(val){
                this.localActiveFilters = Object.assign({}, val);
                this.$emit('input', this.localActiveFilters);
            }
        },
        props: {
            filter: {
                type: Object|Array,
                required: true
            },
            activeFilters: {
                type: Object|Array,
                required: true
            }
        }
    }
</script>

<style scoped>
</style>