<template>
  <span class="uk-badge ms-3">{{ productCount }}</span>
</template>

<script>
  import { EventBus } from "../EventBus";

  export default {
    name: "CategoryPageProductsCount",
    mounted() {
      EventBus.$on('RELOAD_PRODUCTS', products => {
        this.productCount = products.productsCount;
      });
    },
    data() {
      return {
        productCount: this.initProducts.productsCount
      }
    },
    computed: {
    },
    methods: {

    },
    props: {
      initProducts: {
        type: Object|Array,
        required: true
      }
    }
  }
</script>

<style scoped>

</style>