var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "sidebar__nav" },
    _vm._l(_vm.filter.values, function (filtervalue) {
      return _c("filter-sidebar-dynamic-filters-checks-item", {
        key: filtervalue.id,
        attrs: {
          filter: filtervalue,
          labelid: _vm.filter.id,
          "default-checked": _vm.localActiveFilters[filtervalue.id] || false,
        },
        on: { input: _vm.handleChange },
        model: {
          value: _vm.localActiveFilters[filtervalue.id],
          callback: function ($$v) {
            _vm.$set(_vm.localActiveFilters, filtervalue.id, $$v)
          },
          expression: "localActiveFilters[filtervalue.id]",
        },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }