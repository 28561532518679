<template>
    <div class="d-flex" v-bind:class="{ 'justify-content-center' : large }">
      <img :src="method.img" :alt="method.name" :width="imgWidth" :height="imgHeight" :uk-tooltip="method.name" draggable="false" class="mr-2 mb-0" v-for="method in methods">
    </div>
</template>

<script>
    export default {
        name: "MolliePaymentMethods",
        mounted() {
            this.fetchPaymentMethods();
        },
        data() {
            return {
                methods: []
            }
        },
        methods: {
            fetchPaymentMethods() {
                window.axios.get(this.routes.getMethods)
                  .then(res => {
                      this.methods = res.data;
                  });
            }
        },
        computed: {
            imgWidth() {
                return this.large ? 64 : 32;
            },
            imgHeight() {
                return this.large ? 48 : 24;
            }
        },
        props: {
            routes: {
                type: Object|Array,
                required: true
            },
            large : {
                type: Boolean,
                required: false,
                default: false
            }
        }
    }
</script>

<style scoped>

</style>