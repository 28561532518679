var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "h-mt--60" }, [
    _c(
      "ul",
      {
        staticClass:
          "uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-4@l uk-grid-small",
        attrs: { "uk-grid": "" },
      },
      [
        _vm._l(_vm.products, function (product) {
          return !_vm.productsLoading
            ? _c(
                "li",
                [
                  _c("product-card", {
                    key: product.id,
                    attrs: {
                      product: product,
                      routes: _vm.routes,
                      translations: _vm.translations,
                    },
                  }),
                ],
                1
              )
            : _vm._e()
        }),
        _vm._v(" "),
        _vm._l(_vm.pwProductsCount, function (i) {
          return !_vm.products
            ? _c("li", { key: i }, [_vm._m(0, true)])
            : _vm._e()
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "product product--placeholder",
        attrs: { href: "javascript:void(0)" },
      },
      [
        _c("div", { staticClass: "product__header" }, [
          _c("img", {
            staticClass: "product__image",
            attrs: {
              src: "/dist/assets/images/placeholders/product-placeholder--thumb.jpg",
              alt: "product",
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "product__info" }, [
          _c("p", { staticClass: "product__title" }, [_vm._v("---")]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "product__footer" }, [
          _c("div", [
            _c("div", { staticClass: "product__price mb-2" }, [
              _c("p", { staticClass: "product__price--current" }, [
                _vm._v("--"),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("button", { staticClass: "product__cart" }, [
            _c("img", {
              attrs: {
                src: "/dist/assets/images/icons/cart.svg",
                width: "20",
                "uk-svg": "",
              },
            }),
          ]),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }