var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("li", [
    _c(
      "a",
      {
        attrs: { href: "javascript:void(0)" },
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.handleLogout.apply(null, arguments)
          },
        },
      },
      [
        _vm.isLoading
          ? _c("div", { staticClass: "loader" }, [
              _c("i", { staticClass: "fa-solid fa-circle-notch fa-spin me-2" }),
            ])
          : _vm._e(),
        _vm._v("\n    " + _vm._s(_vm.translations.logout) + "\n  "),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }