<template>
  <form class="form--password-reset uk-child-width-1-1 uk-grid-medium uk-width-1-1 mt-5" uk-grid>
    <div>
      <label class="uk-form-label" for="email">{{ translations.password }} *</label>
      <div class="uk-form-controls">
        <input
          type="password"
          v-model="password"
          name="password"
          class="uk-input"
        />
      </div>
      <div class="input-errors" v-if="passwordErrors.length > 0">
        <p v-for="error in passwordErrors">{{ error }}</p>
      </div>
    </div>

    <div>
      <label class="uk-form-label" for="password_confirmation">{{ translations.password_confirmation }} *</label>
      <div class="uk-form-controls">
        <input
            type="password"
            v-model="password_confirmation"
            name="password_confirmation"
            class="uk-input"
        />
      </div>
      <div class="input-errors" v-if="passwordErrors.length > 0">
        <p v-for="error in passwordErrors">{{ error }}</p>
      </div>
    </div>

    <div class="uk-width-1-1 mt-5">
      <div class="d-flex flex-sm-row flex-column align-items-sm-center">
        <button
          type="submit"
          @click.prevent="handleReset"
          class="uk-button uk-button-secondary uk-width-1-1 mb-sm-0 mb-4"
        >
          <div class="loader" v-if="isLoading"><i class="fa-solid fa-circle-notch fa-spin me-2"></i></div>
          {{ translations.reset_password }}
        </button>
      </div>
    </div>

  </form>
</template>

<script>
import { EventBus } from '../EventBus';

export default {
  name: 'PasswordResetForm',
  mounted() {},
  data() {
    return {
      password: '',
      password_confirmation: '',
      passwordErrors: {},
      passwordConfirmationErrors: {},
      isLoading: false,
    };
  },
  methods: {
    handleReset() {
      this.isLoading = true;
      window.axios
        .post(this.routes.password_reset, {
          email: this.email,
          token: this.resetToken,
          password: this.password,
          password_confirmation: this.password_confirmation
        })
        .then((res) => {
          this.passwordErrors = {};

          let resData = res.data;
          if (resData.success) {
            window.location.href = this.routes.login_page;
          } else {
            EventBus.$emit('NOTIFY', {
              message: resData.message,
              status: 'danger',
              position: 'top-right',
              timeout: 3000,
            });
            this.isLoading = false;
          }
        })
        .catch((err) => {
          this.passwordErrors = {};

          this.passwordErrors = err?.response?.data?.password ?? {};

          EventBus.$emit('NOTIFY', {
            message: this.translations.incorrect_fields,
            status: 'danger',
            position: 'top-right',
            timeout: 3000,
          });
          this.isLoading = false;
        });
    },
  },
  props: {
    translations: {
      type: Object | Array,
      required: true,
    },
    routes: {
      type: Object | Array,
      required: true,
    },
    email: {
      type: String,
      required: true
    },
    resetToken: {
      type: String,
      required: true,
    }
  },
};
</script>

<style scoped></style>