<template>
  <strong>{{ count }}</strong>
</template>

<script>
  import {EventBus} from "../EventBus";

  export default {
    name: "WishlistBadgeCount",
    mounted() {
      EventBus.$on('WISHLIST_UPDATE', wishlist => {
        this.count = wishlist.productsCount;
      });
    },
    data(){
      return {
        count: this.initCount
      }
    },
    props: {
      initCount: {
        type: Number,
        required: false,
        default: 0
      }
    }
  }
</script>

<style scoped>

</style>