<template>
  <div class="uk-grid-medium" uk-grid>
      <sub-category-item
          v-for="category in categories"
          :category="category"
          :key="category.id"
      ></sub-category-item>
  </div>
</template>

<script>
    import SubCategoryItem from "./SubCategoryItem";

    export default {
        name: "SubCategories",
        components: {SubCategoryItem},
        props: {
            categories: {
                type: Object|Array,
                required: true
            }
        }
    }
</script>

<style scoped>

</style>