<template>
  <li>
    <a href="javascript:void(0)" @click.prevent="handleLogout">
      <div class="loader" v-if="isLoading"><i class="fa-solid fa-circle-notch fa-spin me-2"></i></div>
      {{ translations.logout }}
    </a>
  </li>
</template>

<script>
  import {EventBus} from "../EventBus";

  export default {
    name: "UserLogout",
    data(){
      return {
        isLoading: false,
      }
    },
    methods: {
      handleLogout(){
        this.isLoading = true;

        window.axios.post(this.routes.logout)
          .then(res => {
            let resData = res.data;
            if(resData.success) {
              window.location.href = this.routes.accountPage;
            }
            else {
              EventBus.$emit("NOTIFY", resData.message);
            }
          })
      }
    },
    props: {
      translations: {
        type: Object|Array,
        required: true,
      },
      routes: {
        type: Object|Array,
        required: true
      }
    }
  }
</script>

<style scoped>

</style>