var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-md-12 offset-md-1" }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "form",
        {
          staticClass:
            "col-md-7 col-sm-10 offset-md-0 offset-sm-1 mb-md-0 mb-5 uk-width-1-1 form-custom uk-flex uk-flex-wrap row",
          staticStyle: { "align-items": "flex-end" },
        },
        [
          _c("div", { staticClass: "uk-width-1-2 uk-width-1-3@l" }, [
            _c(
              "label",
              { staticClass: "uk-form-label", attrs: { for: "amount" } },
              [_c("strong", [_vm._v(_vm._s(_vm.translations.label))])]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "uk-form-controls" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.code,
                    expression: "code",
                  },
                ],
                staticClass: "uk-input",
                attrs: { id: "code", name: "code", type: "text" },
                domProps: { value: _vm.code },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.code = $event.target.value
                  },
                },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "ps-0" }, [
            _c(
              "button",
              {
                staticClass: "uk-button uk-button-primary uk-text-uppercase",
                attrs: { type: "submit" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.checkSaldo.apply(null, arguments)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.translations.button))]
            ),
          ]),
          _vm._v(" "),
          _vm.error
            ? _c("div", { staticClass: "uk-width-1-1" }, [
                _c("span", { staticClass: "uk-text-danger" }, [
                  _vm._v(_vm._s(_vm.error)),
                ]),
              ])
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _vm.giftcard
        ? _c(
            "table",
            {
              staticClass:
                "uk-table uk-table-small uk-table-middle uk-width-1-1 uk-width-1-2@m uk-width-1-4@xl",
            },
            [
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.translations.code))]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(_vm.giftcard.code))]),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.translations.originalPrice))]),
                _vm._v(" "),
                _c("td", [
                  _vm._v("€ " + _vm._s(_vm.giftcard.formatted.original_price)),
                ]),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.translations.saldo))]),
                _vm._v(" "),
                _c("td", [_vm._v("€ " + _vm._s(_vm.giftcard.formatted.saldo))]),
              ]),
              _vm._v(" "),
              _c("tr", { class: { "uk-text-danger": _vm.expired } }, [
                _c("th", [_vm._v(_vm._s(_vm.translations.expiryDate))]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(_vm.giftcard.expires_at))]),
              ]),
            ]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }