var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { "uk-slideshow": "ratio: 710:531; animation: fade;" } },
    [
      _c(
        "div",
        {
          staticClass: "uk-position-relative uk-visible-toggle product-gallery",
          attrs: { tabindex: "-1" },
        },
        [
          _c(
            "ul",
            { staticClass: "uk-slideshow-items" },
            _vm._l(_vm.activeProduct.images, function (image) {
              return _c("li", {
                staticClass: "product-gallery__image",
                attrs: {
                  "data-src": image.conversions.gallery
                    ? image.conversions.gallery
                    : image.original,
                  "uk-img": "target: !.uk-slideshow-items",
                },
              })
            }),
            0
          ),
          _vm._v(" "),
          _vm.activeProduct.discount_label
            ? _c("div", { staticClass: "image-banner image-banner--detail" }, [
                _vm._v(_vm._s(_vm.activeProduct.discount_label)),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("wishlist-button", {
            attrs: {
              "in-wishlist": _vm.activeProduct.in_wishlist,
              "product-id": _vm.activeProduct.id,
              routes: _vm.routes,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "product-gallery__nav" },
        _vm._l(_vm.activeProduct.images, function (image) {
          return _c(
            "button",
            {
              staticClass: "product-gallery__nav--image",
              attrs: { "uk-slideshow-item": image.id },
            },
            [
              _c("img", {
                attrs: {
                  "data-src": image.conversions.thumb_gallery
                    ? image.conversions.thumb_gallery
                    : image.original,
                  alt: image.description
                    ? image.description
                    : _vm.activeProduct.name,
                  "uk-img": "",
                },
              }),
            ]
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }