var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a", {
    class: { active: _vm.active, disabled: _vm.disabled },
    style: _vm.colorStyle,
    attrs: { href: "javascript:void(0)", "uk-tooltip": _vm.tooltip },
    on: { click: _vm.handleChange },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }