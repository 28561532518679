<template>
  <ul class="mobile-menu__content">
    <li v-for="category in previousCategories">
      <section class="mobile-category-nav-item">
        <section class="arrow arrow__previous" @click.prevent="previousCategoryStructure(category)">
          <i class="fa-solid fa-arrow-left"></i>
        </section>
        <a :href="category.slug" :title="category.name">{{ category.name }}</a>
      </section>
    </li>
    <li v-for="category in showCategories">
      <section class="mobile-category-nav-item" v-if="category.children_with_products.length > 0" >
        <a :href="category.slug" :title="category.name">{{ category.name }}</a>
        <section class="arrow arrow__next" @click.prevent="nextCategoryStructure(category)">
          <i class="fa-solid fa-arrow-right"></i>
        </section>
      </section>
      <a :href="category.slug" :title="category.name" v-else>
        <section>{{ category.name }}</section>
      </a>
    </li>

    <li v-for="page in otherPages">
      <a :href="page.url" :title="page.title">
        <section class="d-flex align-items-center">
          <img :src="page.image" class="me-3" uk-svg />
          <span v-html="page.title"></span>
        </section>
      </a>
    </li>
  </ul>
</template>

<script>
  export default {
    name: "MobileCategoryNav",
    mounted() {
    },
    data(){
      return {
        previousCategories: [],
        showCategories: this.initCategories
      }
    },
    methods: {
      nextCategoryStructure(clickedCategory){
        this.showCategories = [];
        this.showCategories = clickedCategory.children_with_products;

        this.previousCategories.push(clickedCategory);
      },
      previousCategoryStructure(clickedCategory){
        this.showCategories = [];

        if(clickedCategory.parent_category_id == null) {
          this.showCategories = this.initCategories;
          this.previousCategories = [];
        } else {
          this.previousCategories.splice(this.previousCategories.indexOf(clickedCategory), 1);
          this.showCategories = this.previousCategories[this.previousCategories.length - 1].children_with_products;
        }
      }
    },
    props: {
      initCategories: {
        type: Object|Array,
        required: true
      },
      otherPages: {
        type: Object|Array,
      }
    }
  }
</script>

<style scoped>

</style>