var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "form-custom uk-child-width-1-2@s uk-grid-small mt-4",
      attrs: { "uk-grid": "" },
    },
    [
      _c("div", [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.username,
              expression: "username",
            },
          ],
          staticClass: "uk-input",
          attrs: {
            type: "text",
            name: "username",
            placeholder: _vm.translations.username,
          },
          domProps: { value: _vm.username },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.username = $event.target.value
            },
          },
        }),
        _vm._v(" "),
        _vm.usernameErrors.length > 0
          ? _c(
              "div",
              { staticClass: "input-errors" },
              _vm._l(_vm.usernameErrors, function (error) {
                return _c("p", [_vm._v(_vm._s(error))])
              }),
              0
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.password,
              expression: "password",
            },
          ],
          staticClass: "uk-input",
          attrs: {
            type: "password",
            name: "password",
            placeholder: _vm.translations.password,
          },
          domProps: { value: _vm.password },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.password = $event.target.value
            },
          },
        }),
        _vm._v(" "),
        _vm.passwordErrors.length > 0
          ? _c(
              "div",
              { staticClass: "input-errors" },
              _vm._l(_vm.passwordErrors, function (error) {
                return _c("p", [_vm._v(_vm._s(error))])
              }),
              0
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "uk-width-1-1" }, [
        _c("label", { staticClass: "custom-checkbox" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.loginPersist,
                expression: "loginPersist",
              },
            ],
            staticClass: "uk-checkbox me-2 mt-1",
            attrs: { type: "checkbox" },
            domProps: {
              checked: Array.isArray(_vm.loginPersist)
                ? _vm._i(_vm.loginPersist, null) > -1
                : _vm.loginPersist,
            },
            on: {
              change: function ($event) {
                var $$a = _vm.loginPersist,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.loginPersist = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.loginPersist = $$a
                        .slice(0, $$i)
                        .concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.loginPersist = $$c
                }
              },
            },
          }),
          _vm._v(" "),
          _c("p", { staticClass: "my-0" }, [
            _vm._v(_vm._s(_vm.translations.login_persist)),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "uk-width-1-1 mt-4" }, [
        _c(
          "div",
          {
            staticClass: "d-flex flex-sm-row flex-column align-items-sm-center",
          },
          [
            _c(
              "button",
              {
                staticClass: "uk-button uk-button-primary me-sm-4 mb-sm-0 mb-4",
                attrs: { type: "submit" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.handleLogin.apply(null, arguments)
                  },
                },
              },
              [
                _vm.isLoading
                  ? _c("div", { staticClass: "loader" }, [
                      _c("i", {
                        staticClass: "fa-solid fa-circle-notch fa-spin me-2",
                      }),
                    ])
                  : _vm._e(),
                _vm._v(
                  "\n        " + _vm._s(_vm.translations.login) + "\n      "
                ),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "uk-width-1-1" }, [
              _c("p", { staticClass: "my-0 text--small" }, [
                _c("a", { attrs: { href: _vm.routes.password_forgotten } }, [
                  _vm._v(_vm._s(_vm.translations.forgot_password)),
                ]),
              ]),
            ]),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }