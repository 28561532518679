<template>
  <option :value="giftcard">{{ label }}</option>
</template>

<script>
    export default {
        name: "GiftcardFormAmountItem",
        computed: {
            label(){
                if(this.giftcard.giftcard_type === "fixed") {
                    return '€ ' + this.giftcard.formatted.price;
                } else {
                    return this.translations.choose_self + ' (' + this.translations.min_price + ': € ' + this.giftcard.formatted.price + ")";
                }
            }
        },
        props: {
            giftcard: {
                type: Object|Array,
                required: true
            },
            translations: {
                type: Object|Array,
                required: true
            }
        }
    }
</script>

<style scoped>

</style>