var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.checked
      ? _c(
          "button",
          {
            staticClass: "c-product__like",
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.toggleWishlist.apply(null, arguments)
              },
            },
          },
          [
            _c("img", {
              staticClass: "normal",
              attrs: {
                src: "/dist/assets/images/icons/heart-filled.svg",
                "uk-svg": "",
              },
            }),
            _vm._v(" "),
            _c("img", {
              staticClass: "active",
              attrs: {
                src: "/dist/assets/images/icons/heart-outline.svg",
                "uk-svg": "",
              },
            }),
          ]
        )
      : _c(
          "button",
          {
            staticClass: "c-product__like",
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.toggleWishlist.apply(null, arguments)
              },
            },
          },
          [
            _c("img", {
              staticClass: "normal",
              attrs: {
                src: "/dist/assets/images/icons/heart-outline.svg",
                "uk-svg": "",
              },
            }),
            _vm._v(" "),
            _c("img", {
              staticClass: "active",
              attrs: {
                src: "/dist/assets/images/icons/heart-filled.svg",
                "uk-svg": "",
              },
            }),
          ]
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }