var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "h-mt--50" }, [
    _c(
      "div",
      { staticClass: "filter-top" },
      _vm._l(_vm.renderedList, function (categoryItem) {
        return _c(
          "a",
          {
            staticClass: "filter-checkbox",
            class: { active: _vm.renderActiveState(categoryItem) },
            attrs: { href: categoryItem.slug, for: "filter-checkbox-1" },
          },
          [
            _c("input", {
              staticClass: "uk-checkbox",
              attrs: {
                id: "filter-checkbox-1",
                name: "filter-checkbox-1",
                type: "checkbox",
              },
            }),
            _vm._v(" "),
            _c("span", [_vm._v(_vm._s(categoryItem.name))]),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }