<template>
    <div>
        <div v-if="products.products.length > 0">
            <products-overview
            :translations="translations"
            :init-products="products"
            :routes="routes"
            :layout-classes="layoutClasses"
            ></products-overview>
        </div>
        <div class="uk-text-center" v-else>
            <h4 class="uk-text-center my-2 mb-4">{{ translations.empty }}</h4>
            <a :href="routes.shop_page" class="uk-button uk-button-primary d-inline uk-text-center mt-3">{{ translations.view_products }}</a>
        </div>
    </div>
</template>

<script>
    import { EventBus } from "../EventBus";
    import ProductsOverview from "./ProductsOverview";
    export default {
        name: "WishlistOverview",
        components: {ProductsOverview},
        mounted() {
            EventBus.$on('WISHLIST_UPDATE', wishlist => {
                this.products = wishlist;

                EventBus.$emit("RELOAD_PRODUCTS", wishlist);
            });
        },
        data() {
            return {
                products: this.initProducts
            }
        },
        props: {
            initProducts: {
                type: Object|Array,
                required: true
            },
            routes: {
                type: Object|Array,
                required: true
            },
            translations: {
                type: Object|Array,
                required: true
            },
            layoutClasses: {
                type: String,
                required: true,
                default() { return "uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-4@l uk-grid"; }
            }
        }
    }
</script>

<style scoped>

</style>