var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    { staticClass: "form-custom uk-grid-medium", attrs: { "uk-grid": "" } },
    [
      _c("div", { staticClass: "uk-width-1-4@m" }, [
        _c(
          "label",
          { staticClass: "uk-form-label", attrs: { for: "amount-" + _vm.uid } },
          [
            _c("strong", [_vm._v(_vm._s(_vm.translations.amount))]),
            _vm._v(" (€) *"),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "uk-form-controls" }, [
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.chosenGiftcard,
                  expression: "chosenGiftcard",
                },
              ],
              staticClass: "custom-select uk-width-1-1",
              attrs: { id: "amount-" + _vm.uid },
              on: {
                change: function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.chosenGiftcard = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                },
              },
            },
            _vm._l(_vm.giftcards, function (giftcard) {
              return _c("giftcard-form-amount-item", {
                key: giftcard.id,
                attrs: { giftcard: giftcard, translations: _vm.translations },
              })
            }),
            1
          ),
          _vm._v(" "),
          _vm.errors.product_id
            ? _c(
                "div",
                { staticClass: "input-errors" },
                _vm._l(_vm.errors.product_id, function (error) {
                  return _c("p", [_vm._v(_vm._s(error))])
                }),
                0
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.variablePriceVisible
            ? _c(
                "label",
                {
                  staticClass: "uk-form-label mt-3",
                  attrs: { for: "variable_price-" + _vm.uid },
                },
                [
                  _c("strong", [_vm._v(_vm._s(_vm.translations.enter_price))]),
                  _vm._v(" (€) *"),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.variablePriceVisible
            ? _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.variablePrice,
                    expression: "variablePrice",
                  },
                ],
                staticClass: "uk-input",
                attrs: {
                  id: "variable_price" + _vm.uid,
                  type: "number",
                  placeholder: _vm.translations.enter_price,
                },
                domProps: { value: _vm.variablePrice },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.variablePrice = $event.target.value
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.errors.price
            ? _c(
                "div",
                { staticClass: "input-errors" },
                _vm._l(_vm.errors.price, function (error) {
                  return _c("p", [_vm._v(_vm._s(error))])
                }),
                0
              )
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "uk-width-1-4@m" }, [
        _c(
          "label",
          {
            staticClass: "uk-form-label",
            attrs: { for: "receive-" + _vm.uid },
          },
          [_c("strong", [_vm._v(_vm._s(_vm.translations.receive.self) + " *")])]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "uk-form-controls" }, [
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.receiveType,
                  expression: "receiveType",
                },
              ],
              staticClass: "custom-select uk-width-1-1",
              attrs: { name: "receive", id: "receive-" + _vm.uid },
              on: {
                change: function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.receiveType = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                },
              },
            },
            [
              _c(
                "option",
                {
                  attrs: {
                    value: "physical",
                    disabled: !_vm.chosenGiftcard.extra.physical,
                  },
                },
                [_vm._v(_vm._s(_vm.translations.receive.physical))]
              ),
              _vm._v(" "),
              _c(
                "option",
                {
                  attrs: {
                    value: "digital",
                    disabled: !_vm.chosenGiftcard.extra.digital,
                  },
                },
                [_vm._v(_vm._s(_vm.translations.receive.digital))]
              ),
            ]
          ),
          _vm._v(" "),
          _vm.errors.receive_type
            ? _c(
                "div",
                { staticClass: "input-errors" },
                _vm._l(_vm.errors.receive_type, function (error) {
                  return _c("p", [_vm._v(_vm._s(error))])
                }),
                0
              )
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "uk-width-1-2@m" }, [
        _c(
          "label",
          {
            staticClass: "uk-form-label",
            attrs: { for: "delivery-" + _vm.uid },
          },
          [
            _c("strong", [
              _vm._v(_vm._s(_vm.translations.delivery.self) + " *"),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "uk-form-controls" }, [
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.deliveryType,
                  expression: "deliveryType",
                },
              ],
              staticClass: "custom-select uk-width-1-1",
              attrs: { name: "delivery", id: "delivery-" + _vm.uid },
              on: {
                change: function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.deliveryType = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                },
              },
            },
            [
              _c("option", { attrs: { value: "to_me" } }, [
                _vm._v(_vm._s(_vm.translations.delivery.to_me)),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "to_other" } }, [
                _vm._v(_vm._s(_vm.translations.delivery.to_other)),
              ]),
            ]
          ),
          _vm._v(" "),
          _vm.errors.delivery_type
            ? _c(
                "div",
                { staticClass: "input-errors" },
                _vm._l(_vm.errors.delivery_type, function (error) {
                  return _c("p", [_vm._v(_vm._s(error))])
                }),
                0
              )
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "uk-width-1-1 pt-5" }, [
        _c("h2", { staticClass: "my-0 me-4 d-inline-block" }, [
          _vm._v(_vm._s(_vm.translations.data_giver)),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "uk-width-1-1@m" }, [
        _c(
          "label",
          { staticClass: "uk-form-label", attrs: { for: "name-" + _vm.uid } },
          [_c("strong", [_vm._v(_vm._s(_vm.translations.user.name) + " *")])]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "uk-form-controls" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.senderName,
                expression: "senderName",
              },
            ],
            staticClass: "uk-input",
            attrs: {
              id: "name-" + _vm.uid,
              type: "text",
              placeholder: _vm.translations.user.name,
            },
            domProps: { value: _vm.senderName },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.senderName = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _vm.errors.sender_name
            ? _c(
                "div",
                { staticClass: "input-errors" },
                _vm._l(_vm.errors.sender_name, function (error) {
                  return _c("p", [_vm._v(_vm._s(error))])
                }),
                0
              )
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _vm.dataReceiverVisible
        ? _c(
            "div",
            { staticClass: "uk-grid-medium", attrs: { "uk-grid": "" } },
            [
              _c("div", { staticClass: "uk-width-1-1 pt-5" }, [
                _c("h2", { staticClass: "my-0 me-4 d-inline-block" }, [
                  _vm._v(_vm._s(_vm.translations.data_receiver)),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "uk-width-1-2@m" }, [
                _c(
                  "label",
                  {
                    staticClass: "uk-form-label",
                    attrs: { for: "receiver_name-" + _vm.uid },
                  },
                  [
                    _c("strong", [
                      _vm._v(_vm._s(_vm.translations.user.name) + " *"),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "uk-form-controls" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.receiverName,
                        expression: "receiverName",
                      },
                    ],
                    staticClass: "uk-input",
                    attrs: {
                      id: "receiver_name-" + _vm.uid,
                      type: "text",
                      placeholder: _vm.translations.user.name,
                    },
                    domProps: { value: _vm.receiverName },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.receiverName = $event.target.value
                      },
                    },
                  }),
                  _vm._v(" "),
                  _vm.errors.receiver_name
                    ? _c(
                        "div",
                        { staticClass: "input-errors" },
                        _vm._l(_vm.errors.receiver_name, function (error) {
                          return _c("p", [_vm._v(_vm._s(error))])
                        }),
                        0
                      )
                    : _vm._e(),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "uk-width-1-2@m" }, [
                _c(
                  "label",
                  {
                    staticClass: "uk-form-label",
                    attrs: { for: "email-" + _vm.uid },
                  },
                  [
                    _c("strong", [
                      _vm._v(_vm._s(_vm.translations.user.email) + " *"),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "uk-form-controls" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.receiverEmail,
                        expression: "receiverEmail",
                      },
                    ],
                    staticClass: "uk-input",
                    attrs: {
                      id: "email-" + _vm.uid,
                      type: "email",
                      placeholder: _vm.translations.user.email,
                    },
                    domProps: { value: _vm.receiverEmail },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.receiverEmail = $event.target.value
                      },
                    },
                  }),
                  _vm._v(" "),
                  _vm.errors.receiver_email
                    ? _c(
                        "div",
                        { staticClass: "input-errors" },
                        _vm._l(_vm.errors.receiver_email, function (error) {
                          return _c("p", [_vm._v(_vm._s(error))])
                        }),
                        0
                      )
                    : _vm._e(),
                ]),
              ]),
              _vm._v(" "),
              _vm.addressReceiverVisible
                ? _c(
                    "div",
                    { staticClass: "uk-grid-medium", attrs: { "uk-grid": "" } },
                    [
                      _c("div", { staticClass: "uk-width-1-2@m" }, [
                        _c(
                          "label",
                          {
                            staticClass: "uk-form-label",
                            attrs: { for: "street-" + _vm.uid },
                          },
                          [
                            _c("strong", [
                              _vm._v(
                                _vm._s(_vm.translations.address.street) + " *"
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "uk-form-controls" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.address.street,
                                expression: "address.street",
                              },
                            ],
                            staticClass: "uk-input",
                            attrs: {
                              id: "street-" + _vm.uid,
                              type: "text",
                              placeholder: _vm.translations.address.street,
                            },
                            domProps: { value: _vm.address.street },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.address,
                                  "street",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _vm.errors["address.street"]
                            ? _c(
                                "div",
                                { staticClass: "input-errors" },
                                _vm._l(
                                  _vm.errors["address.street"],
                                  function (error) {
                                    return _c("p", [_vm._v(_vm._s(error))])
                                  }
                                ),
                                0
                              )
                            : _vm._e(),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "uk-width-1-6@m" }, [
                        _c(
                          "label",
                          {
                            staticClass: "uk-form-label",
                            attrs: { for: "number-" + _vm.uid },
                          },
                          [
                            _c("strong", [
                              _vm._v(
                                _vm._s(_vm.translations.address.housenr) + " *"
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "uk-form-controls" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.address.number,
                                expression: "address.number",
                              },
                            ],
                            staticClass: "uk-input",
                            attrs: {
                              id: "number-" + _vm.uid,
                              type: "text",
                              placeholder: _vm.translations.address.housenr,
                            },
                            domProps: { value: _vm.address.number },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.address,
                                  "number",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _vm.errors["address.number"]
                            ? _c(
                                "div",
                                { staticClass: "input-errors" },
                                _vm._l(
                                  _vm.errors["address.number"],
                                  function (error) {
                                    return _c("p", [_vm._v(_vm._s(error))])
                                  }
                                ),
                                0
                              )
                            : _vm._e(),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "uk-width-1-6@m" }, [
                        _c(
                          "label",
                          {
                            staticClass: "uk-form-label uk-invisible",
                            attrs: { for: "addition-" + _vm.uid },
                          },
                          [
                            _c("strong", [
                              _vm._v(
                                _vm._s(_vm.translations.address.number_addon) +
                                  " *"
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "uk-form-controls" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.address.number_addon,
                                expression: "address.number_addon",
                              },
                            ],
                            staticClass: "uk-input",
                            attrs: {
                              id: "addition-" + _vm.uid,
                              type: "text",
                              placeholder:
                                _vm.translations.address.number_addon,
                            },
                            domProps: { value: _vm.address.number_addon },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.address,
                                  "number_addon",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _vm.errors["address.number_addon"]
                            ? _c(
                                "div",
                                { staticClass: "input-errors" },
                                _vm._l(
                                  _vm.errors["address.number_addon"],
                                  function (error) {
                                    return _c("p", [_vm._v(_vm._s(error))])
                                  }
                                ),
                                0
                              )
                            : _vm._e(),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "uk-width-1-6@m" }, [
                        _c(
                          "label",
                          {
                            staticClass: "uk-form-label",
                            attrs: { for: "bus-" + _vm.uid },
                          },
                          [
                            _c("strong", [
                              _vm._v(_vm._s(_vm.translations.address.busnr)),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "uk-form-controls" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.address.busnumber,
                                expression: "address.busnumber",
                              },
                            ],
                            staticClass: "uk-input",
                            attrs: {
                              id: "bus-" + _vm.uid,
                              type: "text",
                              placeholder: _vm.translations.address.busnr,
                            },
                            domProps: { value: _vm.address.busnumber },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.address,
                                  "busnumber",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _vm.errors["address.busnumber"]
                            ? _c(
                                "div",
                                { staticClass: "input-errors" },
                                _vm._l(
                                  _vm.errors["address.busnumber"],
                                  function (error) {
                                    return _c("p", [_vm._v(_vm._s(error))])
                                  }
                                ),
                                0
                              )
                            : _vm._e(),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "uk-width-1-4@m" }, [
                        _c(
                          "label",
                          {
                            staticClass: "uk-form-label",
                            attrs: { for: "postalcode-" + _vm.uid },
                          },
                          [
                            _c("strong", [
                              _vm._v(
                                _vm._s(_vm.translations.address.postalcode) +
                                  " *"
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "uk-form-controls" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.address.postalcode,
                                expression: "address.postalcode",
                              },
                            ],
                            staticClass: "uk-input",
                            attrs: {
                              id: "postalcode-" + _vm.uid,
                              type: "text",
                              placeholder: _vm.translations.address.postalcode,
                            },
                            domProps: { value: _vm.address.postalcode },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.address,
                                  "postalcode",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _vm.errors["address.postalcode"]
                            ? _c(
                                "div",
                                { staticClass: "input-errors" },
                                _vm._l(
                                  _vm.errors["address.postalcode"],
                                  function (error) {
                                    return _c("p", [_vm._v(_vm._s(error))])
                                  }
                                ),
                                0
                              )
                            : _vm._e(),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "uk-width-1-2@m" }, [
                        _c(
                          "label",
                          {
                            staticClass: "uk-form-label",
                            attrs: { for: "city-" + _vm.uid },
                          },
                          [
                            _c("strong", [
                              _vm._v(
                                _vm._s(_vm.translations.address.commune) + " *"
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "uk-form-controls" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.address.commune,
                                expression: "address.commune",
                              },
                            ],
                            staticClass: "uk-input",
                            attrs: {
                              id: "city-" + _vm.uid,
                              type: "text",
                              placeholder: _vm.translations.address.commune,
                            },
                            domProps: { value: _vm.address.commune },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.address,
                                  "commune",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _vm.errors["address.commune"]
                            ? _c(
                                "div",
                                { staticClass: "input-errors" },
                                _vm._l(
                                  _vm.errors["address.commune"],
                                  function (error) {
                                    return _c("p", [_vm._v(_vm._s(error))])
                                  }
                                ),
                                0
                              )
                            : _vm._e(),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "uk-width-1-4@m" }, [
                        _c(
                          "label",
                          {
                            staticClass: "uk-form-label",
                            attrs: { for: "country-" + _vm.uid },
                          },
                          [
                            _c("strong", [
                              _vm._v(
                                _vm._s(_vm.translations.address.country) + " *"
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "uk-form-controls" }, [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.address.country,
                                  expression: "address.country",
                                },
                              ],
                              staticClass: "custom-select uk-width-1-1",
                              attrs: { id: "country-" + _vm.uid },
                              on: {
                                change: function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.address,
                                    "country",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                              },
                            },
                            _vm._l(_vm.countries, function (country) {
                              return _c(
                                "option",
                                { domProps: { value: country.id } },
                                [_vm._v(_vm._s(country.name))]
                              )
                            }),
                            0
                          ),
                          _vm._v(" "),
                          _vm.errors["address.country"]
                            ? _c(
                                "div",
                                { staticClass: "input-errors" },
                                _vm._l(
                                  _vm.errors["address.country"],
                                  function (error) {
                                    return _c("p", [_vm._v(_vm._s(error))])
                                  }
                                ),
                                0
                              )
                            : _vm._e(),
                        ]),
                      ]),
                    ]
                  )
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "uk-width-1-1 pt-5" }, [
        _c("h2", { staticClass: "my-0 me-4 d-inline-block" }, [
          _vm._v(_vm._s(_vm.translations.data_cart)),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "uk-width-1-1@m" }, [
        _c(
          "label",
          {
            staticClass: "uk-form-label",
            attrs: { for: "headline-" + _vm.uid },
          },
          [_c("strong", [_vm._v(_vm._s(_vm.translations.cart.title))])]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "uk-form-controls" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.giftCardTitle,
                expression: "giftCardTitle",
              },
            ],
            staticClass: "uk-input",
            attrs: {
              id: "headline-" + _vm.uid,
              type: "text",
              placeholder: _vm.translations.cart.title,
            },
            domProps: { value: _vm.giftCardTitle },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.giftCardTitle = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _vm.errors.giftcard_title
            ? _c(
                "div",
                { staticClass: "input-errors" },
                _vm._l(_vm.errors.giftcard_title, function (error) {
                  return _c("p", [_vm._v(_vm._s(error))])
                }),
                0
              )
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "uk-width-1-1@m" }, [
        _c(
          "label",
          { staticClass: "uk-form-label", attrs: { for: "body-" + _vm.uid } },
          [_c("strong", [_vm._v(_vm._s(_vm.translations.cart.text))])]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "uk-form-controls" }, [
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.giftCardBody,
                expression: "giftCardBody",
              },
            ],
            staticClass: "uk-textarea",
            attrs: {
              id: "body-" + _vm.uid,
              placeholder: _vm.translations.cart.text,
            },
            domProps: { value: _vm.giftCardBody },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.giftCardBody = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _vm.errors.giftcard_body
            ? _c(
                "div",
                { staticClass: "input-errors" },
                _vm._l(_vm.errors.giftcard_body, function (error) {
                  return _c("p", [_vm._v(_vm._s(error))])
                }),
                0
              )
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "uk-width-1-1 pt-sm-5" }, [
        _c(
          "button",
          {
            staticClass: "uk-button uk-button-primary",
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.handleOrder.apply(null, arguments)
              },
            },
          },
          [
            _vm.handleLoading
              ? _c("div", { staticClass: "loader" }, [
                  _c("i", {
                    staticClass: "fa-solid fa-circle-notch fa-spin me-2",
                  }),
                ])
              : _vm._e(),
            _vm._v(
              "\n        " + _vm._s(_vm.translations.add_to_cart) + "\n    "
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }