var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      staticClass: "uk-width-1-4@m uk-width-1-3@s block-link-category-wrapper",
      attrs: { href: _vm.category.slug, title: _vm.category.name },
    },
    [
      _c(
        "div",
        {
          class: _vm.className,
          attrs: { "data-src": _vm.category.image, "uk-img": "" },
        },
        [_vm.category.image ? _c("div", { staticClass: "overlay" }) : _vm._e()]
      ),
      _vm._v(" "),
      _c("span", [_vm._v(_vm._s(_vm.category.name))]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }