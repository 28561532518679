var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex", class: { "justify-content-center": _vm.large } },
    _vm._l(_vm.methods, function (method) {
      return _c("img", {
        staticClass: "mr-2 mb-0",
        attrs: {
          src: method.img,
          alt: method.name,
          width: _vm.imgWidth,
          height: _vm.imgHeight,
          "uk-tooltip": method.name,
          draggable: "false",
        },
      })
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }