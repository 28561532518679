var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass:
        "form--password-reset uk-child-width-1-1 uk-grid-medium uk-width-1-1 mt-5",
      attrs: { "uk-grid": "" },
    },
    [
      _c("div", [
        _c("label", { staticClass: "uk-form-label", attrs: { for: "email" } }, [
          _vm._v(_vm._s(_vm.translations.password) + " *"),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "uk-form-controls" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.password,
                expression: "password",
              },
            ],
            staticClass: "uk-input",
            attrs: { type: "password", name: "password" },
            domProps: { value: _vm.password },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.password = $event.target.value
              },
            },
          }),
        ]),
        _vm._v(" "),
        _vm.passwordErrors.length > 0
          ? _c(
              "div",
              { staticClass: "input-errors" },
              _vm._l(_vm.passwordErrors, function (error) {
                return _c("p", [_vm._v(_vm._s(error))])
              }),
              0
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", [
        _c(
          "label",
          {
            staticClass: "uk-form-label",
            attrs: { for: "password_confirmation" },
          },
          [_vm._v(_vm._s(_vm.translations.password_confirmation) + " *")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "uk-form-controls" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.password_confirmation,
                expression: "password_confirmation",
              },
            ],
            staticClass: "uk-input",
            attrs: { type: "password", name: "password_confirmation" },
            domProps: { value: _vm.password_confirmation },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.password_confirmation = $event.target.value
              },
            },
          }),
        ]),
        _vm._v(" "),
        _vm.passwordErrors.length > 0
          ? _c(
              "div",
              { staticClass: "input-errors" },
              _vm._l(_vm.passwordErrors, function (error) {
                return _c("p", [_vm._v(_vm._s(error))])
              }),
              0
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "uk-width-1-1 mt-5" }, [
        _c(
          "div",
          {
            staticClass: "d-flex flex-sm-row flex-column align-items-sm-center",
          },
          [
            _c(
              "button",
              {
                staticClass:
                  "uk-button uk-button-secondary uk-width-1-1 mb-sm-0 mb-4",
                attrs: { type: "submit" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.handleReset.apply(null, arguments)
                  },
                },
              },
              [
                _vm.isLoading
                  ? _c("div", { staticClass: "loader" }, [
                      _c("i", {
                        staticClass: "fa-solid fa-circle-notch fa-spin me-2",
                      }),
                    ])
                  : _vm._e(),
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.translations.reset_password) +
                    "\n      "
                ),
              ]
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }