var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("li", [
    _c("a", { attrs: { href: _vm.category.slug } }, [
      _c("span", { staticClass: "text" }, [_vm._v(_vm._s(_vm.category.name))]),
      _vm._v(" "),
      _c("span", { staticClass: "amount" }, [
        _vm._v("(" + _vm._s(_vm.category.products_count) + ")"),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }