<template>
    <div class="uk-text-center mt-sm-5 mt-4">
    <div class="loader" :class="!isLoading ? 'uk-invisible':''"><i class="fa-solid fa-circle-notch fa-spin"></i></div>
        <ul class="uk-pagination uk-flex-center" uk-margin>
            <li v-if="currentPage != 1" @click="handleClick(currentPage - 1)"><a href="javascript:void(0)" class="uk-pagination--prev"><i class="fa-solid fa-chevron-left"></i></a></li>

            <li v-for="page in pagesArray" :class="page.class" @click="handleClick(page.page)">
                <span v-if="page.class">{{ page.page }}</span>
                <a v-else href="javascript:void(0)">{{ page.page }}</a>
            </li>

            <li v-if="currentPage != lastPage" @click="handleClick(currentPage + 1)"><a href="javascript:void(0)" class="uk-pagination--next"><i class="fa-solid fa-chevron-right"></i></a></li>
        </ul>
    </div>
</template>

<script>
  import { EventBus } from "../EventBus";
  import * as functions from "../functions.js";

  export default {
    name: "ProductOverviewPagination",
    mounted() {
    },
    data(){
      return{
        pagesBetween: 3,
        isLoading: false
      }
    },
    computed: {
      currentPage: function(){
        return this.pagination.currentPage;
      },
      lastPage: function(){
        return this.pagination.lastPage;
      },
      pagesArray: function(){
        let pagesBefore = this.currentPage - this.pagesBetween + 1;

        if(pagesBefore <= 1){
          pagesBefore = 2;
        }else{
          pagesBefore -= 1;
        }

        if(pagesBefore == 1){
          pagesBefore = 2;
        }

        let pagesAfter = this.currentPage + this.pagesBetween + 1;

        if(pagesAfter >= this.lastPage){
          pagesAfter = this.lastPage;
        }

        let pagesArray = [];

        if(this.currentPage != 1)
        {
          pagesArray.push({page: 1});
        }

        if(pagesBefore != 2)
        {
          pagesArray.push({page: "...", class: "uk-disabled"});
        }

        for (let i = pagesBefore; i < this.currentPage; i++){
          pagesArray.push({page: i});
        }

        pagesArray.push({page: this.currentPage, class: 'uk-active'});

        for (let i = this.currentPage + 1; i < pagesAfter; i++){
          pagesArray.push({page: i});
        }

        if(pagesAfter < this.lastPage){
          pagesArray.push({page: '...', class: "uk-disable"});
        }

        if(this.currentPage != this.lastPage){
          pagesArray.push({page: this.lastPage});
        }

        return pagesArray;
      },

    },
    methods: {
      handleClick(page){
        if(page != "..." && page != this.currentPage)
        {
          this.isLoading = true;
          functions.pushUrlParameter("page", page);

          let route = this.routes.page + functions.buildQueryString(functions.getUrlParameters());

          window.axios.get(route)
              .then(res => {
                EventBus.$emit('RELOAD_PRODUCTS', res.data.products);
                this.isLoading = false;
              });
        }
      },
    },
    props: {
        pagination: {
          type: Object|Array,
          required: true
        },
        translations: {
          type: Object|Array,
          required: true
        },
        routes: {
          type: Object|Array,
          required: true
        }
    }
  }
</script>

<style scoped>

</style>