<template>
    <li v-bind:class="{'uk-open': ukOpen}">
        <a class="uk-accordion-title" href="javascript:void(0)">
            <div class="d-flex">
              <info-circle
                v-if="sizegroup.description !== null && sizegroup.description !== ''"
                :headline="sizegroup.name"
                :body="sizegroup.description"
              ></info-circle>
              <span>{{ sizegroup.name }}</span>
            </div>
            <i class="fa-solid fa-chevron-down"></i>
        </a>

        <div class="uk-accordion-content">
            <ul class="sidebar__nav">
                <filter-sidebar-sizes-group-item
                    v-for="size in sizegroup.values"
                    v-model="activeSizes[size.id]"
                    :size="size"
                    :key="size.id"
                    :group="sizegroup"
                    :default-checked="activeSizes[size.id] || false"
                    :active-sizes="activeSizes"
                    @input="handleChange"
                >
                </filter-sidebar-sizes-group-item>
            </ul>
        </div>
    </li>
</template>

<script>
    import InfoCircle from "./InfoCircle";
    export default {
        name: "FilterSidebarSizes",
      components: {InfoCircle},
      mounted() {
            
        },
        data() {
            return {
                localActiveSizes: this.activeSizes
            }
        },
        methods: {
            handleChange(){
                this.$emit('input', this.activeSizes);
            }
        },
        props: {
            sizegroup: {
                type: Object|Array,
                required: true
            },
            activeSizes: {
                type: Object|Array,
                required: true
            },
            ukOpen: {
              type: Boolean,
              required: false,
              default: true
            }
        }
    }
</script>

<style scoped>
</style>