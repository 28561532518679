var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("li", { class: { "uk-open": _vm.ukOpen } }, [
    _c("a", { staticClass: "uk-accordion-title", attrs: { href: "#" } }, [
      _c("span", [_vm._v(_vm._s(_vm.categoryName))]),
      _vm._v(" "),
      _c("i", { staticClass: "fa-solid fa-chevron-down" }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-accordion-content" }, [
      _c(
        "ul",
        { staticClass: "sidebar__nav" },
        _vm._l(_vm.categories, function (category) {
          return _c("filter-sidebar-categories-item", {
            key: category.id,
            attrs: { category: category },
          })
        }),
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }