import UIkit from 'uikit';
const axios = require('axios').default;

// ==========[ JS HELPERS ]==========
/**
 * listens when document is loaded
 * @param {function} fn 
 */
export let docReady = (fn) => {
    if (document.readyState === "complete" || document.readyState === "interactive") {
        setTimeout(fn, 1);
    } else {
        document.addEventListener("DOMContentLoaded", fn);
    }
}

/**
 * 
 * @param {element} element 
 */
export let getTarget = (element) => {
    if (element.classList.contains("fa-solid") || element.classList.contains("fa") || element.classList.contains('fas')) {
        element = element.parentNode;
    }

    if (element.tagName == "SPAN") {
        element = element.parentNode;
    }

    return element;
}

/**
 * apply's eventlistener event on elements with querySelector
 * @param {string} querySelector 
 * @param {string} event 
 * @param {function} fn 
 */
export let createEvent = (querySelector, event, fn) => {
    let firstChar = querySelector.charAt(0);
    let selector = querySelector.substring(1);

    let nodes = document.querySelectorAll(querySelector);
    nodes = Array.from(nodes);
    nodes = nodes.map(el => el);

    document.addEventListener(event, function (e) {
        if (nodes.indexOf(e.target) != -1 || e.target.closest(querySelector) != null) {
            fn();
        }
    });

    // if (firstChar == ".") {
    //     document.addEventListener(event, function (e) {
    //         if (e.target.classList.contains(selector) || e.target.closest(querySelector) != null) {
    //             fn();
    //         }
    //     });
    // } else if (firstChar == "#") {
    //     document.addEventListener(event, function (e) {
    //         if (e.target.id == selector || e.target.closest(querySelector) != null) {
    //             fn();
    //         }
    //     })
    // } else if (firstChar == "[") {
    //     let dataAttr = selector.substring(0, selector.length - 1);
    //     if (dataAttr.includes("=")) {
    //         dataAttr = dataAttr.substring(0, dataAttr.indexOf("="));
    //     }

    //     document.addEventListener(event, function (e) {
    //         if (e.target.hasAttribute(dataAttr) || e.target.closest(querySelector) != null) {
    //             fn();
    //         }
    //     });
    // } else {
    //     document.addEventListener(event, function (e) {
    //         if (e.target.tagName == querySelector.toUpperCase()) {
    //             fn();
    //         }
    //     });
    // }
}

/**
 * check's if elements exists with querySelector
 * @param {string} querySelector 
 * @returns {boolean}
 */
export let domElementExists = (querySelector) => {
    if (document.querySelectorAll(querySelector).length > 0) {
        return true;
    }

    return false;
}

// ==========[ HELPERS ]==========
/**
 * Scroll's to an element
 * @param {string} to (default:body)
 * @param {string} behavior (default:smooth)
 * @param {string} block (default:start)
 */
export let scrollToElement = (to = "body", behavior = "smooth", block = "start") => {
    document.querySelector(to).scrollIntoView({ behavior: behavior, block: block });
}

/**
 * 
 * @param {string} message 
 * @param {string} position 
 * @param {string} status 
 * @param {int} timeout
 */
export let uiKitNotification = (message = "", position = "top-right", status = "primary", timeout = 5000) => {
    UIkit.notification({
        message: message,
        pos: position,
        status: status,
        timeout: timeout
    });
}

/**
 * Display multiple uikit notifications
 * @param {Array} notifications 
 */
export let multipleUiKitNotifications = (notifications) => {
    for (let i = 0, len = notifications.length; i < len; i++) {
        let notification = notifications[i];
        if ('message' in notification && notification["message"] != "") {
            let message = notification["message"];
            let position = "top-right";
            let status = "primary";
            let timeout = 5000;

            if ('position' in notification) {
                position = notification["position"];
            }

            if ('status' in notification) {
                status = notification["status"];
            }

            if ('timeout' in notification) {
                timeout = notification["timout"];
            }

            uiKitNotification(message, position, status, timeout);
        }
    }
}

// ==========[ FORM HANDLER ]==========
/**
 * Default form handler
 */
export let formHandler = () => {
    event.preventDefault();

    var eventTarget = getTarget(event.target);

    let formName = eventTarget.dataset.form;
    let formId = "#" + formName + "-form";
    let loaderId = "." + formName + "-loader";
    let feedbackId = "#" + formName + "-feedback";
    let formBlockId = "#" + formName + "-form-block";

    let requestUrl = ajaxUrl;
    if (eventTarget.hasAttribute("data-url")) {
        requestUrl = eventTarget.dataset.url;
    }

    if (domElementExists(formId)) {
        let data = new FormData(document.querySelector(formId));
        data.append("js_url", "js_set");

        let remove_loader = true;
        if (domElementExists(loaderId)) {
            togglePageLoader(loaderId);
        } else {
            togglePageLoader();
        }

        axios
            .post(
                requestUrl,
                data,
                {
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Content-type': 'application/x-www-form-urlencoded'
                    }
                }
            ).then(function (response) {
                let responseData = response.data;

                // Add url parameters
                if (responseData.urlParameters) {
                    pushUrlParameters(responseData.urlParameters);
                }

                // Redirect to page
                if (responseData.redirect) {
                    window.location.href = responseData.redirect;
                    remove_loader = false;
                }

                // Reload page
                if (responseData.reload) {
                    location.reload();
                    remove_loader = false;
                }

                // Hide loader
                if (remove_loader) {
                    if (domElementExists(loaderId)) {
                        togglePageLoader(loaderId);
                    } else {
                        togglePageLoader();
                    }
                }

                // Render HTML
                if (responseData.renderHtml) {
                    renderMultipleHtml(responseData.renderHtml);
                }

                // Hide form
                if (responseData.hideForm) {
                    document.querySelector(formId).classList.add("d-none");
                }

                //Remove previous messages
                if (domElementExists(feedbackId)) {
                    document.querySelector(feedbackId).innerHTML = "";

                    // Add messages to feedbackId
                    if (responseData.messages && responseData.messages.length > 0) {
                        for (let i = 0; i < responseData.messages.length; i++) {
                            let message = document.createElement('div');
                            message.classList.add('uk-alert-' + responseData.messages[i].type);
                            message.setAttribute("uk-alert", "");

                            let link = document.createElement('a');
                            link.classList.add('uk-alert-close');
                            link.setAttribute("uk-close", "");

                            let messageContent = document.createElement('span');
                            messageContent.innerHTML = responseData.messages[i].message;

                            message.appendChild(link);
                            message.appendChild(messageContent);

                            document.querySelector(feedbackId).appendChild(message);
                        }
                        document.querySelector(feedbackId).classList.remove("d-none");
                    }
                }

                // Scroll to formBlockId
                if (responseData.toTop && domElementExists(formBlockId)) {
                    scrollToElement(formBlockId);
                }

                // Remove old error fields
                let errorFields = document.querySelector(formId).querySelectorAll(".uk-form-danger");
                if (errorFields.length > 0) {
                    for (var i = 0, length = errorFields.length; i < length; i++) {
                        errorFields[i].classList.remove("uk-form-danger");
                    }
                }


                // Add danger class to error fields
                if (responseData.errorFields && responseData.errorFields.length > 0) {
                    let form = document.querySelector(formId);
                    for (let i = 0; i < responseData.errorFields.length; i++) {
                        let fields = form.querySelectorAll(responseData.errorFields[i]);

                        if (fields.length > 0) {
                            for (let i = 0, len = fields.length; i < len; i++) {
                                fields[i].classList.add("uk-form-danger");
                            }
                        }
                    }
                }

                //show uikit notifications
                if (responseData.uikitNotification) {
                    multipleUiKitNotifications(responseData.uikitNotification);
                }

                //hide modal if set
                if ("modal" in eventTarget.dataset && responseData.hideModal) {
                    let modalId = eventTarget.dataset.modal;
                    if (domElementExists(modalId)) {
                        UIkit.modal(document.querySelector(modalId)).hide();
                    }
                }

                if (responseData.laravel) {
                    // Add danger class to error fields
                    if (responseData.laravel_errorFields && responseData.laravel_errorFields.length > 0) {
                        // let form = document.querySelector(formId);
                        // for (let i = 0; i < responseData.errorFields.length; i++) {
                        //     let fields = form.querySelectorAll(responseData.errorFields[i]);

                        //     if (fields.length > 0) {
                        //         for (let i = 0, len = fields.length; i < len; i++) {
                        //             fields[i].classList.add("uk-form-danger");
                        //         }
                        //     }
                        // }
                    }
                }
            });
    }

}

// ==========[ COOKIE HANDLER ]==========
export let cookieHandler = () => {
    document.querySelector("#cookie-modal").style.display = 'none';

    let cookie_form = document.querySelector("#cookie_form");
    let cookie_data = new FormData(cookie_form);
        cookie_data.append("js_url", "js_set");

    axios
        .post(
            ajaxUrl,
                cookie_data,
                {
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Content-type': 'application/x-www-form-urlencoded'
                    }
                }
            ).then(function (response) {
                let responseData = response.data;

                // Render HTML
                if (responseData.addHtml) {
                    addHtml('head',responseData.addHtml);
                }
            });
}

// ==========[ DOM MANIPULATORS ]==========
/**
 * Render's html from array
 * the array has to contain objects with following key=>values:
 *      parent => queryselector for the parent html element; 
 *      method => what has to happen? 'replace' html inside parent or 'add' to bottom of parent; 
 *      html => the html that has to be replaced/added
 * @param {Array} html 
 */
export let renderMultipleHtml = (html) => {
    if (html.length > 0) {
        for (let i = 0; i < html.length; i++) {
            if (domElementExists(html[i].parent)) {
                if (html[i].method == "replace") { // will replace all the content inside parent
                    replaceHtml(html[i].parent, html[i].html);
                } else if (html[i].method == "add") {
                    let where = "bottom";
                    if (html[i].where) {
                        where = html[i].where;
                    }
                    addHtml(html[i].parent, html[i].html, where);
                } else if (html[i].method == "replaceElement") { // will replace an other element
                    replaceDomElement(html[i].oldChild, html[i].html, html[i].parent);
                } else {
                    console.warn("HTML render method not found: " + html[i].method + "; Source: functions.js => renderMultipleHtml");
                }
            } else {
                console.warn("Dom element not found: " + html[i].parent + "; Source: functions.js => renderMultipleHtml");
            }
        }
    }
}

/**
 * replaces the html in a parent element
 * @param {string} parent 
 * @param {string} html 
 */
export let replaceHtml = (parent, html) => {
    if (domElementExists(parent)) {
        let parentElement = document.querySelector(parent);
        while (parentElement.firstChild) {
            parentElement.removeChild(parentElement.firstChild);
        }
        parentElement.insertAdjacentHTML("afterbegin", html);
    } else {
        console.warn("Dom element not found: " + html[i].parent + "; Source: functions.js => renderHtml");
    }
}

/**
 * Add's the html to the bottom of the parent ellement
 * @param {string} parent 
 * @param {string} html 
 */
export let addHtml = (parent, html, where = "bottom") => {
    if (where == "bottom") {
        where = "beforeend";
    }
    else if (where == "top") {
        where = "afterbegin";
    }
    if (domElementExists(parent)) {
        document.querySelector(parent).insertAdjacentHTML(where, html);
    } else {
        console.warn("Dom element not found: " + html[i].parent + "; Source: functions.js => renderHtml");
    }
}

export let replaceDomElement = (oldChild, newElement, parentElement) => {
    if (domElementExists(oldChild)) {
        let oldElement = document.querySelector(oldChild);
        oldElement.insertAdjacentHTML("afterend", newElement);
        removeElement(oldChild, true);
    } else {
        console.warn("Dom element not found: " + html[i].parent + "; Source: functions.js => replaceDomElement");
    }
}

/**
 * Toggles d-none class on a element (mostly used for page loaders)
 * @param {string} loader 
 */
export let togglePageLoader = (loader = ".page-loader") => {
    if (domElementExists(loader)) {
        let loaders = document.querySelectorAll(loader);
        for (let i = 0, len = loaders.length; i < len; i++) {
            loaders[i].classList.toggle("d-none");
        }
    }
}


/**
 * Removes found elements with querySelector from DOM
 * If first is set to true it only removes the first found element
 * @param {string} querySelector 
 * @param {bool} first 
 */
export let removeElement = (querySelector, first = false) => {
    if (domElementExists(querySelector)) {
        if (first) {
            let element = document.querySelector(querySelector);
            element.parentNode.removeChild(element);
        }
        else {
            let elements = document.querySelectorAll(querySelector);
            elements.forEach(element => {
                element.parentNode.removeChild(element);
            });
        }
    }
}


// ==========[ URL FUNCTIONS ]==========
export let getUrlQueryString = (url = "") => {
    if (url == "") {
        url = location.href;
    }

    if (url.indexOf('?') > -1) {
        return url.substring(url.indexOf('?'));
    }

    return "";
}
/**
 * returns an object of all the variables in a url; the url parameter is optional if you want to get them from another url than the current
 * @param {string} url 
 */

export let getUrlParameters = (url = "") => {
    if (url == "") {
        url = location.href;
    }

    let queryString = url.substring(url.indexOf('?') + 1);
    let paramsArr = queryString.split('&');
    let params = {};

    for (let i = 0, len = paramsArr.length; i < len; i++) {
        let keyValuePair = paramsArr[i].split('=');
        if (keyValuePair[1] != undefined) {
            params[keyValuePair[0]] = keyValuePair[1];
        }
    }

    return params;
}

/**
 * returns the querystring to be used in a url of params
 * @param {object} params 
 */
export let buildQueryString = (params = {}) => {
    let queryString = "";
    let first = true;

    if (params.lenth == 0) {
        params = getUrlParameters();
    }

    Object.keys(params).forEach((key) => {
        let val = params[key];
        if (val != "") {
            if (first) {
                first = false;
                queryString += "?";
            } else {
                queryString += "&";
            }

            queryString += key + "=" + val;
        }
    });

    return queryString;
}

/**
 * Add's current url parameters or given parameters to a formdata object
 * @param {FormData} data 
 * @param {object} params
 * @returns {FormData}
 */
export let addParamsData = (data, params = {}) => {
    if (Object(params).length == 0) {
        params = getUrlParameters();
    }

    Object.keys(params).forEach((key) => {
        data.append(key, params[key]);
    });

    return data;
}

/**
 * Pushes key => value parameter to the url
 * @param {string} key 
 * @param {string} value 
 */
export let pushUrlParameter = (key, value) => {
    let params = getUrlParameters();

    params[key] = value;

    pushQueryString(buildQueryString(params));
}

/**
 * Pushes multiple key => values to the url
 * @param {Object} params 
 */
export let pushUrlParameters = (params) => {
    let existingParams = getUrlParameters();

    Object.keys(params).forEach((key) => {
        existingParams[key] = params[key];
    });

    pushQueryString(buildQueryString(existingParams));
}

/**
 * Pushes queryString to the url
 * @param {string} queryString 
 */
export let pushQueryString = (queryString = "") => {
    if (queryString == "") {
        window.history.pushState({}, "", location.pathname);
    } else {
        window.history.pushState({}, "", queryString);
    }
};

/**
 * Converts key: value,... string to object
 * @param {string} string 
 */
export let stringToArray = (string = "") => {
    if (string != "") {
        if (string.slice(-1) == ",") {
            string = string.slice(0, -1);
        }

        let strings = string.split(/, (?=[^,]+:)/);
        let payload = {};
        let keyVal = "";
        let key = "";
        let val = "";

        Object.values(strings).forEach((strVal) => {
            keyVal = strVal.split(': ');

            if (keyVal.length == 2) {
                key = keyVal[0].trim();
                val = keyVal[1].trim();

                if (key != "" && val != "") {
                    payload[key] = val;
                }
            } else {
                keyVal = strVal.split(':');

                if (keyVal.length == 2) {
                    key = keyVal[0].trim();
                    val = keyVal[1].trim();

                    if (key != "" && val != "") {
                        payload[key] = val;
                    }
                }
            }
        });

        strings = string.split(/,(?=[^,]+:)/);

        Object.values(strings).forEach((strVal) => {
            keyVal = strVal.split(': ');

            if (keyVal.length == 2) {
                key = keyVal[0].trim();
                val = keyVal[1].trim();

                if (key != "" && val != "") {
                    payload[key] = val;
                }
            } else {
                keyVal = strVal.split(':');

                if (keyVal.length == 2) {
                    key = keyVal[0].trim();
                    val = keyVal[1].trim();


                    if (key != "" && val != "") {
                        payload[key] = val;
                    }
                }
            }
        });

        return payload;
    }

    return [];
}


export let arrayToString = (array) => {
    let string = "";

    Object.keys(array).forEach((key) => {
        string += key + ": " + array[key] + ", ";
    });

    string = string.substring(0, string.length - 2);

    return string;
}

export let ucfirst = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export let isFunction = (functionToCheck) => {
    return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
}

export const isEmpty = value => {
    return (
        value == null || // From standard.js: Always use === - but obj == null is allowed to check null || undefined
        (typeof value === 'object' && Object.keys(value).length === 0) ||
        (typeof value === 'string' && value.trim().length === 0)
    )
}

export const capitalize = word => {
    return word[0].toUpperCase() + word.substring(1).toLowerCase();
}

export const trim = (str, c = '\\s') => {
    return str.replace(new RegExp(`^([${c}]*)(.*?)([${c}]*)$`), '$2')
}

export const debounce = (func, delay, { leading } = {}) => {
    let timerId

    return (...args) => {
        if (!timerId && leading) {
            func(...args)
        }
        clearTimeout(timerId)

        timerId = setTimeout(() => func(...args), delay)
    }
}