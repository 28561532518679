<template>
    <div class="newsletter mb-sm-4 mb-4">
        <form class="newsletter_form">
            <input type="text" v-model="url" name="url" class="uk-input d-none">
            
            <input type="text" v-model="email" name="email" class="uk-input" :placeholder="translations.email">
            <button type="submit" @click.prevent="handleSignup" class="uk-button uk-button-primary mb-sm-0 mb-4"><i class="fa-solid fa-arrow-right"></i></button>        
        </form>
        <div class="input-errors" v-if="emailErrors.length > 0">
            <p v-for="error in emailErrors">{{ error }}</p>
        </div>        
    </div>
</template>

<script>
  import {EventBus} from "../EventBus";

  export default {
    name: "NewsletterForm",
    mounted() {
    },
    data(){
      return {
        email: '',                
        emailErrors: {},
        url: '',   
      }
    },
    methods: {
      handleSignup(){
          //alert(this.routes.signup);
        if(this.url == ''){
          window.axios.post(this.routes.signup, {email: this.email})
            .then(res => {
              this.emailErrors = {};            
              let resData = res.data;
              if(resData.success)
              {
                //window.location.href = this.routes.accountPage;
                EventBus.$emit('NOTIFY', {message: resData.message, status: 'success', position: 'top-right', 'timeout': 3000});
              }
              else
              {
                EventBus.$emit('NOTIFY', {message: resData.message, status: 'danger', position: 'top-right', 'timeout': 3000});
              }
            })
            .catch(err => {
              this.emailErrors = {};            
              this.emailErrors = err?.response?.data?.email ?? {};            
              EventBus.$emit('NOTIFY', {message: this.translations.incorrect_fields, status: 'danger', position: 'top-right', 'timeout': 3000});
            });
        }
      },
    },
    props: {
      translations: {
        type: Object|Array,
        required: true
      },
      routes: {
        type: Object|Array,
        required: true
      }
    }
  }
</script>

<style scoped>
</style>