var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", {
      staticClass: "d-none",
      attrs: { id: "password-forgotten-success" },
    }),
    _vm._v(" "),
    _c(
      "form",
      {
        staticClass:
          "form--password-forgotten uk-child-width-1-1 uk-grid-medium uk-width-1-1 mt-5",
        attrs: { "uk-grid": "" },
      },
      [
        _c("div", [
          _c(
            "label",
            { staticClass: "uk-form-label", attrs: { for: "email" } },
            [_vm._v(_vm._s(_vm.translations.email) + " *")]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "uk-form-controls" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.email,
                  expression: "email",
                },
              ],
              staticClass: "uk-input",
              attrs: { type: "text", name: "email" },
              domProps: { value: _vm.email },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.email = $event.target.value
                },
              },
            }),
          ]),
          _vm._v(" "),
          _vm.emailErrors.length > 0
            ? _c(
                "div",
                { staticClass: "input-errors" },
                _vm._l(_vm.emailErrors, function (error) {
                  return _c("p", [_vm._v(_vm._s(error))])
                }),
                0
              )
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "uk-width-1-1 mt-5" }, [
          _c(
            "div",
            {
              staticClass:
                "d-flex flex-sm-row flex-column align-items-sm-center",
            },
            [
              _c(
                "button",
                {
                  staticClass:
                    "uk-button uk-button-secondary uk-width-1-1 mb-sm-0 mb-4",
                  attrs: { type: "submit" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.handleForgotten.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm.isLoading
                    ? _c("div", { staticClass: "loader" }, [
                        _c("i", {
                          staticClass: "fa-solid fa-circle-notch fa-spin me-2",
                        }),
                      ])
                    : _vm._e(),
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.translations.forgot_password) +
                      "\n        "
                  ),
                ]
              ),
            ]
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }