var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("i", {
      staticClass: "fa-solid fa-info-circle me-2 info--circle",
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.handleModal.apply(null, arguments)
        },
      },
    }),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "uk-flex-top info--modal",
        attrs: { id: _vm.modalId, "uk-modal": "" },
      },
      [
        _c(
          "div",
          {
            staticClass:
              "uk-modal-dialog uk-margin-auto-vertical uk-modal-body",
          },
          [
            _c("button", {
              staticClass: "uk-modal-close-default",
              attrs: { type: "button", "uk-close": "" },
            }),
            _vm._v(" "),
            _vm.headline !== ""
              ? _c("h2", { staticClass: "uk-modal-title" }, [
                  _vm._v(_vm._s(_vm.headline)),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.body !== "" ? _c("p", [_vm._v(_vm._s(_vm.body))]) : _vm._e(),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }