var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "sidebar__nav" },
    _vm._l(_vm.filter.values, function (filtervalue) {
      return _c("filter-sidebar-dynamic-filters-radios-item", {
        key: filtervalue.id,
        attrs: {
          filter: filtervalue,
          labelid: _vm.filter.id,
          "active-filters": _vm.localActiveFilters,
          "filter-values": _vm.filter.values,
        },
        on: { input: _vm.handleChange },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }