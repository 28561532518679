<template>
    <div>
        <div :class="layoutClasses">
            <div v-for="document in documents">
                <!-- <button class="c-download-link" href="#modal-gated-content" uk-toggle> -->
                <button class="c-download-link" @click="downloadDocument(document.url);">
                    <span class="c-download-link__image"  :style="{'background-image': 'url(' + document.img + ')'}"></span>
    
                    <div class="c-download-link__footer">
                        <div>
                            <p class="c-download-link__title">{{document.headline}}</p>
                            <p class="c-download-link__file-size">{{document.filesize}}</p>
                        </div>
                        <span class="c-download-link__bttn"><i class="fa-regular fa-download"></i></span>
                    </div>
                </button>
            </div>
        </div>
        
        <div ref="gatedContentModal" id="modal-gated-content" class="uk-flex-top c-modal" uk-modal>
            <div class="uk-modal-dialog uk-margin-auto-vertical">
                <button class="c-button-icon c-button-icon--remove uk-modal-close-default" type="button" uk-close></button>

                <div class="uk-modal-body" uk-overflow-auto>
                    <div class="c-modal__content">
                        <h2 class="t-h2 mt-0">{{translations.headline_popup}}</h2>
                        <div v-html="translations.body_popup"></div>
                    
                        <div ref="formSection">
                            <form class="uk-form-stacked form-custom" ref="form">
                                <div class="h-p-rel p-4 h-mb--40 h-mt--40" :class="'uk-alert-' + message.type" uk-alert v-for="message in messages" uk-alert>
                                    <a class="uk-alert-close" uk-close></a>
                                    <p>{{ message.message }}</p>
                                </div>
                    
                                <div v-if="!hideForm">
                                    <fieldset class="h-mt--60">
                                        <div class="uk-grid-small" uk-grid>
                                            <div class="uk-form-group uk-width-1-2@s">
                                                <label for="firstname" class="uk-form-label" v-bind:class="{ 'uk-text-danger': errors.firstname }">{{ translations.firstname }}</label>
                                                <input type="text" class="uk-input uk-form-control" v-bind:class="{ 'uk-form-danger': errors.firstname }" id="firstname" name="firstname" v-model="firstname">
                                                <p class="uk-text-danger mt-1" v-if="errors.firstname" v-for="error in errors.firstname">{{ error }}</p>
                                            </div>
                                            <div class="uk-form-group uk-width-1-2@s">
                                                <label for="lastname" class="uk-form-label" v-bind:class="{ 'uk-text-danger': errors.lastname }">{{ translations.lastname }}</label>
                                                <input type="text" class="uk-input uk-form-control" v-bind:class="{ 'uk-form-danger': errors.lastname }" id="lastname" name="lastname" v-model="lastname">
                                                <p class="uk-text-danger mt-1" v-if="errors.lastname" v-for="error in errors.lastname">{{ error }}</p>
                                            </div>
                                            <div class="uk-form-group uk-width-1-2@s">
                                                <label for="company" class="uk-form-label" v-bind:class="{ 'uk-text-danger': errors.company }">{{ translations.company }} <span>({{ translations.optional }})</span></label>
                                                <input type="text" class="uk-input uk-form-control" v-bind:class="{ 'uk-form-danger': errors.company }" id="company" name="company" v-model="company">
                                                <p class="uk-text-danger mt-1" v-if="errors.company" v-for="error in errors.company">{{ error }}</p>
                                            </div>
                                            <div class="uk-form-group uk-width-1-2@s">
                                                <label for="email" class="uk-form-label" v-bind:class="{ 'uk-text-danger': errors.email }">{{ translations.email }}</label>
                                                <input type="email" class="uk-input uk-form-control" v-bind:class="{ 'uk-form-danger': errors.email }" id="email" name="email" v-model="email">
                                                <p class="uk-text-danger mt-1" v-if="errors.email" v-for="error in errors.email">{{ error }}</p>
                                            </div>
                                            <div class="uk-form-group uk-width-1-1 form-url" id="url_wrapper">
                                                <label class="uk-form-label" for="url">Url</label>
                                                <input class="uk-input uk-form-control" id="url" name="url" type="text" placeholder="Url" v-model="url">
                                            </div>
                                        </div>
                                    </fieldset>
                    
                                    <fieldset class="h-mt--30">
                                        <div class="d-flex flex-column  align-items-start justify-content-between">
                                            <div class="uk-form-group uk-width-4-5@s uk-width-3-5@m">
                                                <label class="custom-checkbox"  v-bind:class="{ 'uk-form-danger': errors.privacy }" for="privacy">
                                                    <input class="uk-checkbox" id="privacy" name="privacy" type="checkbox" v-model="privacy">
                                                    <span class="t-cms t-text--small">
                                                        <p>{{ translations.i_agree_with }} <a :href="routes.privacy_page" target="_blank" :title="translations.privacy_policy">{{ translations.privacy_policy }}</a></p>
                                                    </span>
                                                </label>
                                                <p class="uk-text-danger mt-1" v-if="errors.privacy" v-for="error in errors.privacy">{{ error }}</p>
                                            </div>
                    
                                            <button class="uk-button uk-button-primary mt-4" @click.prevent="handleFormSubmit">{{ translations.submit }}</button>
                                        </div>
                                    </fieldset>
                    
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import UIkit from 'uikit';
    import Cookies from 'js-cookie';

    export default {
        name: "GatedContentDocuments",
        data() {
            return {
                errors: {},
                firstname: '',
                lastname: '',
                company: '',
                email: '',
                comments: '',
                url: '',
                privacy: false,
                messages: {},
                hideForm: false,
            }
        },
        methods: {
            downloadDocument(urlDocument) {
                const canDownload = Cookies.get('gated_content_ok');

                if(canDownload) {
                    window.open(urlDocument, '_blank');

                } else {
                    UIkit.modal(this.$refs.gatedContentModal).show();
                }
            },
            handleFormSubmit() {
                let data = new FormData(this.$refs.form);
                data.append("js_url", "js_set");
                data.append("action", "gated-content");
                data.append("controller", "forms");

                window.axios
                    .post(this.routes.form_handle, data, {
                        headers: {
                            'X-Requested-With': 'XMLHttpRequest',
                            'Content-type': 'application/x-www-form-urlencoded',
                        }
                    })
                    .then(this.handleRes);
            },
            handleRes(res){
                this.errors = res?.data?.errorFields ?? {};
                this.messages = res?.data?.messages ?? {};

                if(res?.data?.setCookie)
                {
                    Cookies.set('gated_content_ok', 1)
                }

                if(res?.data?.hideForm)
                {
                    this.hideForm = true;
                }

                if(res?.data?.toTop)
                {
                    this.$refs.formSection.scrollIntoView({behavior: "smooth", block: "start"});
                }
            },
        },
        props: {
            routes: {
                type: Object|Array,
                required: true,
            },
            translations: {
                type: Object|Array,
                required: true
            },
            documents: {
                type: Object|Array,
                required: false,
                default() { return " "; }
            },  
            layoutClasses: {
                type: String,
                required: true,
                default() { return "uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-4@l uk-grid-small uk-grid"; }
            }
        }
    }
</script>

<style scoped>

</style>