<template>        
    <a :href="routes.overview" class="has-icon">
        <span><i class="fa-regular fa-plus"></i></span> {{translations}}
    </a>
</template>

<script>    
    import { EventBus } from "../EventBus";

    export default {
        name: "WishlistIcon",        
        mounted() {
            EventBus.$on('WISHLIST_UPDATE', wishlist => {
                this.count = wishlist.productsCount;
            });
        },
        data() {
            return {
                count: this.initCount ?? 0
            };
        },
        props: {
            translations: {
                type: String,
                required: false,
                default() { return " "; }
            },
            initCount: String|Number,
            routes: {
                type: Object,
                required: true
            }
        }
    }
</script>

<style scoped>

</style>