var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "button",
      {
        staticClass: "c-product",
        attrs: { title: _vm.product.name },
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.toggleWishlist.apply(null, arguments)
          },
        },
      },
      [
        _c("span", { staticClass: "c-product__header" }, [
          _c("p", { staticClass: "c-product__line" }, [
            _vm._v(_vm._s(_vm.product.filter.name)),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "c-product__title" }, [
            _vm._v(_vm._s(_vm.product.name)),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "c-product__nr" }, [
            _vm._v(_vm._s(_vm.product.sku)),
          ]),
        ]),
        _vm._v(" "),
        _c("img", {
          staticClass: "c-product__image",
          attrs: { src: _vm.imageUrl, alt: _vm.product.name },
        }),
        _vm._v(" "),
        !_vm.checked
          ? _c("span", { staticClass: "c-product__add" }, [
              _c("i", { staticClass: "fa-regular fa-plus" }),
            ])
          : _c("span", { staticClass: "c-product__remove" }, [
              _c("i", { staticClass: "fa-regular fa-trash-can" }),
            ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }