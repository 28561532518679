<template>
    <a class="cart-dropdown__row">
        <span>
            <img class="cart-dropdown__image lazyload" :src="imageUrl">
        </span>

        <div class="cart-dropdown__content">
            <div class="cart-dropdown__header">
                <div class="cart-dropdown__title"><span v-if="item.quantity > 1">{{ item.quantity }}x</span> {{ item.product.name || '' }}</div>
                <div class="cart-dropdown__price">{{ showVat ? item.formatted.subtotal_min_discount : item.formatted.subtotal_min_discount_incl_vat }}</div>
            </div>

            <div v-if="variations !== ''" class="cart-dropdown__description">
                <p>{{ variations }}</p>
            </div>
        </div>
    </a>
</template>

<script>
    import { capitalize } from '../functions';
    import { trim } from '../functions';

    export default {
        name: "CartDropdownItem",
        data() {
            return {
                item: this.initItem
            }
        },
        watch: {
            initItem(newVal, oldVal) {
                this.item = newVal;
            }
        },
        computed: {
            variations() {
                let str = '';
                if (this.item?.product?.color) {
                    str += `${capitalize(this.translations.color)}: ${this.item.product.color.name} - `;
                }

                if (this?.item?.product?.size?.group) {
                    str += `${capitalize(this.item.product.size.group.name)}: ${this.item.product.size.value} ${this.item.product.size.group.unit_shorthand} - `
                }

                return trim(str, ' -');
            },
            imageUrl() {
                return this?.item?.product?.images[0]?.conversion_urls?.thumb ?? '';
            }
        },
        props: {
            initItem: {
                type: Object,
                required: true,
            },
            translations: {
                type: Object,
                required: true,
                default() { return {} }
            },
            showVat: {
                type: Boolean,
                required: false,
                default: false
            }
        }
    }
</script>

<style scoped>

</style>