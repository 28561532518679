<template>
    <!-- PRODUCT MODAL -->
    <div class="modal modal--big" :hidden="hidden">
        <div class="modal__box">
            <div class="d-flex align-items-center justify-content-md-start justify-content-center mb-sm-5 mb-4">
                <h1 class="modal--title my-0 me-4 uk-visible@s"><i class="fa-solid fa-check text--success"></i></h1>
                <h1 class="modal--title my-0 uk-text-break uk-text-left@s uk-text-center">{{ translations.add_product_modal.added }}</h1>
            </div>

            <div class="product-row">
                <div>
                    <div class="image-block uk-position-relative" :data-src="imageUrl" uk-img></div>
                </div>

                <div>
                    <div class="d-flex flex-column justify-content-center align-items-start-md uk-height-1-1 uk-text-center">
                        <h4 class="uk-text-bold my-0 uk-text-break">{{ quantityString }}{{ product.name }}</h4>
<!--                        <p class="text&#45;&#45;small my-0" v-if="variations != ''">{{ variations }}</p>-->
                    </div>
                </div>

                <div>
                    <div class="d-flex flex-column justify-content-center align-items-start-md uk-height-1-1 uk-text-center">
                        <div class="image-banner pt-3 pt-md-0" v-if="discountActive">{{ discountPrice }}</div>
                        <h3 class="my-md-0 mt-2">&euro; {{ totalPrice }}</h3>
                    </div>
                </div>

                <div>
                    <div class="d-flex flex-md-column flex-sm-row flex-column justify-content-md-center align-items-md-end justify-content-center align-items-center uk-height-1-1 uk-child-width1-1">
                        <a :href="routes.add_products_modal.cart" class="uk-button uk-button-primary mb-md-2 mb-sm-0 mb-2 me-md-0 me-sm-2 me-0">{{ translations.add_product_modal.to_cart }}</a>
                        <button @click="hideModal" class="uk-button uk-button-secondary">{{ translations.add_product_modal.continue_shopping }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { EventBus } from "../EventBus";

    export default {
        name: "AddProductModal",
        mounted() {
            EventBus.$on('PRODUCT_ADDED_MODAL', object => {
              this.showModal(object.product, object.quantity, object?.price)
            });
        },
        data() {
            return {
                hidden: true,
                quantity: 1,
                price: 0,
                product: {}
            }
        },
        methods: {
            showModal(product, quantity = 1, price = null) {
                this.quantity = quantity;
                this.product = product;
                this.hidden = false;

                if(price == null) {
                    let usePrice = this.product.price;
                    if(this.product.discount_price != null)
                    {
                      usePrice = this.product.discount_price;
                    }

                    this.price = parseInt(usePrice);
                } else {
                  this.price = price;
                }
            },
            hideModal() {
                this.hidden = true;
                this.product = {};
                this.price = 0;
                this.quantity = 1;
            }
        },
        computed: {
            totalPrice() {
                return (this.product.use_price * this.quantity) / 100;
            },
            discountPrice(){
                return (this.product.price * this.quantity) / 100;
            },
            quantityString()
            {
                let str = '';

                if(this.quantity > 1)
                {
                    str = this.quantity + 'x ';
                }

                return str;
            },
            imageUrl() {
              return this.product?.image ?? '';
            },
            discountActive () {
              return this?.product?.discount_active ?? false;
            }
        },
        props: {
            translations: {
                type: Object|Array,
                required: true
            },
            routes: {
                type: Object|Array,
                required: true
            },
        }
    }
</script>

<style scoped>
    
</style>