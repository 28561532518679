var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "h-mt--60" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-6" }, [
        _c(
          "div",
          { staticClass: "c-timeline" },
          _vm._l(_vm.timelineSections, function (section, index) {
            return _c(
              "button",
              {
                key: section.id,
                staticClass: "c-timeline__section",
                class: { "is-active": index == _vm.currentSectionIndex },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.setCurrentIndex(index)
                  },
                },
              },
              [
                section.period
                  ? _c("p", { staticClass: "c-timeline__year" }, [
                      _vm._v(_vm._s(section.period)),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                section.headline
                  ? _c("h3", { staticClass: "c-timeline__title" }, [
                      _vm._v(_vm._s(section.headline)),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                section.intro
                  ? _c("div", { staticClass: "t-cms t-text--small" }, [
                      _c("p", [_vm._v(_vm._s(section.intro))]),
                    ])
                  : _vm._e(),
              ]
            )
          }),
          0
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-md-5 offset-md-1 mt-5 mt-md-0" },
        _vm._l(
          _vm.timelineSections[_vm.currentSectionIndex].images,
          function (img) {
            return _c("img", {
              key: img.id,
              staticClass: "mb-3 ",
              attrs: {
                src: img.url,
                width: img.width,
                height: img.height,
                alt: img.descrtiption,
              },
            })
          }
        ),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }