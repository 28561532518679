<template>
    <div>
        <div class="address-box">
            <section>
                <p class="my-0">
                    {{ address.street }} {{ address.number }}{{ address.number_addon }} {{ address.busnumber }} <br>
                    {{ address.postalcode }} {{ address.commune }}, <br>
                    {{ address.country.name }}
                </p>
            </section>

            <section>
                <a href="javascript:void(0)" @click.prevent="handleStartEdit"><i class="fa-solid fa-pencil fa-fw me-1"></i>{{ translations.edit_address }}</a>
                <a href="javascript:void(0)" @click.prevent="handleTrash">
                  <div class="loader" v-if="trashIsLoading"><i class="fa-solid fa-circle-notch fa-spin fa-fw me-1"></i></div>
                  <i class="fa-solid fa-trash fa-fw me-1" v-else></i>
                  {{ translations.delete_address }}
                </a>
                <a href="javascript:void(0)" class="no-click" v-if="address.id === defaultDelivery"><i class="fa-solid fa-house fa-fw me-1"></i>{{ translations.current_default_delivery }}</a>
                <a href="javascript:void(0)" class="no-click" v-if="address.id === defaultBilling"><i class="fa-solid fa-file-invoice-dollar fa-fw me-1"></i>{{ translations.current_default_billing }}</a>
            </section>
        </div>
        <div :id="editModalId" class="uk-modal-container uk-flex-top" uk-modal="esc-close: false; bg-close: false; keyboard: false;">
            <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
                <form class="form-custom" @submit.stop.prevent="handleUpdate" v-on:keyup.enter.stop.prevent="handleUpdate">
                    <div class="uk-grid-small" uk-grid>
                        <div class="uk-width-1-2@s">
                            <label class="uk-form-label" for="streetname">{{ translations.streetname }}</label>
                            <div class="uk-form-controls">
                                <input class="uk-input" id="streetname" type="text" :placeholder="translations.streetname" v-model="editAddress.street">
                                <div class="input-errors" v-if="editErrors.street">
                                  <p v-for="error in editErrors.street">{{ error }}</p>
                                </div>
                            </div>
                        </div>

                        <div class="uk-width-1-6@s">
                            <label class="uk-form-label" for="number">{{ translations.housenr }}</label>
                            <div class="uk-form-controls">
                                <input class="uk-input" id="number" type="text" :placeholder="translations.housenr" v-model="editAddress.number">
                                <div class="input-errors" v-if="editErrors.number">
                                  <p v-for="error in editErrors.number">{{ error }}</p>
                                </div>
                            </div>
                        </div>

                        <div class="uk-width-1-6@s">
                            <label class="uk-form-label" for="number_addon">{{ translations.number_addon_short }}</label>
                            <div class="uk-form-controls">
                                <input class="uk-input" id="number_addon" type="text" :placeholder="translations.number_addon_short" v-model="editAddress.number_addon">
                                <div class="input-errors" v-if="editErrors.number_addon">
                                  <p v-for="error in editErrors.number_addon">{{ error }}</p>
                                </div>
                            </div>
                        </div>

                        <div class="uk-width-1-6@s">
                            <label class="uk-form-label" for="busnumber">{{ translations.busnr }}</label>
                            <div class="uk-form-controls">
                                <input class="uk-input" id="busnumber" type="text" placeholder="Busnr." v-model="editAddress.busnumber">
                                <div class="input-errors" v-if="editErrors.busnr">
                                  <p v-for="error in editErrors.busnr">{{ error }}</p>
                                </div>
                            </div>
                        </div>

                        <div class="uk-width-1-4@s">
                            <label class="uk-form-label" for="postalcode">{{ translations.postalcode }}</label>
                            <div class="uk-form-controls">
                                <input class="uk-input" id="postalcode" type="text" :placeholder="translations.postalcode" v-model="editAddress.postalcode">
                                <div class="input-errors" v-if="editErrors.postalcode">
                                  <p v-for="error in editErrors.postalcode">{{ error }}</p>
                                </div>
                            </div>
                        </div>

                        <div class="uk-width-1-2@s">
                            <label class="uk-form-label" for="commune">{{ translations.commune }}</label>
                            <div class="uk-form-controls">
                                <input class="uk-input" id="commune" type="text" :placeholder="translations.commune" v-model="editAddress.commune">
                                <div class="input-errors" v-if="editErrors.commune">
                                  <p v-for="error in editErrors.commune">{{ error }}</p>
                                </div>
                            </div>
                        </div>

                        <div class="uk-width-1-4@s">
                            <label class="uk-form-label" for="country">{{ translations.country }}</label>
                            <div class="uk-form-controls">
                                <select name="company-type" id="country" class="custom-select uk-width-1-1" v-model="editAddress.country_id">
                                    <option :value="country.id" v-for="country in countries">{{ country.name }}</option>
                                </select>
                                <div class="input-errors" v-if="editErrors.country_id">
                                  <p v-for="error in editErrors.country_id">{{ error }}</p>
                                </div>
                            </div>
                        </div>

                        <div class="uk-width-1-1 mb-3">
                          <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid">
                            <p><span class="uk-text-bold">{{ translations.default_address }}s</span></p>

                            <label class="custom-checkbox">
                              <input class="uk-checkbox me-2 mt-1" type="checkbox" name="default_delivery" v-model="editDefaultDelivery">
                              <p class="my-0">{{ translations.default_delivery }}</p>
                            </label>
                            <div class="input-errors" v-if="editErrors.default_delivery">
                              <p v-for="error in editErrors.default_delivery">{{ error }}</p>
                            </div>

                            <label class="custom-checkbox">
                              <input class="uk-checkbox me-2 mt-1" type="checkbox" name="default_billing" v-model="editDefaultBilling">
                              <p class="my-0">{{ translations.default_billing }}</p>
                            </label>
                            <div class="input-errors" v-if="editErrors.default_billing">
                              <p v-for="error in editErrors.default_billing">{{ error }}</p>
                            </div>
                          </div>
                        </div>

                        <div class="uk-width-1-1">
                            <div class="d-flex justify-content-end">
                                <button class="uk-button uk-button-secondary me-2" @click.prevent="cancelUpdate">{{ translations.cancel }}</button>
                                <button class="uk-button uk-button-primary" @click.prevent="handleUpdate">
                                  <div class="loader" v-if="editIsLoading"><i class="fa-solid fa-circle-notch fa-spin me-2"></i></div>
                                  {{ translations.save }}
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
  import UIkit from 'uikit';
  import { EventBus } from "../EventBus";
  export default {
    name: "UserAdressCard",
    data() {
        return {
          address: Object.assign({}, this.initAddress),
          editModalId: "edit-modal-" + this._uid,
          editAddress: Object.assign({}, this.initAddress),
          editDefaultDelivery: this.initDefaultDelivery(),
          editDefaultBilling: this.initDefaultBilling(),
          editErrors: {},
          editIsLoading: false,
          trashIsLoading: false,
          trashRoute: this.routes.trash.replace(":address_id", this.initAddress.id),
          updateRoute: this.routes.update.replace(":address_id", this.initAddress.id)
        }
    },
    methods: {
      handleStartEdit(){
        UIkit.modal(document.getElementById(this.editModalId)).show();
      },
      initDefaultDelivery(){
        return this.initAddress.id === this.defaultDelivery;
      },
      initDefaultBilling(){
        return this.initAddress.id === this.defaultBilling;
      },
      cancelUpdate(){
        UIkit.modal(document.getElementById(this.editModalId)).hide();
        this.editAddress = this.address;
        this.editErrors = {};
        this.editDefaultBilling = this.initDefaultBilling();
        this.editDefaultDelivery = this.initDefaultDelivery();
      },
      handleUpdate(){
        UIkit.modal(document.getElementById(this.editModalId)).show();
        this.editIsLoading = true;

        let data =  {
          street: this.editAddress.street,
          number: this.editAddress.number,
          number_addon: this.editAddress.number_addon,
          busnumber: this.editAddress.busnumber,
          postalcode: this.editAddress.postalcode,
          commune: this.editAddress.commune,
          country_id: this.editAddress.country_id,
          default_delivery: this.editDefaultDelivery,
          default_billing: this.editDefaultBilling
        };

        window.axios.post(this.updateRoute, data)
          .then(res => {
              let resData = res.data;

              if(resData.messages) {
                  resData.messages.forEach(message => {
                    EventBus.$emit('NOTIFY', message)
                  });
              }

              if(resData.address) {
                this.address = resData.address;
                this.editAddress = resData.address;
              }

              if(resData.defaultBilling || resData.defaultBilling === "") {
                this.defaultBilling = resData.defaultBilling;
                EventBus.$emit("DEFAULT_BILLING_ADDRESS_UPDATE", resData.defaultBilling);
                this.editDefaultBilling = this.initDefaultBilling();
              }

              if(resData.defaultDelivery || resData.defaultDelivery === "") {
                this.defaultDelivery = resData.defaultDelivery;
                EventBus.$emit("DEFAULT_DELIVERY_ADDRESS_UPDATE", resData.defaultDelivery);
                this.editDefaultDelivery = this.initDefaultDelivery();
              }

              if(resData.success) {
                UIkit.modal(document.getElementById(this.editModalId)).hide();
              }

              this.editIsLoading = false;
          })
          .catch(err => {
            this.editErrors = err?.response?.data ?? {};
            this.editIsLoading = false;
          })
      },
      handleTrash () {
        this.trashIsLoading = true;
        UIkit.modal.confirm('Bent u zeker?').then(function() {
            window.axios.delete(this.trashRoute)
              .then(res => {
                  let resData = res.data;

                  if(resData.message) {
                    EventBus.$emit('NOTIFY', resData.message);
                  }

                  if(resData.success) {
                    EventBus.$emit("REMOVE_ADDRESS", this.initAddress);
                  }
              });
        }.bind(this), function () {
          this.trashIsLoading = false;
        }.bind(this));
      }
    },
    props: {
      initAddress: {
        type: Object|Array,
        required: true
      },
      defaultDelivery: {
        type: Number,
        required: false,
        default: null
      },
      defaultBilling: {
        type: Number,
        required: false,
        default: null
      },
      countries: {
        type: Object|Array,
        required: true
      },
      routes: {
        type: Object|Array,
        required: true
      },
      translations: {
        type: Object|Array,
        required: true
      }
    }
  }
</script>

<style scoped>

</style>