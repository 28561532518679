<template>
  <div>
    <div class="d-none" id="password-forgotten-success"></div>

    <form class="form--password-forgotten uk-child-width-1-1 uk-grid-medium uk-width-1-1 mt-5" uk-grid>
      <div>
        <label class="uk-form-label" for="email">{{ translations.email }} *</label>
        <div class="uk-form-controls">
          <input
            type="text"
            v-model="email"
            name="email"
            class="uk-input"
          />
        </div>
        <div class="input-errors" v-if="emailErrors.length > 0">
          <p v-for="error in emailErrors">{{ error }}</p>
        </div>
      </div>

      <div class="uk-width-1-1 mt-5">
        <div class="d-flex flex-sm-row flex-column align-items-sm-center">
          <button
            type="submit"
            @click.prevent="handleForgotten"
            class="uk-button uk-button-secondary uk-width-1-1 mb-sm-0 mb-4"
          >
            <div class="loader" v-if="isLoading"><i class="fa-solid fa-circle-notch fa-spin me-2"></i></div>
            {{ translations.forgot_password }}
          </button>
        </div>
      </div>

    </form>
  </div>
</template>

<script>
import { EventBus } from '../EventBus';

export default {
  name: 'PasswordForgottenForm',
  mounted() {},
  data() {
    return {
      email: '',
      emailErrors: {},
      isLoading: false,
    };
  },
  methods: {
    handleForgotten() {
      this.isLoading = true;
      window.axios
        .post(this.routes.password_forgotten, {
          email: this.email,
          action: "forgotten",
        })
        .then((res) => {
          this.emailErrors = {};

          let resData = res.data;
          if (resData.success) {
            document.querySelector('.form--password-forgotten').classList.add('d-none');
            document.querySelector('#password-forgotten-success').classList.remove('d-none');
            document.querySelector('#password-forgotten-success').innerHTML = resData.message;
          } else {
            EventBus.$emit('NOTIFY', {
              message: resData.message,
              status: 'danger',
              position: 'top-right',
              timeout: 3000,
            });
            this.isLoading = false;
          }
        })
        .catch((err) => {
          this.emailErrors = {};

          this.emailErrors = err?.response?.data?.email ?? {};

          EventBus.$emit('NOTIFY', {
            message: this.translations.incorrect_fields,
            status: 'danger',
            position: 'top-right',
            timeout: 3000,
          });
          this.isLoading = false;
        });
    },
  },
  props: {
    translations: {
      type: Object | Array,
      required: true,
    },
    routes: {
      type: Object | Array,
      required: true,
    },
  },
};
</script>

<style scoped></style>