<template>
  <a :href="category.slug" :title="category.name" class="uk-width-1-4@m uk-width-1-3@s block-link-category-wrapper">
    <div :class="className" :data-src="category.image" uk-img>
      <div class="overlay" v-if="category.image"></div>
    </div>
    <span>{{ category.name }}</span>
  </a>
</template>

<script>
    export default {
        name: "SubCategoryItem",
        computed: {
            className() {
              return this.imageUrl === false ? 'block-link-category' : 'block-image-link-category';
            }
        },
        props: {
          category: {
            type: Object|Array,
            required: true
          }
        }
    }
</script>

<style scoped>

</style>