<template>
    <div id="offcanvas-add-to-cart" class="add-to-cart" uk-offcanvas="overlay: true" ref="popup">
        <div class="uk-offcanvas-bar">
            <button class="uk-offcanvas-close" type="button" uk-close></button>

            <div v-if="basartProduct === null && originalProduct !== null">
                <div class="uk-position-relative">
                    <div :data-src="originalProduct.image" uk-img="" class="image-block contain uk-position-relative"></div>
                </div>

                <h3>{{ originalProduct.name }}</h3>
            </div>
            <div v-else-if="basartProduct !== null">
                <div uk-slideshow="animation: fade; ratio: 710:531;" v-if="basartProduct.images.length">
                    <div class="uk-position-relative uk-visible-toggle product-gallery" tabindex="-1">
                        <ul class="uk-slideshow-items">
                            <li v-for="image in basartProduct.images" class="product-gallery__image" :data-src="image.conversions.gallery ? image.conversions.gallery : image.original"  uk-img="target: !.uk-slideshow-items"></li>
                        </ul>

                        <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slideshow-item="previous"></a>
                        <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slideshow-item="next"></a>
                    </div>
                    <ul class="uk-slideshow-nav uk-dotnav uk-flex-center uk-margin"></ul>
                </div>

                <h3>{{ basartProduct.name }}</h3>
            </div>

            <product-configurator
                v-if="basartProduct"
                :allow-history="false"
                :colors="colors"
                :product="basartProduct"
                :routes="routes"
                :siblings="siblings"
                :sizes="sizes"
                :translations="translations"
            ></product-configurator>

<!--            <div class="d-flex flex-sm-row flex-column align-items-baseline">-->
<!--                <h2 class="my-0 mr-3">94,95</h2>-->
<!--                <h5 class="my-0 mr-3 old-price"><s>105,50</s></h5>-->
<!--                <p class="my-0 body&#45;&#45;small">-->
<!--                    <strong>Je bespaart 20%</strong> (bij min 3 stuks)-->
<!--                </p>-->
<!--            </div>-->

<!--            <div class="product__stock product__stock&#45;&#45;success mt-3 mb-4">-->
<!--                <div class="icon">-->
<!--                    <i class="fas fa-check"></i>-->
<!--                </div>-->

<!--                <p>Op voorraad, morgen in huis</p>-->
<!--            </div>-->

<!--            <div class="size-picker mb-4">-->
<!--                <div class="sizes">-->
<!--                    <button class="size uk-active">44</button>-->
<!--                    <button class="size">46</button>-->
<!--                    <button class="size">48</button>-->
<!--                    <button class="size">50</button>-->
<!--                    <button class="size">52</button>-->
<!--                    <button class="size">54</button>-->
<!--                    <button class="size">56</button>-->
<!--                    <button class="size">58</button>-->
<!--                    <button class="size">60</button>-->
<!--                    <button class="size">62</button>-->
<!--                    <button class="size">64</button>-->
<!--                </div>-->
<!--            </div>-->

<!--            <div class="row">-->
<!--                <div class="col-sm-3">-->
<!--                    <input type="number" name="amount" class="amount-input" min="1" value="1" />-->
<!--                </div>-->

<!--                <div class="col-sm-9 my-sm-0 my-2">-->
<!--                    <button class="uk-button uk-button-cart uk-width-1-1 uk-width-auto@s">-->
<!--                        <img src="/dist/assets/images/icons/cart&#45;&#45;smaller.svg" class="mr-3" uk-svg />-->
<!--                        In mijn winkelwagen-->
<!--                    </button>-->
<!--                </div>-->
<!--            </div>-->
        </div>
    </div>
</template>

<script>
    import {EventBus} from "../EventBus";
    import UIkit from "uikit";
    import ProductConfigurator from "./ProductConfigurator";

    export default {
        name: "ProductCardConfigurator",
        components: {ProductConfigurator},
        mounted() {
            EventBus.$on("PRODUCT_CARD_CONFIGURATOR", product => {
                this.originalProduct = product;
                this.fetchProductData();
                UIkit.offcanvas(this.$refs.popup).show();
            });

            EventBus.$on("PRODUCT_CHANGED", variation => {
                this.basartProduct = variation;
            });

            EventBus.$on("CLOSE_PRODUCT_CARD_CONFIGURATOR", event => {
                this.closeConfigurator();
            })

            UIkit.util.on('#offcanvas-add-to-cart', 'hide', function () {
                this.resetDefaultValues();
            }.bind(this));
        },
        data() {
            return {
                originalProduct: null,
                basartProduct: null,
                siblings: null,
                colors: null,
                sizes: null,
                activeProduct: null,
            }
        },
        methods: {
            fetchProductData() {
                window.axios.get(this.routes.getConfigurator.replace(":product_id", this.originalProduct.id))
                    .then(res => {
                        this.basartProduct = res?.data?.product ?? null;
                        this.siblings = res?.data?.siblings ?? null;
                        this.colors = res?.data?.colors ?? null;
                        this.sizes = res?.data?.sizes ?? null;
                    });
            },
            resetDefaultValues() {
                this.originalProduct = null;
                this.basartProduct = null;
                this.siblings = null;
                this.colors = null;
                this.sizes = null;
            },
            closeConfigurator() {
                this.resetDefaultValues();
                UIkit.offcanvas(this.$refs.popup).hide();
            }
        },
        props: {
            routes: {
                type: Object|Array,
                required: true,
            },
            translations: {
                type: Object|Array,
                required: true,
            },
        }
    }
</script>

<style scoped>

</style>