var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("form", { staticClass: "form-custom" }, [
    _c(
      "ul",
      { staticClass: "sidebar", attrs: { "uk-accordion": "multiple: true" } },
      _vm._l(_vm.dynamicfilters, function (filter) {
        return _c("filter-sidebar-dynamic-filters", {
          key: filter.id,
          attrs: {
            filter: filter,
            "active-filters": _vm.activeDynamicalFilters,
          },
          model: {
            value: _vm.activeDynamicalFilters,
            callback: function ($$v) {
              _vm.activeDynamicalFilters = $$v
            },
            expression: "activeDynamicalFilters",
          },
        })
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }