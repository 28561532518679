var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "popup",
      staticClass: "add-to-cart",
      attrs: { id: "offcanvas-add-to-cart", "uk-offcanvas": "overlay: true" },
    },
    [
      _c(
        "div",
        { staticClass: "uk-offcanvas-bar" },
        [
          _c("button", {
            staticClass: "uk-offcanvas-close",
            attrs: { type: "button", "uk-close": "" },
          }),
          _vm._v(" "),
          _vm.basartProduct === null && _vm.originalProduct !== null
            ? _c("div", [
                _c("div", { staticClass: "uk-position-relative" }, [
                  _c("div", {
                    staticClass: "image-block contain uk-position-relative",
                    attrs: {
                      "data-src": _vm.originalProduct.image,
                      "uk-img": "",
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("h3", [_vm._v(_vm._s(_vm.originalProduct.name))]),
              ])
            : _vm.basartProduct !== null
            ? _c("div", [
                _vm.basartProduct.images.length
                  ? _c(
                      "div",
                      {
                        attrs: {
                          "uk-slideshow": "animation: fade; ratio: 710:531;",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "uk-position-relative uk-visible-toggle product-gallery",
                            attrs: { tabindex: "-1" },
                          },
                          [
                            _c(
                              "ul",
                              { staticClass: "uk-slideshow-items" },
                              _vm._l(
                                _vm.basartProduct.images,
                                function (image) {
                                  return _c("li", {
                                    staticClass: "product-gallery__image",
                                    attrs: {
                                      "data-src": image.conversions.gallery
                                        ? image.conversions.gallery
                                        : image.original,
                                      "uk-img": "target: !.uk-slideshow-items",
                                    },
                                  })
                                }
                              ),
                              0
                            ),
                            _vm._v(" "),
                            _c("a", {
                              staticClass:
                                "uk-position-center-left uk-position-small uk-hidden-hover",
                              attrs: {
                                href: "#",
                                "uk-slidenav-previous": "",
                                "uk-slideshow-item": "previous",
                              },
                            }),
                            _vm._v(" "),
                            _c("a", {
                              staticClass:
                                "uk-position-center-right uk-position-small uk-hidden-hover",
                              attrs: {
                                href: "#",
                                "uk-slidenav-next": "",
                                "uk-slideshow-item": "next",
                              },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c("ul", {
                          staticClass:
                            "uk-slideshow-nav uk-dotnav uk-flex-center uk-margin",
                        }),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("h3", [_vm._v(_vm._s(_vm.basartProduct.name))]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.basartProduct
            ? _c("product-configurator", {
                attrs: {
                  "allow-history": false,
                  colors: _vm.colors,
                  product: _vm.basartProduct,
                  routes: _vm.routes,
                  siblings: _vm.siblings,
                  sizes: _vm.sizes,
                  translations: _vm.translations,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }