var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { class: _vm.layoutClasses },
      _vm._l(_vm.documents, function (document) {
        return _c("div", [
          _c(
            "button",
            {
              staticClass: "c-download-link",
              on: {
                click: function ($event) {
                  return _vm.downloadDocument(document.url)
                },
              },
            },
            [
              _c("span", {
                staticClass: "c-download-link__image",
                style: { "background-image": "url(" + document.img + ")" },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "c-download-link__footer" }, [
                _c("div", [
                  _c("p", { staticClass: "c-download-link__title" }, [
                    _vm._v(_vm._s(document.headline)),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "c-download-link__file-size" }, [
                    _vm._v(_vm._s(document.filesize)),
                  ]),
                ]),
                _vm._v(" "),
                _vm._m(0, true),
              ]),
            ]
          ),
        ])
      }),
      0
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        ref: "gatedContentModal",
        staticClass: "uk-flex-top c-modal",
        attrs: { id: "modal-gated-content", "uk-modal": "" },
      },
      [
        _c("div", { staticClass: "uk-modal-dialog uk-margin-auto-vertical" }, [
          _c("button", {
            staticClass:
              "c-button-icon c-button-icon--remove uk-modal-close-default",
            attrs: { type: "button", "uk-close": "" },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "uk-modal-body", attrs: { "uk-overflow-auto": "" } },
            [
              _c("div", { staticClass: "c-modal__content" }, [
                _c("h2", { staticClass: "t-h2 mt-0" }, [
                  _vm._v(_vm._s(_vm.translations.headline_popup)),
                ]),
                _vm._v(" "),
                _c("div", {
                  domProps: { innerHTML: _vm._s(_vm.translations.body_popup) },
                }),
                _vm._v(" "),
                _c("div", { ref: "formSection" }, [
                  _c(
                    "form",
                    { ref: "form", staticClass: "uk-form-stacked form-custom" },
                    [
                      _vm._l(_vm.messages, function (message) {
                        return _c(
                          "div",
                          {
                            staticClass: "h-p-rel p-4 h-mb--40 h-mt--40",
                            class: "uk-alert-" + message.type,
                            attrs: { "uk-alert": "", "uk-alert": "" },
                          },
                          [
                            _c("a", {
                              staticClass: "uk-alert-close",
                              attrs: { "uk-close": "" },
                            }),
                            _vm._v(" "),
                            _c("p", [_vm._v(_vm._s(message.message))]),
                          ]
                        )
                      }),
                      _vm._v(" "),
                      !_vm.hideForm
                        ? _c("div", [
                            _c("fieldset", { staticClass: "h-mt--60" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "uk-grid-small",
                                  attrs: { "uk-grid": "" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "uk-form-group uk-width-1-2@s",
                                    },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "uk-form-label",
                                          class: {
                                            "uk-text-danger":
                                              _vm.errors.firstname,
                                          },
                                          attrs: { for: "firstname" },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.translations.firstname)
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.firstname,
                                            expression: "firstname",
                                          },
                                        ],
                                        staticClass: "uk-input uk-form-control",
                                        class: {
                                          "uk-form-danger":
                                            _vm.errors.firstname,
                                        },
                                        attrs: {
                                          type: "text",
                                          id: "firstname",
                                          name: "firstname",
                                        },
                                        domProps: { value: _vm.firstname },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.firstname = $event.target.value
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _vm._l(
                                        _vm.errors.firstname,
                                        function (error) {
                                          return _vm.errors.firstname
                                            ? _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "uk-text-danger mt-1",
                                                },
                                                [_vm._v(_vm._s(error))]
                                              )
                                            : _vm._e()
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "uk-form-group uk-width-1-2@s",
                                    },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "uk-form-label",
                                          class: {
                                            "uk-text-danger":
                                              _vm.errors.lastname,
                                          },
                                          attrs: { for: "lastname" },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.translations.lastname)
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.lastname,
                                            expression: "lastname",
                                          },
                                        ],
                                        staticClass: "uk-input uk-form-control",
                                        class: {
                                          "uk-form-danger": _vm.errors.lastname,
                                        },
                                        attrs: {
                                          type: "text",
                                          id: "lastname",
                                          name: "lastname",
                                        },
                                        domProps: { value: _vm.lastname },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.lastname = $event.target.value
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _vm._l(
                                        _vm.errors.lastname,
                                        function (error) {
                                          return _vm.errors.lastname
                                            ? _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "uk-text-danger mt-1",
                                                },
                                                [_vm._v(_vm._s(error))]
                                              )
                                            : _vm._e()
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "uk-form-group uk-width-1-2@s",
                                    },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "uk-form-label",
                                          class: {
                                            "uk-text-danger":
                                              _vm.errors.company,
                                          },
                                          attrs: { for: "company" },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.translations.company) +
                                              " "
                                          ),
                                          _c("span", [
                                            _vm._v(
                                              "(" +
                                                _vm._s(
                                                  _vm.translations.optional
                                                ) +
                                                ")"
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.company,
                                            expression: "company",
                                          },
                                        ],
                                        staticClass: "uk-input uk-form-control",
                                        class: {
                                          "uk-form-danger": _vm.errors.company,
                                        },
                                        attrs: {
                                          type: "text",
                                          id: "company",
                                          name: "company",
                                        },
                                        domProps: { value: _vm.company },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.company = $event.target.value
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _vm._l(
                                        _vm.errors.company,
                                        function (error) {
                                          return _vm.errors.company
                                            ? _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "uk-text-danger mt-1",
                                                },
                                                [_vm._v(_vm._s(error))]
                                              )
                                            : _vm._e()
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "uk-form-group uk-width-1-2@s",
                                    },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "uk-form-label",
                                          class: {
                                            "uk-text-danger": _vm.errors.email,
                                          },
                                          attrs: { for: "email" },
                                        },
                                        [_vm._v(_vm._s(_vm.translations.email))]
                                      ),
                                      _vm._v(" "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.email,
                                            expression: "email",
                                          },
                                        ],
                                        staticClass: "uk-input uk-form-control",
                                        class: {
                                          "uk-form-danger": _vm.errors.email,
                                        },
                                        attrs: {
                                          type: "email",
                                          id: "email",
                                          name: "email",
                                        },
                                        domProps: { value: _vm.email },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.email = $event.target.value
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _vm._l(
                                        _vm.errors.email,
                                        function (error) {
                                          return _vm.errors.email
                                            ? _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "uk-text-danger mt-1",
                                                },
                                                [_vm._v(_vm._s(error))]
                                              )
                                            : _vm._e()
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "uk-form-group uk-width-1-1 form-url",
                                      attrs: { id: "url_wrapper" },
                                    },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "uk-form-label",
                                          attrs: { for: "url" },
                                        },
                                        [_vm._v("Url")]
                                      ),
                                      _vm._v(" "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.url,
                                            expression: "url",
                                          },
                                        ],
                                        staticClass: "uk-input uk-form-control",
                                        attrs: {
                                          id: "url",
                                          name: "url",
                                          type: "text",
                                          placeholder: "Url",
                                        },
                                        domProps: { value: _vm.url },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.url = $event.target.value
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("fieldset", { staticClass: "h-mt--30" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex flex-column  align-items-start justify-content-between",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "uk-form-group uk-width-4-5@s uk-width-3-5@m",
                                    },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "custom-checkbox",
                                          class: {
                                            "uk-form-danger":
                                              _vm.errors.privacy,
                                          },
                                          attrs: { for: "privacy" },
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.privacy,
                                                expression: "privacy",
                                              },
                                            ],
                                            staticClass: "uk-checkbox",
                                            attrs: {
                                              id: "privacy",
                                              name: "privacy",
                                              type: "checkbox",
                                            },
                                            domProps: {
                                              checked: Array.isArray(
                                                _vm.privacy
                                              )
                                                ? _vm._i(_vm.privacy, null) > -1
                                                : _vm.privacy,
                                            },
                                            on: {
                                              change: function ($event) {
                                                var $$a = _vm.privacy,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = null,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      (_vm.privacy = $$a.concat(
                                                        [$$v]
                                                      ))
                                                  } else {
                                                    $$i > -1 &&
                                                      (_vm.privacy = $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        ))
                                                  }
                                                } else {
                                                  _vm.privacy = $$c
                                                }
                                              },
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "t-cms t-text--small",
                                            },
                                            [
                                              _c("p", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.translations
                                                      .i_agree_with
                                                  ) + " "
                                                ),
                                                _c(
                                                  "a",
                                                  {
                                                    attrs: {
                                                      href: _vm.routes
                                                        .privacy_page,
                                                      target: "_blank",
                                                      title:
                                                        _vm.translations
                                                          .privacy_policy,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.translations
                                                          .privacy_policy
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm._l(
                                        _vm.errors.privacy,
                                        function (error) {
                                          return _vm.errors.privacy
                                            ? _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "uk-text-danger mt-1",
                                                },
                                                [_vm._v(_vm._s(error))]
                                              )
                                            : _vm._e()
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "uk-button uk-button-primary mt-4",
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.handleFormSubmit.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.translations.submit))]
                                  ),
                                ]
                              ),
                            ]),
                          ])
                        : _vm._e(),
                    ],
                    2
                  ),
                ]),
              ]),
            ]
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "c-download-link__bttn" }, [
      _c("i", { staticClass: "fa-regular fa-download" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }