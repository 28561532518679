<template>        
    <div class="h-mt--60">
        <div class="row">
            <div class="col-md-6">
                <div class="c-timeline">
                    <button v-for="(section, index) in timelineSections" :key="section.id" class="c-timeline__section" :class="{'is-active' : index == currentSectionIndex}" @click.prevent="setCurrentIndex(index)">
                        <p v-if="section.period" class="c-timeline__year" >{{section.period}}</p>
                        <h3  v-if="section.headline" class="c-timeline__title">{{section.headline}}</h3>
                        <div v-if="section.intro" class="t-cms t-text--small">
                            <p>{{section.intro}}</p>
                        </div>
                    </button>
                </div>
            </div>

            <div class="col-md-5 offset-md-1 mt-5 mt-md-0">
                <img v-for="img in timelineSections[currentSectionIndex].images" 
                    :key="img.id"
                    class="mb-3 "
                    :src='img.url' 
                    :width='img.width'
                    :height='img.height'
                    :alt='img.descrtiption'>
            </div>
        </div>
    </div>
</template>

<script>    

    export default {
        name: "Timeline",        
        mounted() {
            setInterval(() => {
                this.currentSectionIndex = this.currentSectionIndex + 1;

                if (this.currentSectionIndex > this.numberOfSections - 1) {
                    this.currentSectionIndex = 0;
                }
            }, 10000);
        },
        data() {
            return {
                currentSectionIndex: 0,
                numberOfSections: this.timelineSections.length,
            };
        },
        methods: {
            setCurrentIndex(index) {
                this.currentSectionIndex = index;
            }
        },
        props: {
            timelineSections: {
                type: Object|Array,
                required: true
            }
        }
    }
</script>

<style scoped>

</style>