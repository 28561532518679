<template>
    <div class="dropdown-item" v-if="cart !== null">
        <a :href="routes.overview" class="cart-button">
            <span class="amount">{{ totalItems }}</span>
            <img src="/dist/assets/images/icons/cart.svg" alt="cart icon" draggable="false" uk-svg>
            <span>{{ translations.navigation_label || '' }} <i class="fa-solid fa-chevron-down ms-1 text--tiny"></i></span>
        </a>

        <div uk-dropdown="pos: bottom-right" class="cart-dropdown">
            <h6 class="mt-0">{{ translations.title || '' }}</h6>

            <div v-if="cart.items.length">
                <cart-dropdown-item
                    v-for="item in cart.items"
                    :key="item.id"
                    :init-item="item"
                    :translations="translations"
                    :href="item.product.slug || '#'"
                    :show-vat="showVat"
                ></cart-dropdown-item>

                <div class="d-flex justify-content-end align-items-baseline mt-3">
                    <p class="my-0 me-3">{{ translations.subtotal }}</p>
                    <p class="my-0"><strong>{{ showVat ? cart.formatted.subtotal_min_product_discount : cart.formatted.subtotal_min_product_discount_incl_vat }}</strong></p>
                </div>

                <div class="d-flex justify-content-end mt-3">
                    <a :href="routes.overview" class="uk-button uk-button-primary">{{ translations.to_overview }}</a>
                </div>
            </div>

            <div v-else>
                <h4>{{ translations.empty }}</h4>
                <a :href="routes.shop_page" class="uk-button uk-button-primary">{{ translations.shop_now }}</a>
            </div>
        </div>
    </div>
</template>

<script>
    import CartDropdownItem from "./CartDropdownItem";
    import { EventBus } from "../EventBus";
    import collect from "collect.js";

    export default {
        name: "CartDropdown",
        components: {CartDropdownItem},
        mounted() {
            EventBus.$on('CART_UPDATE', cart => {
                this.cart = cart;
            });
        },
        data() {
            return {
                cart: this.initCart ?? null
            };
        },
        computed: {
            totalItems() {
                return collect(this?.cart?.items ?? []).sum('quantity');
            }
        },
        props: {
            translations: {
                type: Object,
                required: false,
                default() { return  {}; }
            },
            initCart: {
                type: Object,
                required: true,
                default: null
            },
            routes: {
                type: Object,
                required: true
            },
            showVat: {
                type: Boolean,
                required: false,
                default: false
            }
        }
    }
</script>

<style scoped>

</style>