<template>
    <li>
        <a :href="category.slug">
            <span class="text">{{ category.name }}</span>
            <span class="amount">({{ category.products_count }})</span>
        </a>
    </li>
</template>

<script>
export default {
    name: "FilterSidebarCategoriesItem",
    mounted() {
    },
    data() {
        return {
        }
    },
    methods: {
        
    },
    props: {
        category: {
            type: Object|Array,
            required: true
        }
    }
}
</script>

<style scoped>
    
</style>