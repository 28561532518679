<template>
  <div>
    <i class="fa-solid fa-info-circle me-2 info--circle" @click.stop="handleModal"></i>
    <div :id="modalId" class="uk-flex-top info--modal" uk-modal>
      <div class="uk-modal-dialog uk-margin-auto-vertical uk-modal-body">
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <h2 class="uk-modal-title" v-if="headline !== ''">{{ headline }}</h2>
        <p v-if="body !== ''">{{ body }}</p>
      </div>
    </div>
  </div>
</template>

<script>
  import UIkit from 'uikit';
  export default {
    name: "InfoCircle",
    data() {
      return{
        modalId: 'info--modal-' + this._uid,
      }
    },
    methods: {
      handleModal() {
        UIkit.modal(document.getElementById(this.modalId)).show();
      }
    },
    props: {
      headline: {
        type: String,
        required: false,
        default: ''
      },
      body: {
        type: String,
        required: false,
        default: ''
      }
    }
  }
</script>

<style scoped>
</style>