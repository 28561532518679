<template>
    <div class="cart__row">
        <div class="cga-remove">
            <button class="cart__remove" @click="$emit('delete-item', item.id)"><i class="fa-solid fa-xmark"></i></button>
        </div>

        <div class="cga-link">
            <a class="cart__link" :href="route">
                <div class="cart__image">
                    <img class="lazyload" :data-src="imageUrl" :alt="item.product.name" />
                    <div v-if="item.readable" class="image-banner">{{ item.readable }}</div>
                </div>

                <div class="cart__product-info">
                    <p class="mb-1 uk-h4"><strong>{{ item.product.name }}</strong></p>
                    <p v-if="variations !== '' || item.product.type === 'giftcard'" class="mb-1 my-0 text--small">
                        <span v-if="variations !== ''" class="me-2">{{ variations }}</span>
                        <span v-if="item.product.type === 'giftcard' && false" :uk-tooltip="translations.giftcard_translations.edit" @click.stop="editGiftcard"><i class="fa-solid fa-pencil edit"></i></span>
                    </p>
                    <product-delivery-time
                        v-if="item.product.display_delivery_time"
                        :delivery-time="item.product.display_delivery_time"
                        class="mb-1"
                    ></product-delivery-time>
                    <p v-if="notInStock" class="my-0 text--small uk-text-danger">{{ translations.stock }}: {{ item.product.stock }}</p>
                </div>
            </a>
        </div>

        <div class="cga-amount">
            <p class="my-0 me-4 text--small" :class="{'uk-text-danger': notInStock}">{{ translations.quantity || '' }}</p>
            <input type="number" inputmode="numeric" @input="handleUpdate" v-model="quantity" name="amount" class="cart__amount" :class="{'cart__amount--danger': notInStock}" min="1" />
        </div>

        <div v-if="showVat" class="cga-price">
            <p class="cart__prices--current">&euro; {{ item.formatted.subtotal_min_discount }}</p>
            <p class="cart__prices--old" v-if="item.total_discount > 0">&euro; {{ item.formatted.subtotal }}</p>
        </div>
        <div v-else class="cga-price">
            <h3 class="cart__prices--current">&euro; {{ item.formatted.subtotal_min_discount_incl_vat }}</h3>
            <h4 class="cart__prices--old" v-if="item.total_discount > 0">&euro; {{ item.formatted.subtotal_incl_vat }}</h4>
        </div>
    </div>
</template>

<script>
    import { debounce } from '../functions';
    import { capitalize } from '../functions';
    import { trim } from '../functions';
    import GiftcardForm from "./GiftcardForm";
    import UIkit from "uikit";
    import ProductDeliveryTime from "./ProductDeliveryTime";

    export default {
        name: "CartOverviewItem",
      components: {ProductDeliveryTime, GiftcardForm},
      created() {
            this.handleUpdate = debounce(this.handleUpdate, 100);
        },
        data() {
            return {
                item: this.initItem,
                quantity: this.initItem.quantity,
                editGiftcardModalId: 'edit-giftcard-' + this._uid,
            }
        },
        watch: {
            initItem(newVal, oldVal) {
                this.item = newVal;
            }
        },
        methods: {
            handleUpdate() {
                this.$emit('update-item', this.item.id, this.quantity);
            },
            editGiftcard() {
              UIkit.modal(document.getElementById(this.editGiftcardModalId)).show();
            }
        },
        computed: {
            variations() {
                let str = '';

                if(this?.item?.product?.type === "product") {
                    if (this?.item?.product?.color) {
                      str += `${capitalize(this.translations.color)}: ${this.item.product.color.name} - `;
                    }

                    if (this?.item?.product?.size?.group) {
                      str += `${capitalize(this.item.product.size.group.name)}: ${this.item.product.size.value} ${this.item.product.size.group.unit_shorthand} - `
                    }
                } else {
                    let giftcardTranslations = this.translations.giftcard_translations;
                    if(this?.item?.extra?.receive_type === "digital") {
                      str += giftcardTranslations.received + ': ' + giftcardTranslations.digital + ' - ';
                    } else if(this?.item?.extra?.receive_type === "physical") {
                      str += giftcardTranslations.received + ': ' + giftcardTranslations.physical + ' - ';
                    }

                    if(this?.item?.extra?.delivery_type === "to_me") {
                      str += giftcardTranslations.send + ': ' + giftcardTranslations.to_me + ' - ';
                    } else if(this.item?.extra?.delivery_type === "to_other") {
                      str += giftcardTranslations.send + ': ' + giftcardTranslations.to_other + ' - ';
                    }

                    if(this?.item?.extra?.sender_name) {
                      str += giftcardTranslations.sender_name + ': ' + this?.item?.extra?.sender_name + ' - ';
                    }

                    if(this.item?.extra?.delivery_type === "to_other") {
                      str += giftcardTranslations.receiver_name + ": " + this.item?.extra?.receiver_name + ' - ';
                      str += giftcardTranslations.receiver_email + ": " + this.item?.extra?.receiver_email + ' - ';
                    }

                    if(this?.item?.extra?.receive_type === "physical" && this?.item?.extra?.delivery_type === "to_other") {
                        str += giftcardTranslations.send_to + ": ";

                        if(this?.item?.extra?.address?.street) {
                          str += this?.item?.extra?.address?.street + " ";
                        }

                        if(this?.item?.extra?.address?.number) {
                          str += this?.item?.extra?.address?.number;
                        }

                        if(this?.item?.extra?.address?.number_addon) {
                          str += this?.item?.extra?.address?.number_addon;
                        }

                        if(this?.item?.extra?.address?.busnumber) {
                          str += " " + this?.item?.extra?.address?.busnumber;
                        }

                        if(this?.item?.extra?.address?.postalcode) {
                          str += " " + this?.item?.extra?.address?.postalcode;
                        }

                        if(this?.item?.extra?.address?.commune) {
                          str += " " + this?.item?.extra?.address?.commune;
                        }

                        if(this?.item?.extra?.address?.country_name) {
                          str += " " + this?.item?.extra?.address?.country_name;
                        }

                        str += " - ";
                    }
                }

                return trim(str, ' -');
            },
            imageUrl() {
                return this?.item?.product?.images[0]?.conversion_urls?.thumb_xs ?? '';
            },
            notInStock() {
                if (this.highlightStock) {
                    return !this.item.all_in_stock;
                }

                return false;
            },
        },
        props: {
            initItem: {
                type: Object,
                required: true,
            },
            translations: {
                type: Object,
                required: true,
                default() { return {} }
            },
            showVat: {
                type: Boolean,
                required: false,
                default: false
            },
            highlightStock: {
                type: Boolean,
                default: false,
            },
            route: {
                type: String,
                default: null,
                required: false
            },
        }
    }
</script>