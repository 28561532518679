<template>
  <section class="d-flex align-items-center uk-visible@m">
    <p class="my-0 me-3">Gekozen filters</p>

    <div class="active-filter me-2"><span>Dames</span><i class="fa-solid fa-xmark"></i></div>
    <div class="active-filter"><span>Unisex</span><i class="fa-solid fa-xmark"></i></div>
  </section>
</template>

<script>
  import { EventBus } from "../EventBus";

  export default {
    name: "ActiveFilters"
  }
</script>

<style scoped>

</style>