var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("li", { staticClass: "mb-2" }, [
    _c("label", { staticClass: "custom-radio" }, [
      _c("input", {
        staticClass: "uk-radio me-2",
        attrs: { name: _vm.labelid, type: "radio" },
        domProps: { checked: _vm.checked },
        on: { click: _vm.handleChange },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "d-flex align-items-baseline" }, [
        _c("p", { staticClass: "my-0" }, [_vm._v(_vm._s(_vm.filter.name))]),
        _vm._v(" "),
        _c("p", { staticClass: "my-0 text--small text--grey-dark ms-2" }, [
          _vm._v("(" + _vm._s(_vm.filter.products_count) + ")"),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }