<template>
    <li v-bind:class="{'uk-open': ukOpen}">
        <a class="uk-accordion-title" href="#">
            <span>{{ translations.color }}</span>
            <i class="fa-solid fa-chevron-down"></i>
        </a>

        <div class="uk-accordion-content">
            <div class="color-picker">
                <filter-sidebar-colors-item
                    v-for="color in colors"
                    :color="color"
                    :key="color.id"
                    :active-colors="localActiveColors"
                    @input="handleChange"
                >
                </filter-sidebar-colors-item>
            </div>
        </div>
    </li>
</template>

<script>
export default {
    name: "FilterSidebarColors",
    mounted() {
        
    },
    data() {
        return {
            localActiveColors: this.activeColors
        }
    },
    methods: {
        handleChange(val){
            this.localActiveColors = Object.assign({}, val);
            this.$emit('input', this.localActiveColors);
        }
    },
    props: {
        colors: {
            type: Object|Array,
            required: true
        },
        translations: {
            type: Object|Array,
            required: true
        },
        activeColors: {
            type: Object|Array,
            required: true
        },
        ukOpen: {
          type: Boolean,
          required: false,
          default: true
        }
    }
}
</script>

<style scoped>
</style>