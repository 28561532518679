var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "mobile-menu__content" },
    [
      _vm._l(_vm.previousCategories, function (category) {
        return _c("li", [
          _c("section", { staticClass: "mobile-category-nav-item" }, [
            _c(
              "section",
              {
                staticClass: "arrow arrow__previous",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.previousCategoryStructure(category)
                  },
                },
              },
              [_c("i", { staticClass: "fa-solid fa-arrow-left" })]
            ),
            _vm._v(" "),
            _c("a", { attrs: { href: category.slug, title: category.name } }, [
              _vm._v(_vm._s(category.name)),
            ]),
          ]),
        ])
      }),
      _vm._v(" "),
      _vm._l(_vm.showCategories, function (category) {
        return _c("li", [
          category.children_with_products.length > 0
            ? _c("section", { staticClass: "mobile-category-nav-item" }, [
                _c(
                  "a",
                  { attrs: { href: category.slug, title: category.name } },
                  [_vm._v(_vm._s(category.name))]
                ),
                _vm._v(" "),
                _c(
                  "section",
                  {
                    staticClass: "arrow arrow__next",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.nextCategoryStructure(category)
                      },
                    },
                  },
                  [_c("i", { staticClass: "fa-solid fa-arrow-right" })]
                ),
              ])
            : _c(
                "a",
                { attrs: { href: category.slug, title: category.name } },
                [_c("section", [_vm._v(_vm._s(category.name))])]
              ),
        ])
      }),
      _vm._v(" "),
      _vm._l(_vm.otherPages, function (page) {
        return _c("li", [
          _c("a", { attrs: { href: page.url, title: page.title } }, [
            _c("section", { staticClass: "d-flex align-items-center" }, [
              _c("img", {
                staticClass: "me-3",
                attrs: { src: page.image, "uk-svg": "" },
              }),
              _vm._v(" "),
              _c("span", { domProps: { innerHTML: _vm._s(page.title) } }),
            ]),
          ]),
        ])
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }