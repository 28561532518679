<template>
    <a href="javascript:void(0)" :style="colorStyle" v-bind:class="{ 'active': active, 'disabled': disabled }" @click="handleChange" :uk-tooltip="tooltip"></a>
</template>

<script>
export default {
    name: "FilterSidebarColorsItem",
    mounted() {
    },
    data() {
        return {
            colorStyle: '--bg-color:' + this.color.hex,
            localActiveColors: this.activeColors,
            active: this.activeColors[this.color.id] || false,
        }
    },
    computed: {
      tooltip: function () {
          return this.color.name + ' (' + this.color.products_count + ')';
      },
      disabled: function(){
        if(this.color.products_count === 0 && !this.active){
          return true;
        }
        return false;
      }
    },
    methods: {
        handleChange(){
          if(!this.disabled){
            this.active = !this.active;

            this.localActiveColors[this.color.id] = this.active;

            this.$emit('input', this.localActiveColors);
          }
        }
    },
    watch: {
        activeColors(val, oldVal){
            this.active = val[this.color.id] || false;
        }
    },
    props: {
        color: {
            type: Object|Array,
            required: true
        },
        activeColors: {
            type: Object|Array,
            required: true
        }
    }
}
</script>

<style scoped>
    
</style>