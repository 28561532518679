<template>
    <div class="product__stock" v-if="deliveryTime">
        <div class="icon" :style="'color:' + deliveryTime.color" v-if="deliveryTime.icon">
            <i :class="deliveryTime.icon"></i>
        </div>

        <p :style="'color:' + deliveryTime.color">{{ deliveryTime.text }}</p>
    </div>
</template>

<script>
    export default {
        name: "ProductDeliveryTime",
        props: {
            deliveryTime: {
                type: Object|Array,
                required: true
            }
        }
    }
</script>

<style scoped>

</style>