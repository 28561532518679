var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a", {
    class: { active: _vm.activeColor == _vm.color.id },
    style: _vm.style,
    attrs: { href: _vm.slug, "uk-tooltip": _vm.color.name },
    on: {
      click: function ($event) {
        $event.preventDefault()
        _vm.clickable ? _vm.$emit("change", _vm.variation) : null
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }