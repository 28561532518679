var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("li", { class: { "uk-open": _vm.ukOpen } }, [
    _c("a", { staticClass: "uk-accordion-title", attrs: { href: "#" } }, [
      _c("span", [_vm._v(_vm._s(_vm.translations.color))]),
      _vm._v(" "),
      _c("i", { staticClass: "fa-solid fa-chevron-down" }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-accordion-content" }, [
      _c(
        "div",
        { staticClass: "color-picker" },
        _vm._l(_vm.colors, function (color) {
          return _c("filter-sidebar-colors-item", {
            key: color.id,
            attrs: { color: color, "active-colors": _vm.localActiveColors },
            on: { input: _vm.handleChange },
          })
        }),
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }