var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("li", { staticClass: "mb-2" }, [
    _c(
      "label",
      { staticClass: "custom-checkbox", class: { disabled: _vm.disabled } },
      [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.checked,
              expression: "checked",
            },
          ],
          staticClass: "uk-checkbox me-2 mt-1",
          attrs: { name: "brand", type: "checkbox", disabled: _vm.disabled },
          domProps: {
            checked: Array.isArray(_vm.checked)
              ? _vm._i(_vm.checked, null) > -1
              : _vm.checked,
          },
          on: {
            change: [
              function ($event) {
                var $$a = _vm.checked,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.checked = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.checked = $$a
                        .slice(0, $$i)
                        .concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.checked = $$c
                }
              },
              function ($event) {
                return _vm.$emit("input", _vm.checked)
              },
            ],
          },
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "d-flex justify-content-between align-items-center" },
          [
            _c("p", { staticClass: "my-0" }, [_vm._v(_vm._s(_vm.brand.name))]),
            _vm._v(" "),
            _c("p", { staticClass: "my-0 text--small text--grey-dark" }, [
              _vm._v("(" + _vm._s(_vm.brand.products_count) + ")"),
            ]),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }