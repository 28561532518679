<template>
    <div class="col-md-12 offset-md-1">
        <div class="row">
            <form class="col-md-7 col-sm-10 offset-md-0 offset-sm-1 mb-md-0 mb-5 uk-width-1-1 form-custom uk-flex uk-flex-wrap row" style="align-items: flex-end;">

                <div class="uk-width-1-2 uk-width-1-3@l">
                    <label class="uk-form-label" for="amount"><strong>{{ translations.label }}</strong></label>

                    <div class="uk-form-controls">
                        <input v-model="code" class="uk-input" id="code" name="code" type="text">
                    </div>
                </div>

                <div class="ps-0">
                    <button @click.prevent="checkSaldo" type="submit" class="uk-button uk-button-primary uk-text-uppercase">{{ translations.button }}</button>
                </div>

                <div class="uk-width-1-1" v-if="error">
                    <span  class="uk-text-danger">{{ error }}</span>
                </div>
            </form>
            <table class="uk-table uk-table-small uk-table-middle uk-width-1-1 uk-width-1-2@m uk-width-1-4@xl" v-if="giftcard">
                <tr>
                    <th>{{ translations.code }}</th>
                    <td>{{ giftcard.code }}</td>
                </tr>
                <tr>
                    <th>{{ translations.originalPrice }}</th>
                    <td>&euro; {{ giftcard.formatted.original_price }}</td>
                </tr>
                <tr>
                    <th>{{ translations.saldo }}</th>
                    <td>&euro; {{ giftcard.formatted.saldo }}</td>
                </tr>
                <tr :class="{'uk-text-danger': expired}">
                    <th>{{ translations.expiryDate }}</th>
                    <td>{{ giftcard.expires_at }}</td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
    export default {
        name: "SaldoChecker",
        data() {
            return {
                code: '',
                giftcard: null,
                error: null
            }
        },
        methods: {
            checkSaldo() {
                if (this.code.length < 1) {
                    return;
                }

                window.axios.get(this.route.replace('-code-', this.code))
                    .then(res => {
                        this.code = '';
                        this.error = null;
                        this.giftcard = res?.data;
                    })
                    .catch(err => {
                        this.code = '';
                        this.error = err.response?.data?.error;
                    });
            }
        },
        computed: {
            expired() {
                if(this.giftcard && this.giftcard.expires_at) {
                    let parts = this.giftcard.expires_at.split("/");
                    let date = new Date(Number(parts[2]), Number(parts[1]) - 1, Number(parts[0]));

                    return date-Date.parse(new Date()) < 0;
                }

                return false;
            }
        },
        props: {
            translations: {
                type: Object,
                required: true,
            },
            route: {
                type: String,
                required: true
            }
        }
    }
</script>

<style scoped>
</style>