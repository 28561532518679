<template>
    <form class="form-custom">
      <div class="uk-width-1-2@s mb-4" v-if="userLoggedIn">
        <label class="uk-form-label" for="form-stacked-text">{{ translations.user_reference }}</label>
        <div class="uk-form-controls">
          <input class="uk-input" id="form-stacked-text" type="text" :placeholder="translations.user_reference" v-model="userReference">
        </div>
      </div>

      <div class="uk-width-1-1">
        <label class="uk-form-label" for="form-stacked-text">{{ translations.regards }}</label>
        <div class="uk-form-controls">
          <textarea class="uk-textarea mb-4" rows="5" :placeholder="translations.your_message" v-model="additionalRemarks"></textarea>
        </div>
      </div>

      <div class="d-flex justify-content-end">
        <p>
          {{ translations.by_continuing }} <a :href="routes.general_terms_conditions" :title="translations.general_terms_conditions">{{ translations.our_conditions }}</a>.
        </p>
      </div>

      <div class="d-flex justify-content-between align-items-center mt-4">
        <a :href="routes.delivery_billing" :title="translations.delivery_billing" class="uk-button-back">
          <i class="fa-solid fa-arrow-left me-2"></i>
          {{ translations.delivery_billing }}
        </a>

        <button class="uk-button uk-button-primary" @click.prevent="handleCheckout">
          <div class="loader" v-if="isLoading"><i class="fa-solid fa-circle-notch fa-spin me-2"></i></div>
          {{ translations.make_order }}
        </button>
      </div>
    </form>
</template>

<script>
  import {EventBus} from "../EventBus";

  export default {
    name: "OverviewForm",
    mounted() {
    },
    data(){
      return {
          userReference: this.cart?.order_reference ?? '',
          additionalRemarks: this.cart?.order_comment ?? '',
          errors: {},
          isLoading: false
      }
    },
    methods: {
      handleCheckout(){
        this.isLoading = true;

        let postData = {
          user_reference: this.userReference,
          additional_remarks: this.additionalRemarks
        }

        window.axios.post(this.routes.checkout, postData)
          .then(res => {
            this.errors = {};

            let resData = res.data;
            if(resData.success){
              window.location.href = resData.next_page;
            }
            else{
              EventBus.$emit('NOTIFY', {message: resData?.message, status: 'danger', position: 'top-right', 'timeout': 3000});
              this.isLoading = false;
            }
          })
          .catch(err => {
            this.errors = err?.response?.data;

            EventBus.$emit('NOTIFY', {message: this.translations.incorrect_fields, status: 'danger', position: 'top-right', 'timeout': 3000});
            this.isLoading = false;
          });
      }
    },
    props: {
      userLoggedIn: {
        type: Boolean,
        required: true
      },
      cart: {
        type: Array|Object,
        required: true
      },
      translations: {
        type: Array|Object,
        required: true
      },
      routes: {
        type: Array|Object,
        required: true
      }
    }
  }
</script>

<style scoped>

</style>