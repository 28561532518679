<template>
  <form class="form-custom uk-grid-medium" uk-grid>
    <div class="uk-width-1-4@m">
      <label class="uk-form-label" :for="'amount-' + uid "><strong>{{ translations.amount }}</strong> (€) *</label>

      <div class="uk-form-controls">
          <select class="custom-select uk-width-1-1" v-model="chosenGiftcard" :id="'amount-' + uid">
              <giftcard-form-amount-item
                  v-for="giftcard in giftcards"
                  :key="giftcard.id"
                  :giftcard="giftcard"
                  :translations="translations"
              ></giftcard-form-amount-item>
          </select>
          <div class="input-errors" v-if="errors.product_id">
            <p v-for="error in errors.product_id">{{ error }}</p>
          </div>

          <label v-if="variablePriceVisible" class="uk-form-label mt-3" :for="'variable_price-' + uid"><strong>{{ translations.enter_price }}</strong> (€) *</label>
          <input v-if="variablePriceVisible" class="uk-input" :id="'variable_price' + uid" type="number" :placeholder="translations.enter_price" v-model="variablePrice">
          <div class="input-errors" v-if="errors.price">
            <p v-for="error in errors.price">{{ error }}</p>
          </div>
      </div>
    </div>

    <div class="uk-width-1-4@m">
      <label class="uk-form-label" :for="'receive-' + uid"><strong>{{ translations.receive.self }} *</strong></label>

      <div class="uk-form-controls">
        <select class="custom-select uk-width-1-1" name="receive" :id="'receive-' + uid" v-model="receiveType">
          <option value="physical" :disabled="!chosenGiftcard.extra.physical">{{ translations.receive.physical }}</option>
          <option value="digital" :disabled="!chosenGiftcard.extra.digital">{{ translations.receive.digital }}</option>
        </select>
        <div class="input-errors" v-if="errors.receive_type">
          <p v-for="error in errors.receive_type">{{ error }}</p>
        </div>
      </div>
    </div>

    <div class="uk-width-1-2@m">
      <label class="uk-form-label" :for="'delivery-' + uid"><strong>{{ translations.delivery.self }} *</strong></label>

      <div class="uk-form-controls">
        <select class="custom-select uk-width-1-1" name="delivery" :id="'delivery-' + uid" v-model="deliveryType">
          <option value="to_me">{{ translations.delivery.to_me }}</option>
          <option value="to_other">{{ translations.delivery.to_other }}</option>
        </select>
        <div class="input-errors" v-if="errors.delivery_type">
          <p v-for="error in errors.delivery_type">{{ error }}</p>
        </div>
      </div>
    </div>

    <div class="uk-width-1-1 pt-5">
      <h2 class="my-0 me-4 d-inline-block">{{ translations.data_giver }}</h2>
    </div>

    <div class="uk-width-1-1@m">
      <label class="uk-form-label" :for="'name-' + uid"><strong>{{ translations.user.name }} *</strong></label>

      <div class="uk-form-controls">
        <input class="uk-input" :id="'name-' + uid" type="text" :placeholder="translations.user.name" v-model="senderName">
        <div class="input-errors" v-if="errors.sender_name">
          <p v-for="error in errors.sender_name">{{ error }}</p>
        </div>
      </div>
    </div>

    <div v-if="dataReceiverVisible" class="uk-grid-medium" uk-grid>

        <div class="uk-width-1-1 pt-5">
          <h2 class="my-0 me-4 d-inline-block">{{ translations.data_receiver }}</h2>
        </div>

        <div class="uk-width-1-2@m">
          <label class="uk-form-label" :for="'receiver_name-' + uid"><strong>{{ translations.user.name }} *</strong></label>

          <div class="uk-form-controls">
            <input class="uk-input" :id="'receiver_name-' + uid" type="text" :placeholder="translations.user.name" v-model="receiverName">
            <div class="input-errors" v-if="errors.receiver_name">
              <p v-for="error in errors.receiver_name">{{ error }}</p>
            </div>
          </div>
        </div>

        <div class="uk-width-1-2@m">
          <label class="uk-form-label" :for="'email-' + uid"><strong>{{ translations.user.email }} *</strong></label>

          <div class="uk-form-controls">
            <input class="uk-input" :id="'email-' + uid" type="email" :placeholder="translations.user.email" v-model="receiverEmail">
            <div class="input-errors" v-if="errors.receiver_email">
              <p v-for="error in errors.receiver_email">{{ error }}</p>
            </div>
          </div>
        </div>

        <div v-if="addressReceiverVisible" class="uk-grid-medium" uk-grid>
            <div class="uk-width-1-2@m">
              <label class="uk-form-label" :for="'street-' + uid"><strong>{{ translations.address.street }} *</strong></label>

              <div class="uk-form-controls">
                <input class="uk-input" :id="'street-' + uid" type="text" :placeholder="translations.address.street" v-model="address.street">
                <div class="input-errors" v-if="errors['address.street']">
                  <p v-for="error in errors['address.street']">{{ error }}</p>
                </div>
              </div>
            </div>

            <div class="uk-width-1-6@m">
              <label class="uk-form-label" :for="'number-' + uid"><strong>{{ translations.address.housenr }} *</strong></label>

              <div class="uk-form-controls">
                <input class="uk-input" :id="'number-' + uid" type="text" :placeholder="translations.address.housenr" v-model="address.number">
                <div class="input-errors" v-if="errors['address.number']">
                  <p v-for="error in errors['address.number']">{{ error }}</p>
                </div>
              </div>
            </div>

            <div class="uk-width-1-6@m">
              <label class="uk-form-label uk-invisible" :for="'addition-' + uid"><strong>{{ translations.address.number_addon }} *</strong></label>

              <div class="uk-form-controls">
                <input class="uk-input" :id="'addition-' + uid" type="text" :placeholder="translations.address.number_addon" v-model="address.number_addon">
                <div class="input-errors" v-if="errors['address.number_addon']">
                  <p v-for="error in errors['address.number_addon']">{{ error }}</p>
                </div>
              </div>
            </div>

            <div class="uk-width-1-6@m">
              <label class="uk-form-label" :for="'bus-' + uid"><strong>{{ translations.address.busnr }}</strong></label>

              <div class="uk-form-controls">
                <input class="uk-input" :id="'bus-' + uid" type="text" :placeholder="translations.address.busnr" v-model="address.busnumber">
                <div class="input-errors" v-if="errors['address.busnumber']">
                  <p v-for="error in errors['address.busnumber']">{{ error }}</p>
                </div>
              </div>
            </div>

            <div class="uk-width-1-4@m">
              <label class="uk-form-label" :for="'postalcode-' + uid"><strong>{{ translations.address.postalcode }} *</strong></label>

              <div class="uk-form-controls">
                <input class="uk-input" :id="'postalcode-' + uid" type="text" :placeholder="translations.address.postalcode" v-model="address.postalcode">
                <div class="input-errors" v-if="errors['address.postalcode']">
                  <p v-for="error in errors['address.postalcode']">{{ error }}</p>
                </div>
              </div>
            </div>

            <div class="uk-width-1-2@m">
              <label class="uk-form-label" :for="'city-' + uid"><strong>{{ translations.address.commune }} *</strong></label>

              <div class="uk-form-controls">
                <input class="uk-input" :id="'city-' + uid" type="text" :placeholder="translations.address.commune" v-model="address.commune">
                <div class="input-errors" v-if="errors['address.commune']">
                  <p v-for="error in errors['address.commune']">{{ error }}</p>
                </div>
              </div>
            </div>

            <div class="uk-width-1-4@m">
              <label class="uk-form-label" :for="'country-' + uid"><strong>{{ translations.address.country }} *</strong></label>

              <div class="uk-form-controls">
                <select class="custom-select uk-width-1-1" :id="'country-' + uid" v-model="address.country">
                  <option v-for="country in countries" :value="country.id">{{ country.name }}</option>
                </select>
                <div class="input-errors" v-if="errors['address.country']">
                  <p v-for="error in errors['address.country']">{{ error }}</p>
                </div>
              </div>
            </div>
        </div>
    </div>

    <div class="uk-width-1-1 pt-5">
      <h2 class="my-0 me-4 d-inline-block">{{ translations.data_cart }}</h2>
    </div>

    <div class="uk-width-1-1@m">
      <label class="uk-form-label" :for="'headline-' + uid"><strong>{{ translations.cart.title }}</strong></label>

      <div class="uk-form-controls">
        <input class="uk-input" :id="'headline-' + uid" type="text" :placeholder="translations.cart.title" v-model="giftCardTitle">
        <div class="input-errors" v-if="errors.giftcard_title">
          <p v-for="error in errors.giftcard_title">{{ error }}</p>
        </div>
      </div>
    </div>

    <div class="uk-width-1-1@m">
      <label class="uk-form-label" :for="'body-' + uid"><strong>{{ translations.cart.text }}</strong></label>

      <div class="uk-form-controls">
        <textarea class="uk-textarea" :id="'body-' + uid" :placeholder="translations.cart.text" v-model="giftCardBody"></textarea>
        <div class="input-errors" v-if="errors.giftcard_body">
          <p v-for="error in errors.giftcard_body">{{ error }}</p>
        </div>
      </div>
    </div>

    <div class="uk-width-1-1 pt-sm-5">
      <button class="uk-button uk-button-primary" @click.prevent="handleOrder">
          <div class="loader" v-if="handleLoading"><i class="fa-solid fa-circle-notch fa-spin me-2"></i></div>
          {{ translations.add_to_cart }}
      </button>
    </div>
  </form>
</template>

<script>
    import GiftcardFormAmountItem from "./GiftcardFormAmountItem";
    import {EventBus} from "../EventBus";
    import collect from 'collect.js';

    export default {
        name: "GiftcardForm",
        components: {GiftcardFormAmountItem},
        data () {
            return {
                uid: this._uid,
                chosenGiftcard: this.initChosenGiftcard(),
                receiveType: this.orderLine?.extra?.receive_type ?? '',
                deliveryType: this.orderLine?.extra?.delivery_type ?? '',
                senderName: this.orderLine?.extra?.sender_name ?? '',
                receiverName: this.orderLine?.extra?.receiver_name ?? '',
                receiverEmail: this.orderLine?.extra?.receiver_email ?? '',
                address: {
                  street: this.orderLine?.extra?.address?.street ?? '',
                  number: this.orderLine?.extra?.address?.number ?? '',
                  number_addon: this.orderLine?.extra?.address?.number_addon,
                  busnumber: this.orderLine?.extra?.address?.busnumber,
                  postalcode: this.orderLine?.extra?.address?.postalcode,
                  commune: this.orderLine?.extra?.address?.commune,
                  country: this.orderLine?.extra?.address?.country ?? ''
                },
                giftCardTitle: this.orderLine?.extra?.address?.giftcard_title ?? '',
                giftCardBody: this.orderLine?.extra?.address?.giftcard_body ?? '',
                handleLoading: false,
                variablePrice: null,
                errors: {},
                physicalDisabled: this.setPhysicalDisabled(this.initChosenGiftcard()),
                digitalDisabled: this.setDigitalDisabled(this.initChosenGiftcard())
            }
        },
        methods: {
            handleOrder() {
                this.handleLoading = true;

                let chosenPrice = null;
                if(this.chosenGiftcard?.giftcard_type === "fixed") {
                    chosenPrice = this.chosenGiftcard.price / 100;
                } else if(this.chosenGiftcard?.giftcard_type === "variable") {
                    chosenPrice = this.variablePrice;
                }

                let data = {
                    product_id: this.chosenGiftcard?.id,
                    receive_type: this.receiveType,
                    delivery_type: this.deliveryType,
                    sender_name: this.senderName,
                    receiver_name: this.receiverName,
                    receiver_email: this.receiverEmail,
                    address: this.address,
                    giftcard_title: this.giftCardTitle,
                    giftcard_body: this.giftCardBody,
                    quantity: 1,
                    price: chosenPrice
                };

                data.address.country_name = collect(this?.countries ?? [])
                    .first(c => {
                      return c.id == data?.address?.country ?? ''
                    })?.name ?? ''
                window.axios.post(this.routes.saveGiftcard, data)
                    .then(res => {
                        this.errors = {};

                        EventBus.$emit('CART_UPDATE', res.data.cart);
                        EventBus.$emit('PRODUCT_ADDED_MODAL', {product: this.chosenGiftcard, quantity: 1, price: chosenPrice});

                        this.setInitValues();

                        this.handleLoading = false;
                    })
                    .catch(err => {
                        this.errors = err?.response?.data;

                        EventBus.$emit('NOTIFY', {message: this.translations.incorrect_fields, status: 'danger', position: 'top-right', 'timeout': 3000});

                        this.handleLoading = false;
                    });

            },
            setPhysicalDisabled(giftcard) {
                if(giftcard?.extra == null || !giftcard?.extra?.physical){
                    if(this.receiveType === "physical") {
                        this.receiveType = null;
                    }
                    return false;
                }

                return true;
            },
            setDigitalDisabled(giftcard) {
                if(giftcard?.extra == null || !giftcard?.extra?.digital){
                    if(this.receiveType === "digital") {
                        this.receiveType = null;
                    }
                    return false;
                }

                return true;
            },
            setInitValues() {
                this.chosenGiftcard = this.giftcards[0];
                this.receiveType = null;
                this.deliveryType = null;
                this.senderName = null;
                this.receiverName = null;
                this.receiverEmail = null;
                this.address = {
                    street: '',
                    number: '',
                    number_addon: '',
                    busnumber: '',
                    postalcode: '',
                    commune: '',
                    country: ''
                };
                this.giftCardTitle = '';
                this.giftCardBody = '';
                this.handleLoading = false;
                this.variablePrice = null;
                this.errors = {};
                this.physicalDisabled = this.setPhysicalDisabled(this.giftcards[0]);
                this.digitalDisabled = this.setDigitalDisabled(this.giftcards[0]);
            },
            initChosenGiftcard() {
                if(this.orderLine?.product != null)
                {
                    let initGiftcardsMapped = this.giftcards.map(function(x) { return x.id; });
                    return this.giftcards[initGiftcardsMapped.indexOf(this.orderLine.product.id)];
                }

                return this.giftcards[0];
            }
        },
        computed: {
            variablePriceVisible() {
                return this.chosenGiftcard != null && this.chosenGiftcard?.giftcard_type === "variable";
            },
            dataReceiverVisible() {
                return this.deliveryType === "to_other";
            },
            addressReceiverVisible() {
                return this.dataReceiverVisible && this.receiveType === "physical";
            },
        },
        watch: {
            chosenGiftcard: function(val, oldVal){
                this.digitalDisabled = this.setDigitalDisabled(this.chosenGiftcard);
                this.physicalDisabled = this.setPhysicalDisabled(this.chosenGiftcard);
            }
        },
        props: {
            translations: {
                type: Object|Array,
                required: true
            },
            giftcards: {
                type: Object|Array,
                required: true
            },
            countries: {
                type: Object|Array,
                required: true
            },
            routes: {
                type: Object|Array,
                required: true
            },
            orderLine: {
                type: Object|Array,
                required: false,
                default: function(){return {};}
            }
        }
    }
</script>

<style scoped>

</style>