<template>
    <li class="mb-2">
        <label class="custom-checkbox" v-bind:class="{'disabled': disabled}">
            <input class="uk-checkbox me-2 mt-1" name="brand" type="checkbox" v-model="checked" @change="$emit('input', checked);" v-bind:disabled="disabled">
            <div class="d-flex justify-content-between align-items-center">
                <p class="my-0">{{ brand.name }}</p>
                <p class="my-0 text--small text--grey-dark">({{ brand.products_count }})</p>
            </div>
        </label>
    </li>
</template>

<script>
export default {
    name: "FilterSidebarBrandsItem",
    mounted() {
        
    },
    data() {
        return {
            checked: this.defaultChecked
        }
    },
    methods: {
    },
    computed: {
      disabled: function(){
        if(this.brand.products_count === 0 && !this.checked){
          return true;
        }
        return false;
      }
    },
    props: {
        brand: {
            type: Object|Array,
            required: true
        },
        defaultChecked: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style scoped>
</style>