<template>
    <div>
        <button @click.prevent="toggleWishlist" :title="product.name" class="c-product">
            <span class="c-product__header">
                <p class="c-product__line">{{ product.filter.name }}</p>
                <p class="c-product__title">{{ product.name }}</p>
                <p class="c-product__nr">{{ product.sku }}</p>
            </span>

            <img class="c-product__image" :src="imageUrl" :alt="product.name">

            <!-- checked: {{checked}} <br>
            product.in_wishlist: {{product.in_wishlist}} <br> -->

            <span v-if="!checked" class="c-product__add">
                <i class="fa-regular fa-plus"></i>
            </span>
            <span v-else class="c-product__remove">
                <i class="fa-regular fa-trash-can"></i>
            </span>

            <!-- <wishlist-button
                :in-wishlist="product.in_wishlist"
                :product-id="product.id"
                :routes="routes"
            ></wishlist-button>  -->
        </button>
    </div>
</template>

<script>
    import { EventBus } from "../EventBus";

    export default {
        name: "ProductCard",
        data(){
            return {
                isLoading: false,
                checked: this.product.in_wishlist
            }
        },
        methods: {
            toggleWishlist()
            {
                window.axios.post(this.routes.wishlist.toggleWishlist, {product_id: this.product.id})
                    .then(res => {
                        this.checked = !this.checked;

                        if (res?.data?.message) {
                            EventBus.$emit('NOTIFY', res.data.message);
                        }

                        if (res?.data?.products) {
                            EventBus.$emit('WISHLIST_UPDATE', res.data.products);
                        }
                    })
                    .catch(error => {
                        if (error?.response?.message) {
                            EventBus.$emit('NOTIFY', error.response.data.message);
                        }
                    });
            },
        },
        computed: {
          imageUrl() {
            return this.product?.image;
          },
        },
        props: {
            product: {
                type: Object|Array,
                required: true
            },
            routes: {
              type: Object|Array,
              required: true
            },
            translations: {
              type: Object|Array,
              required: true
            }
        }
    }
</script>

<style scoped>
    
</style>
