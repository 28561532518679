var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.deliveryTime
    ? _c("div", { staticClass: "product__stock" }, [
        _vm.deliveryTime.icon
          ? _c(
              "div",
              { staticClass: "icon", style: "color:" + _vm.deliveryTime.color },
              [_c("i", { class: _vm.deliveryTime.icon })]
            )
          : _vm._e(),
        _vm._v(" "),
        _c("p", { style: "color:" + _vm.deliveryTime.color }, [
          _vm._v(_vm._s(_vm.deliveryTime.text)),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }