var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "uk-grid-medium", attrs: { "uk-grid": "" } },
    _vm._l(_vm.categories, function (category) {
      return _c("sub-category-item", {
        key: category.id,
        attrs: { category: category },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }