<template>
    <li class="mb-3">
        <label class="custom-checkbox custom-checkbox--big" v-bind:class="{'disabled': disabled}">
            <input class="uk-checkbox me-3" :name="labelid" v-model="checked" @change="$emit('input', checked)" type="checkbox" v-bind:disabled="disabled">
            <div class="d-flex justify-content-between align-items-center">
                <p class="my-0">{{ filter.name }}</p>
                <p class="my-0 text--small text--grey-dark"></p>
            </div>
        </label>
    </li>
    
</template>

<script>
export default {
    name: 'FilterSidebarDynamicFiltersChecksItem',
    mounted() {
        
    },
    data() {
        return {
            checked: this.defaultChecked
        }
    },
    methods: {
        
    },
  computed: {
    disabled: function(){
        if(this.filter.products_count === 0 && !this.checked){
          return true;
        }
        return false;
      }
    },
    props: {
        filter: {
            type: Object|Array,
            required: true
        },
        labelid: {
            type: Number,
            required: true
        },
        defaultChecked: {
            type: Boolean,
            required: true
        }
    }
}
</script>

<style scoped>
</style>