<template>
    <ul class="sidebar__nav">
        <filter-sidebar-dynamic-filters-checks-item
            v-for="filtervalue in filter.values"
            v-model="localActiveFilters[filtervalue.id]"
            :filter="filtervalue"
            :labelid="filter.id"
            :key="filtervalue.id"
            @input="handleChange"
            :default-checked="localActiveFilters[filtervalue.id] || false"
        >
        </filter-sidebar-dynamic-filters-checks-item>
    </ul>
</template>

<script>
    export default {
        name: "FilterSidebarDynamicFiltersChecks",
        mounted() {
            
        },
        data() {
            return {
                localActiveFilters: this.activeFilters
            }
        },
        methods: {
            handleChange(){
                this.$emit('input', this.localActiveFilters);
            }
        },
        props: {
            filter: {
                type: Object|Array,
                required: true
            },
            activeFilters: {
                type: Object|Array,
                required: true
            }
        }
    }
</script>

<style scoped>
</style>