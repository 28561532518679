<template>
    <div class="h-mt--50">
        <div class="filter-top">
                <a :href="categoryItem.slug" for="filter-checkbox-1" class="filter-checkbox" :class="{ active: renderActiveState(categoryItem) }" v-for="categoryItem in renderedList">
                    <input id="filter-checkbox-1" name="filter-checkbox-1" type="checkbox" class="uk-checkbox">
                    <span>{{categoryItem.name}}</span>
                </a>
        </div>
    </div>
</template>
<script>
export default {
    name: "FilterTop",
    mounted() {
    },
    data() {
        return {
        }
    },
    methods:{
        renderActiveState(category){
            for(let i = 0;i < this.categories.length; i++ ) {
                if(category.id == (this.category ? this.category.id : '')) {
                    return true
                } else {
                    return false
                }
            }

        }
    },
    computed: {
        renderedList() {

            return this.categories
           
            // if(this.category && this.category_parent) {
            //     let categoriesParent;
            //         categoriesParent = this.category_parent
                
            //     return this.categories.filter(function(cat){
            //         if(cat.parent) {
            //             return cat.parent.id == categoriesParent.id;
            //         }
                    

            //         return false
            //     })
            // } else {
            //     return this.categories
            // }
            

            // var list = [];
            // if (Array.isArray(this.categories)) {
            //     for(var i=0; i<this.categories.length; i++){
            //         return this.categories[i]
            //     }
                
            // }
        },
      
    },
    props: {
            category: {
                type: Object|Array,
                required: false
            },
            category_parent: {
                type: Object|Array,
                required: false
            },
            categories: {
                type: Object|Array,
                required: true
            },
            translations: {
                type: Object|Array,
                required: true
            },
            routes: {
                type: Object|Array,
                required: true
            }
    }
}
</script>

<style scoped>
    
</style>