var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "formSection" }, [
    _c(
      "form",
      { ref: "form", staticClass: "uk-form-stacked form-custom" },
      [
        _vm._l(_vm.messages, function (message) {
          return _c(
            "div",
            {
              staticClass: "h-mb--40",
              class: "uk-alert-" + message.type,
              attrs: { "uk-alert": "", "uk-alert": "" },
            },
            [
              _c("a", {
                staticClass: "uk-alert-close",
                attrs: { "uk-close": "" },
              }),
              _vm._v(" "),
              _c("p", [_vm._v(_vm._s(message.message))]),
            ]
          )
        }),
        _vm._v(" "),
        !_vm.hideForm
          ? _c("div", [
              _c("fieldset", {}, [
                _c("legend", [_vm._v(_vm._s(_vm.translations.interested_in))]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-md-7" }, [
                    _c(
                      "div",
                      {
                        staticClass: "uk-grid-small",
                        attrs: { "uk-grid": "" },
                      },
                      _vm._l(_vm.interests, function (interest) {
                        return _c(
                          "div",
                          { staticClass: "uk-width-1-2@s uk-width-1-3@m" },
                          [
                            _c(
                              "label",
                              {
                                staticClass:
                                  "custom-checkbox custom-checkbox--big",
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.interestsChecked,
                                      expression: "interestsChecked",
                                    },
                                  ],
                                  staticClass: "uk-checkbox",
                                  attrs: { type: "checkbox", id: interest },
                                  domProps: {
                                    value: interest,
                                    checked: Array.isArray(_vm.interestsChecked)
                                      ? _vm._i(_vm.interestsChecked, interest) >
                                        -1
                                      : _vm.interestsChecked,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a = _vm.interestsChecked,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = interest,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            (_vm.interestsChecked = $$a.concat([
                                              $$v,
                                            ]))
                                        } else {
                                          $$i > -1 &&
                                            (_vm.interestsChecked = $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1)))
                                        }
                                      } else {
                                        _vm.interestsChecked = $$c
                                      }
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c("p", { staticClass: "my-0" }, [
                                  _vm._v(_vm._s(interest)),
                                ]),
                              ]
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("fieldset", { staticClass: "h-mt--60" }, [
                _c("legend", [
                  _vm._v(_vm._s(_vm.translations.your_brushes) + " "),
                  _c("span", [
                    _vm._v("(" + _vm._s(_vm.translations.optional) + ")"),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "uk-grid-small", attrs: { "uk-grid": "" } },
                  _vm._l(_vm.productsSend, function (product, index) {
                    return _c(
                      "div",
                      { key: product.id, staticClass: "uk-width-1-2@m" },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "c-product-small",
                            attrs: { title: product.name },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.removeProduct(index)
                              },
                            },
                          },
                          [
                            _c("img", {
                              staticClass: "c-product-small__image",
                              attrs: { src: product.image },
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticClass: "c-product-small__middel" },
                              [
                                _c(
                                  "p",
                                  { staticClass: "c-product-small__title" },
                                  [_vm._v(_vm._s(product.name))]
                                ),
                                _vm._v(" "),
                                _c(
                                  "p",
                                  { staticClass: "c-product-small__nr" },
                                  [_vm._v("beo_02021")]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm._m(0, true),
                          ]
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ]),
              _vm._v(" "),
              _c("fieldset", { staticClass: "h-mt--60" }, [
                _c("legend", [_vm._v(_vm._s(_vm.translations.comments_title))]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "uk-form-group uk-width-3-5@m" },
                  [
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.comments,
                          expression: "comments",
                        },
                      ],
                      staticClass: "uk-textarea",
                      class: { "uk-form-danger": _vm.errors.comments },
                      attrs: {
                        name: "comments",
                        id: "comments",
                        cols: "30",
                        rows: "4",
                        placeholder: _vm.translations.comments_placeholder,
                      },
                      domProps: { value: _vm.comments },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.comments = $event.target.value
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm._l(_vm.errors.comments, function (error) {
                      return _vm.errors.comments
                        ? _c("p", { staticClass: "uk-text-danger mt-1" }, [
                            _vm._v(_vm._s(error)),
                          ])
                        : _vm._e()
                    }),
                  ],
                  2
                ),
              ]),
              _vm._v(" "),
              _c("fieldset", { staticClass: "h-mt--60" }, [
                _c("legend", [_vm._v(_vm._s(_vm.translations.your_data))]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "uk-grid-small", attrs: { "uk-grid": "" } },
                  [
                    _c(
                      "div",
                      { staticClass: "uk-form-group uk-width-2-5@s" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "uk-form-label",
                            class: { "uk-text-danger": _vm.errors.firstname },
                            attrs: { for: "firstname" },
                          },
                          [_vm._v(_vm._s(_vm.translations.firstname))]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.firstname,
                              expression: "firstname",
                            },
                          ],
                          staticClass: "uk-input uk-form-control",
                          class: { "uk-form-danger": _vm.errors.firstname },
                          attrs: {
                            type: "text",
                            id: "firstname",
                            name: "firstname",
                          },
                          domProps: { value: _vm.firstname },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.firstname = $event.target.value
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm._l(_vm.errors.firstname, function (error) {
                          return _vm.errors.firstname
                            ? _c("p", { staticClass: "uk-text-danger mt-1" }, [
                                _vm._v(_vm._s(error)),
                              ])
                            : _vm._e()
                        }),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "uk-form-group uk-width-2-5@s" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "uk-form-label",
                            class: { "uk-text-danger": _vm.errors.lastname },
                            attrs: { for: "lastname" },
                          },
                          [_vm._v(_vm._s(_vm.translations.lastname))]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.lastname,
                              expression: "lastname",
                            },
                          ],
                          staticClass: "uk-input uk-form-control",
                          class: { "uk-form-danger": _vm.errors.lastname },
                          attrs: {
                            type: "text",
                            id: "lastname",
                            name: "lastname",
                          },
                          domProps: { value: _vm.lastname },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.lastname = $event.target.value
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm._l(_vm.errors.lastname, function (error) {
                          return _vm.errors.lastname
                            ? _c("p", { staticClass: "uk-text-danger mt-1" }, [
                                _vm._v(_vm._s(error)),
                              ])
                            : _vm._e()
                        }),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "uk-form-group uk-width-2-5@s" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "uk-form-label",
                            class: { "uk-text-danger": _vm.errors.company },
                            attrs: { for: "company" },
                          },
                          [
                            _vm._v(_vm._s(_vm.translations.company) + " "),
                            _c("span", [
                              _vm._v(
                                "(" + _vm._s(_vm.translations.optional) + ")"
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.company,
                              expression: "company",
                            },
                          ],
                          staticClass: "uk-input uk-form-control",
                          class: { "uk-form-danger": _vm.errors.company },
                          attrs: {
                            type: "text",
                            id: "company",
                            name: "company",
                          },
                          domProps: { value: _vm.company },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.company = $event.target.value
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm._l(_vm.errors.company, function (error) {
                          return _vm.errors.company
                            ? _c("p", { staticClass: "uk-text-danger mt-1" }, [
                                _vm._v(_vm._s(error)),
                              ])
                            : _vm._e()
                        }),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "uk-form-group uk-width-2-5@s" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "uk-form-label",
                            class: { "uk-text-danger": _vm.errors.tax },
                            attrs: { for: "tax" },
                          },
                          [
                            _vm._v(_vm._s(_vm.translations.tax) + " "),
                            _c("span", [
                              _vm._v(
                                "(" + _vm._s(_vm.translations.optional) + ")"
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.tax,
                              expression: "tax",
                            },
                          ],
                          staticClass: "uk-input uk-form-control",
                          class: { "uk-form-danger": _vm.errors.tax },
                          attrs: { type: "text", id: "tax", name: "tax" },
                          domProps: { value: _vm.tax },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.tax = $event.target.value
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm._l(_vm.errors.tax, function (error) {
                          return _vm.errors.tax
                            ? _c("p", { staticClass: "uk-text-danger mt-1" }, [
                                _vm._v(_vm._s(error)),
                              ])
                            : _vm._e()
                        }),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "uk-form-group uk-width-2-5@s" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "uk-form-label",
                            class: { "uk-text-danger": _vm.errors.email },
                            attrs: { for: "email" },
                          },
                          [_vm._v(_vm._s(_vm.translations.email))]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.email,
                              expression: "email",
                            },
                          ],
                          staticClass: "uk-input uk-form-control",
                          class: { "uk-form-danger": _vm.errors.email },
                          attrs: { type: "email", id: "email", name: "email" },
                          domProps: { value: _vm.email },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.email = $event.target.value
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm._l(_vm.errors.email, function (error) {
                          return _vm.errors.email
                            ? _c("p", { staticClass: "uk-text-danger mt-1" }, [
                                _vm._v(_vm._s(error)),
                              ])
                            : _vm._e()
                        }),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "uk-form-group uk-width-2-5@s" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "uk-form-label",
                            class: { "uk-text-danger": _vm.errors.phone },
                            attrs: { for: "phone" },
                          },
                          [_vm._v(_vm._s(_vm.translations.phone))]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.phone,
                              expression: "phone",
                            },
                          ],
                          staticClass: "uk-input uk-form-control",
                          class: { "uk-form-danger": _vm.errors.phone },
                          attrs: { type: "text", id: "phone", name: "phone" },
                          domProps: { value: _vm.phone },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.phone = $event.target.value
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm._l(_vm.errors.phone, function (error) {
                          return _vm.errors.phone
                            ? _c("p", { staticClass: "uk-text-danger mt-1" }, [
                                _vm._v(_vm._s(error)),
                              ])
                            : _vm._e()
                        }),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "uk-form-group uk-width-4-5 form-url",
                        attrs: { id: "url_wrapper" },
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "uk-form-label",
                            attrs: { for: "url" },
                          },
                          [_vm._v("Url")]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.url,
                              expression: "url",
                            },
                          ],
                          staticClass: "uk-input uk-form-control",
                          attrs: {
                            id: "url",
                            name: "url",
                            type: "text",
                            placeholder: "Url",
                          },
                          domProps: { value: _vm.url },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.url = $event.target.value
                            },
                          },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "uk-form-group uk-width-4-5 form-woonplaats",
                        attrs: { id: "woonplaats_wrapper" },
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "uk-form-label",
                            attrs: { for: "woonplaats" },
                          },
                          [_vm._v("woonplaats")]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.woondplaats,
                              expression: "woondplaats",
                            },
                          ],
                          staticClass: "uk-input uk-form-control",
                          attrs: {
                            id: "woonplaats",
                            name: "woonplaats",
                            type: "text",
                            placeholder: "woonplaats",
                            autocomplete: "off",
                          },
                          domProps: { value: _vm.woondplaats },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.woondplaats = $event.target.value
                            },
                          },
                        }),
                      ]
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("fieldset", { staticClass: "h-mt--60" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex flex-column align-items-start justify-content-between",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "uk-form-group uk-width-4-5@s uk-width-3-5@m",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "custom-checkbox",
                            class: { "uk-form-danger": _vm.errors.privacy },
                            attrs: { for: "privacy" },
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.privacy,
                                  expression: "privacy",
                                },
                              ],
                              staticClass: "uk-checkbox",
                              attrs: {
                                id: "privacy",
                                name: "privacy",
                                type: "checkbox",
                              },
                              domProps: {
                                checked: Array.isArray(_vm.privacy)
                                  ? _vm._i(_vm.privacy, null) > -1
                                  : _vm.privacy,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.privacy,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.privacy = $$a.concat([$$v]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.privacy = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.privacy = $$c
                                  }
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "t-cms t-text--small" }, [
                              _c("p", [
                                _vm._v(
                                  _vm._s(_vm.translations.i_agree_with) + " "
                                ),
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: _vm.routes.privacy_page,
                                      target: "_blank",
                                      title: _vm.translations.privacy_policy,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.translations.privacy_policy)
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _vm._l(_vm.errors.privacy, function (error) {
                          return _vm.errors.privacy
                            ? _c("p", { staticClass: "uk-text-danger mt-1" }, [
                                _vm._v(_vm._s(error)),
                              ])
                            : _vm._e()
                        }),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "uk-button uk-button-primary mt-4 ",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.handleFormSubmit.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.translations.submit))]
                    ),
                  ]
                ),
              ]),
            ])
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "c-product-small__remove" }, [
      _c("i", { staticClass: "fa-regular fa-trash-alt" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }