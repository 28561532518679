var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    { staticClass: "has-icon", attrs: { href: _vm.routes.overview } },
    [_vm._m(0), _vm._v(" " + _vm._s(_vm.translations) + "\n")]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [_c("i", { staticClass: "fa-regular fa-plus" })])
  },
]
render._withStripped = true

export { render, staticRenderFns }