<template>
  <form class="form-custom uk-grid-small" uk-grid>
    <h3 class="mt-5 uk-text-primary">{{ translations.delivery_information }}</h3>

    <div class="uk-width-1-1 mb-3">
      <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid">
        <p><span class="uk-text-bold">{{ translations.salutation.self }}</span></p>

        <label class="custom-radio">
          <input class="uk-radio me-2" type="radio" name="gender" value="male" v-model="salutation">
          <p class="my-0">{{ translations.salutation.male }}</p>
        </label>

        <label class="custom-radio">
          <input class="uk-radio me-2" type="radio" name="gender" value="female" v-model="salutation">
          <p class="my-0">{{ translations.salutation.female }}</p>
        </label>
      </div>
      <div class="input-errors" v-if="errors.salutation">
        <p v-for="error in errors.salutation">{{ error }}</p>
      </div>
    </div>

    <div class="uk-width-1-2@s">
      <label class="uk-form-label" for="firstname">{{ translations.firstname }} *</label>
      <div class="uk-form-controls">
        <input class="uk-input" id="firstname" type="text" :placeholder="translations.firstname" v-model="firstname">
        <div class="input-errors" v-if="errors.firstname">
          <p v-for="error in errors.firstname">{{ error }}</p>
        </div>
      </div>
    </div>

    <div class="uk-width-1-2@s">
      <label class="uk-form-label" for="lastname">{{ translations.lastname }} *</label>
      <div class="uk-form-controls">
        <input class="uk-input" id="lastname" type="text" :placeholder="translations.lastname" v-model="lastname">
        <div class="input-errors" v-if="errors.lastname">
          <p v-for="error in errors.lastname">{{ error }}</p>
        </div>
      </div>
    </div>

    <div class="uk-width-1-2@s">
      <label class="uk-form-label" for="companyname_delivery">{{ translations.company.companyname }}</label>
      <div class="uk-form-controls">
        <input class="uk-input" id="companyname_delivery" type="text" :placeholder="translations.company.companyname" v-model="companyname_delivery">
        <div class="input-errors" v-if="errors.companyname_delivery">
          <p v-for="error in errors.companyname_delivery">{{ error }}</p>
        </div>
      </div>
    </div>

    <div class="uk-width-1-1" v-if="deliveryAddressRequired">
      <label class="uk-form-label">{{ translations.address.delivery_address }} *</label>
        <div class="input-errors" v-if="errors.delivery_address">
          <p v-for="error in errors.delivery_address">{{ error }}</p>
        </div>

      <div class="delivery" v-if="Object.keys(user).length > 0 && Object.keys(user.addresses).length > 0">
        <address-card
            v-for="address in user.addresses"
            :init-address="address"
            :active-address="active_delivery"
            :key="address.id"
            :type="'delivery'"
            v-on:addressPick="addressPick"
        ></address-card>

        <button class="delivery__new" v-bind:class="{ active: newDeliveryAddressActive }" @click.prevent="handleNewAddress('delivery')">
          <i class="fa-solid fa-plus me-2"></i>
          <span>{{ translations.address.new }}</span>
        </button>
      </div>

      <div id="new-delivery" class="uk-grid-small uk-grid" uk-grid v-bind:class="{ 'd-none' : !newDeliveryAddressActive }">
        <div class="uk-width-1-2@s">
          <label class="uk-form-label" for="new_delivery_street">{{ translations.address.streetname }} *</label>
          <div class="uk-form-controls">
            <input class="uk-input" id="new_delivery_street" type="text" :placeholder="translations.address.streetname" v-model="new_delivery_address.street">
            <div class="input-errors" v-if="errors['new_delivery_address.street']">
              <p v-for="error in errors['new_delivery_address.street']">{{ error }}</p>
            </div>
          </div>
        </div>

        <div class="uk-width-1-6@s">
          <label class="uk-form-label" for="new_delivery_housenr">{{ translations.address.housenr }} *</label>
          <div class="uk-form-controls">
            <input class="uk-input" id="new_delivery_housenr" type="text" :placeholder="translations.address.housenr" v-model="new_delivery_address.number">
            <div class="input-errors" v-if="errors['new_delivery_address.number']">
              <p v-for="error in errors['new_delivery_address.number']">{{ error }}</p>
            </div>
          </div>
        </div>

        <div class="uk-width-1-6@s">
          <label class="uk-form-label" for="new_delivery_housenr_addon">{{ translations.address.number_addon_short }}</label>
          <div class="uk-form-controls">
            <input class="uk-input" id="new_delivery_housenr_addon" type="text" :placeholder="translations.address.number_addon_short" v-model="new_delivery_address.number_addon">
            <div class="input-errors" v-if="errors['new_delivery_address.number_addon']">
              <p v-for="error in errors['new_delivery_address.number_addon']">{{ error }}</p>
            </div>
          </div>
        </div>

        <div class="uk-width-1-6@s">
          <label class="uk-form-label" for="new_delivery_busnr">{{ translations.address.busnr }}</label>
          <div class="uk-form-controls">
            <input class="uk-input" id="new_delivery_busnr" type="text" :placeholder="translations.address.busnr" v-model="new_delivery_address.busnumber">
            <div class="input-errors" v-if="errors['new_delivery_address.busnumber']">
              <p v-for="error in errors['new_delivery_address.busnumber']">{{ error }}</p>
            </div>
          </div>
        </div>

        <div class="uk-width-1-4@s">
          <label class="uk-form-label" for="new_delivery_postalcode">{{ translations.address.postalcode }} *</label>
          <div class="uk-form-controls">
            <input class="uk-input" id="new_delivery_postalcode" type="text" :placeholder="translations.address.postalcode" v-model="new_delivery_address.postalcode">
            <div class="input-errors" v-if="errors['new_delivery_address.postalcode']">
              <p v-for="error in errors['new_delivery_address.postalcode']">{{ error }}</p>
            </div>
          </div>
        </div>

        <div class="uk-width-1-2@s">
          <label class="uk-form-label" for="new_delivery_commune">{{ translations.address.commune }} *</label>
          <div class="uk-form-controls">
            <input class="uk-input" id="new_delivery_commune" type="text" :placeholder="translations.address.commune" v-model="new_delivery_address.commune">
            <div class="input-errors" v-if="errors['new_delivery_address.commune']">
              <p v-for="error in errors['new_delivery_address.commune']">{{ error }}</p>
            </div>
          </div>
        </div>

        <div class="uk-width-1-4@s">
          <label class="uk-form-label" for="new_delivery_country">{{ translations.address.country }} *</label>
          <div class="uk-form-controls">
            <select name="country" id="new_delivery_country" class="custom-select uk-width-1-1" v-model="new_delivery_address.country">
              <option :value="country.id" v-for="country in countries">{{ country.name }}</option>
            </select>
          </div>
          <div class="input-errors" v-if="errors['new_delivery_address.country']">
            <p v-for="error in errors['new_delivery_address.country']">{{ error }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="uk-width-1-1 mt-4 mb-3">
      <h3 class="mt-5 uk-text-primary">{{ translations.facturation_data }}</h3>
    </div>

    <div class="uk-width-1-1 mb-3">
      <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid">
        <p><span class="uk-text-bold">{{ translations.order_type }}</span></p>

        <label class="custom-radio">
          <input class="uk-radio me-2" type="radio" name="order_type" value="private" v-model="order_type">
          <p class="my-0">{{ translations.account_type.private }}</p>
        </label>

        <label class="custom-radio">
          <input class="uk-radio me-2" type="radio" name="order_type" value="business" v-model="order_type">
          <p class="my-0">{{ translations.account_type.company }}</p>
        </label>
      </div>
      <div class="input-errors" v-if="errors.order_type">
        <p v-for="error in errors.order_type">{{ error }}</p>
      </div>
    </div>

    <div class="uk-width-1-2@s" v-if="order_type === 'business'">
      <label class="uk-form-label" for="companyname">{{ translations.company.companyname }} *</label>
      <div class="uk-form-controls">
        <input class="uk-input" id="companyname" type="text" :placeholder="translations.company.companyname" v-model="companyname">
        <div class="input-errors" v-if="errors.companyname">
          <p v-for="error in errors.companyname">{{ error }}</p>
        </div>
      </div>
    </div>

    <div class="uk-width-1-4@s" v-if="order_type === 'business'">
      <label class="uk-form-label">{{ translations.company.type }} *</label>
      <div class="uk-form-controls">
        <select name="company-type" class="custom-select uk-width-1-1" v-model="company_type">
          <option v-for="legalForm in legalForms" :value="legalForm">{{ legalForm }}</option>
        </select>
      </div>
      <div class="input-errors" v-if="errors.company_type">
        <p v-for="error in errors.company_type">{{ error }}</p>
      </div>
    </div>

    <div class="uk-width-1-4@s" v-if="order_type === 'business'">
      <label class="uk-form-label" for="companynumber">{{ translations.company.companynumber }} *</label>
      <div class="uk-form-controls">
        <input class="uk-input" id="companynumber" type="text" :placeholder="translations.company.companynumber" v-model="company_vat">
        <div class="input-errors" v-if="errors.company_vat">
          <p v-for="error in errors.company_vat">{{ error }}</p>
        </div>
      </div>
    </div>

    <div class="uk-width-1-1 mt-4">
      <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid">
        <p><span class="uk-text-bold">{{ translations.address.facturation }} *</span></p>

        <label class="custom-checkbox"  v-if="deliveryAddressRequired">
          <input class="uk-checkbox me-2 mt-1" type="checkbox" v-model="facturation_same_delivery">
          <p class="my-0">{{ translations.address.same_as_delivery }}</p>
        </label>
        <div class="input-errors" v-if="errors.facturation_same_delivery && deliveryAddressRequired">
          <p v-for="error in errors.facturation_same_delivery">{{ error }}</p>
        </div>
      </div>
    </div>

    <div class="uk-width-1-1" v-if="!facturation_same_delivery">
      <div class="input-errors" v-if="errors.billing_address">
        <p v-for="error in errors.billing_address">{{ error }}</p>
      </div>

      <div class="delivery" v-if="Object.keys(user).length > 0 && Object.keys(user.addresses).length > 0">
        <address-card
            v-for="address in user.addresses"
            :init-address="address"
            :active-address="active_billing"
            :key="'billing-' + address.id"
            :type="'billing'"
            v-on:addressPick="addressPick"
        ></address-card>

        <button class="delivery__new" v-bind:class="{ active: newBillingAddressActive }" @click.prevent="handleNewAddress('billing')">
          <i class="fa-solid fa-plus me-2"></i>
          <span>{{ translations.address.new }}</span>
        </button>
      </div>

      <div id="new-billing" class="uk-grid-small uk-grid" uk-grid v-bind:class="{ 'd-none' : !newBillingAddressActive }">
        <div class="uk-width-1-2@s">
          <label class="uk-form-label" for="new_billing_street">{{ translations.address.streetname }} *</label>
          <div class="uk-form-controls">
            <input class="uk-input" id="new_billing_street" type="text" :placeholder="translations.address.streetname" v-model="new_billing_address.street">
            <div class="input-errors" v-if="errors['new_billing_address.street']">
              <p v-for="error in errors['new_billing_address.street']">{{ error }}</p>
            </div>
          </div>
        </div>

        <div class="uk-width-1-6@s">
          <label class="uk-form-label" for="new_billing_housenr">{{ translations.address.housenr }} *</label>
          <div class="uk-form-controls">
            <input class="uk-input" id="new_billing_housenr" type="text" :placeholder="translations.address.housenr" v-model="new_billing_address.number">
            <div class="input-errors" v-if="errors['new_billing_address.number']">
              <p v-for="error in errors['new_billing_address.number']">{{ error }}</p>
            </div>
          </div>
        </div>

        <div class="uk-width-1-6@s">
          <label class="uk-form-label" for="new_billing_housenr_addon">{{ translations.address.number_addon_short }}</label>
          <div class="uk-form-controls">
            <input class="uk-input" id="new_billing_housenr_addon" type="text" :placeholder="translations.address.number_addon_short" v-model="new_billing_address.number_addon">
            <div class="input-errors" v-if="errors['new_billing_address.number_addon']">
              <p v-for="error in errors['new_billing_address.number_addon']">{{ error }}</p>
            </div>
          </div>
        </div>

        <div class="uk-width-1-6@s">
          <label class="uk-form-label" for="new_billing_busnr">{{ translations.address.busnr }}</label>
          <div class="uk-form-controls">
            <input class="uk-input" id="new_billing_busnr" type="text" :placeholder="translations.address.busnr" v-model="new_billing_address.busnumber">
            <div class="input-errors" v-if="errors['new_billing_address.busnumber']">
              <p v-for="error in errors['new_billing_address.busnumber']">{{ error }}</p>
            </div>
          </div>
        </div>

        <div class="uk-width-1-4@s">
          <label class="uk-form-label" for="new_billing_postalcode">{{ translations.address.postalcode }} *</label>
          <div class="uk-form-controls">
            <input class="uk-input" id="new_billing_postalcode" type="text" :placeholder="translations.address.postalcode" v-model="new_billing_address.postalcode">
            <div class="input-errors" v-if="errors['new_billing_address.postalcode']">
              <p v-for="error in errors['new_billing_address.postalcode']">{{ error }}</p>
            </div>
          </div>
        </div>

        <div class="uk-width-1-2@s">
          <label class="uk-form-label" for="new_billing_commune">{{ translations.address.commune }} *</label>
          <div class="uk-form-controls">
            <input class="uk-input" id="new_billing_commune" type="text" :placeholder="translations.address.commune" v-model="new_billing_address.commune">
            <div class="input-errors" v-if="errors['new_billing_address.commune']">
              <p v-for="error in errors['new_billing_address.commune']">{{ error }}</p>
            </div>
          </div>
        </div>

        <div class="uk-width-1-4@s">
          <label class="uk-form-label" for="new_billing_country">{{ translations.address.country }} *</label>
          <div class="uk-form-controls">
            <select name="country" id="new_billing_country" class="custom-select uk-width-1-1" v-model="new_billing_address.country">
              <option :value="country.id" v-for="country in countries">{{ country.name }}</option>
            </select>
            <div class="input-errors" v-if="errors['new_billing_address.country']">
              <p v-for="error in errors['new_billing_address.country']">{{ error }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="uk-width-1-1 mt-4 mb-3">
      <h3 class="mt-5 uk-text-primary">{{ translations.contact_info }}</h3>
    </div>

    <div class="uk-width-1-2@s">
      <label class="uk-form-label" for="email">{{ translations.email }} *</label>
      <div class="uk-form-controls">
        <input class="uk-input" id="email" type="email" :placeholder="translations.email" v-model="email">
        <div class="input-errors" v-if="errors.email">
          <p v-for="error in errors.email">{{ error }}</p>
        </div>
      </div>
    </div>

    <div class="uk-width-1-2@s">
      <label class="uk-form-label" for="phone" v-if="userPhoneRequired">{{ translations.tel_or_cell }} *</label>
      <label class="uk-form-label" for="phone" v-else>{{ translations.tel_or_cell }}</label>
      <div class="uk-form-controls">
        <input class="uk-input" id="phone" type="text" :placeholder="translations.tel_or_cell" v-model="phone">
        <div class="input-errors" v-if="errors.phone">
          <p v-for="error in errors.phone">{{ error }}</p>
        </div>
      </div>
    </div>

    <div class="uk-width-1-1 mt-sm-5 mt-4">
      <div class="d-flex justify-content-between align-items-center">
        <a :href="routes.back_to_cart" class="uk-button-back" :title="translations.cart"><i class="fa-solid fa-arrow-left me-2"></i>{{ translations.cart }}</a>
        <button class="uk-button uk-button-primary" @click.prevent="handleValidate">
          <div class="loader" v-if="isLoading"><i class="fa-solid fa-circle-notch fa-spin me-2"></i></div>
          {{ translations.to_overview }}
        </button>
      </div>
    </div>
  </form>
</template>

<script>
  import {EventBus} from "../EventBus";

  export default {
    name: "DeliveryBillingForm",
    mounted() {
    },
    data(){
      return {
        salutation: this.cart?.salutation ?? this.user?.salutation ?? "",
        firstname: this.cart?.delivery_firstname ?? this.user?.firstname ?? "",
        lastname: this.cart?.delivery_lastname ?? this.user?.lastname ?? "",
        companyname_delivery: this.cart?.delivery_company ?? this.user.companyname ?? "",
        active_delivery: this.cart?.delivery_address_id ?? this.user?.default_delivery ?? "",
        new_delivery_address: this.initDeliveryAddress(),
        order_type: this.cart?.billing_type?.toLowerCase() ?? this.user?.account_type ?? "",
        companyname: this.cart?.billing_company ?? this.user?.companyname ?? "",
        company_type: this.cart?.billing_company_type ?? this.user?.company_type ?? "",
        company_vat: this.cart?.billing_vat ?? this.user?.company_vat ?? "",
        facturation_same_delivery: this.initFacturationSameDelivery(),
        active_billing: this.cart?.billing_address_id ?? this.user?.default_facturation ?? "",
        new_billing_address: this.initBillingAddress(),
        email: this.cart?.contact_email ?? this.user?.email ?? "",
        phone: this.cart?.contact_phone ?? this.user?.phone ?? "",
        errors: {},
        isLoading: false,
      }
    },
    computed: {
      newDeliveryAddressActive(){
        if(this.active_delivery == "new"){
          return true;
        } else if(this.user?.addresses?.length == 0 || this.user?.length == 0){
          if(this.cart?.delivery_address_id == null){
            this.setActiveDelivery("new");
          }
          return true;
        }
        return false;
      },
      newBillingAddressActive(){
        if(this.active_billing === "new"){
          return true;
        } else if(this.user?.addresses?.length === 0 || this.user?.length === 0){
          if(this.cart?.billing_address_id == null)
          {
            this.setActiveBilling("new");
          }
          return true;
        }
        return false;
      }
    },
    methods: {
      initDeliveryAddress(){
        let address = {
          street: "",
          number: "",
          number_addon: "",
          busnumber: "",
          postalcode: "",
          commune: "",
          country: "",
        }

        if(this.cart?.delivery_address_id != null && this.cart?.delivery_address_id != "new" && this.cart?.user_id == null)
        {
          address.street = this.cart?.delivery_address?.street ?? "";
          address.number = this.cart?.delivery_address?.number ?? "";
          address.number_addon = this.cart?.delivery_address?.number_addon ?? "";
          address.busnumber = this.cart?.delivery_address?.busnumber ?? "";
          address.postalcode = this.cart?.delivery_address?.postalcode ?? "";
          address.commune = this.cart?.delivery_address?.commune ?? "";
          address.country = this.cart?.delivery_address?.country_id ?? "";
        }

        return address;
      },
      initBillingAddress(){
        let address = {
          street: "",
          number: "",
          number_addon: "",
          busnumber: "",
          postalcode: "",
          commune: "",
          country: "",
        }

        if(this.cart?.billing_address_id !== null && this.cart?.billing_address_id !== "new" && this.cart?.user_id === null)
        {
          address.street = this.cart?.billing_address?.street ?? "";
          address.number = this.cart?.billing_address?.number ?? "";
          address.number_addon = this.cart?.billing_address?.number_addon ?? "";
          address.busnumber = this.cart?.billing_address?.busnumber ?? "";
          address.postalcode = this.cart?.billing_address?.postalcode ?? "";
          address.commune = this.cart?.billing_address?.commune ?? "";
          address.country = this.cart?.billing_address?.country_id ?? "";
        }

        return address;
      },
      initFacturationSameDelivery(){
        if(!this.deliveryAddressRequired){
          return false;
        }

        if(this.cart?.delivery_address_id === this.cart?.billing_address_id)
        {
          return true;
        }

        return false;
      },
      setActiveDelivery(value){
        this.active_delivery = value;
      },
      setActiveBilling(value){
        this.active_billing = value;
      },
      addressPick(type, address_id) {
        if(type == "delivery"){
          this.active_delivery = address_id;
        }else if(type == "billing"){
          this.active_billing = address_id;
        }
      },
      handleNewAddress(type){
        if(type == 'delivery'){
          this.active_delivery = "new";
        }else if(type == 'billing'){
          this.active_billing = "new";
        }
      },
      handleValidate(){
        this.isLoading = true;

        let postData = {
          salutation: this.salutation,
          firstname: this.firstname,
          lastname: this.lastname,
          companyname_delivery: this.companyname_delivery,
          delivery_address: this.active_delivery,
          new_delivery_address: this.new_delivery_address,
          order_type: this.order_type,
          companyname: this.companyname,
          company_type: this.company_type,
          company_vat: this.company_vat,
          facturation_same_delivery: this.facturation_same_delivery,
          billing_address: this.active_billing,
          new_billing_address: this.new_billing_address,
          email: this.email,
          phone: this.phone,
          delivery_address_required: this.deliveryAddressRequired
        }

        window.axios.post(this.routes.validate_route, postData)
          .then(res => {
            this.errors = {};

            let resData = res.data;
            if(resData.success){
              window.location.href = this.routes.next_page;
            }
            else{
              EventBus.$emit('NOTIFY', {message: resData.message, status: 'danger', position: 'top-right', 'timeout': 3000});
              this.isLoading = false;
            }
          })
          .catch(err => {
            this.errors = err?.response?.data;

            EventBus.$emit('NOTIFY', {message: this.translations.incorrect_fields, status: 'danger', position: 'top-right', 'timeout': 3000});

            this.isLoading = false;
          });
      },
    },
    props: {
      translations: {
        type: Object|Array,
        required: true
      },
      routes: {
        type: Object|Array,
        required: true
      },
      user: {
        type: Object|Array,
        default() { return {}}
      },
      countries: {
        type: Object|Array,
        required: true
      },
      cart: {
        type: Object|Array,
        required: true
      },
      userPhoneRequired: {
        type: Object|Array,
        required: false,
        default: true
      },
      deliveryAddressRequired: {
        type: Object|Array,
        required: false,
        default: true
      },
      legalForms: {
        type: Object|Array,
        required: false,
        default() { return []; }
      }
    }
  }
</script>

<style scoped>

</style>